<template>
  <thead>
    <tr>
      <th v-for="hd in headers.filter(h => ! h.hide)" 
        :key="hd.value" 
        :class="sortable(hd) ? `order ${hd.class}` : hd.class"
        v-tooltip="hd.orderBy ? msgClickPerOrdinare : null"
        @click="sortable(hd) ? $emit('orderBy', hd.orderBy) : null">
        {{hd.value}}<span v-if="sortable(hd)" :class="sortable(hd) ? hd.orderBy.dir : null"></span>
      </th>
      <th colspan="2" v-if="! isDashboard" :id="`reset-ordinamento${idPart}`">
        <font-awesome-icon icon="times" color="black" 
        v-tooltip="'Reset ordinamento'"
        v-if="headers.map(hd => hd.orderBy || {}).some(el => !!el.dir)"
        @click="$emit('resetOrder')"/>
      </th>
    </tr>
  </thead>
</template>

<script>
const NAME = 'HeadersDocumentiSpSa'
export default {
  name: NAME,
  props: ['headers', 'isDashboard', 'idPart'],
  data(){
    return {
      msgClickPerOrdinare: this.isDashboard ? null : 'Click per ordinare in base a questa colonna'
    }
  },
  methods:{
    sortable(h){
      return !! h.orderBy
    }
  }
}
</script>