import { format } from "date-fns";
import { nanoid } from "nanoid";
import { R, DateDefaults, keysOfUnsetValues } from "mida4-web-app-utils";
import {
  StatoInvio,
  ModalitaPagamento,
  ImpostaBollo,
  CassaPrevidenziale,
} from "mida4-fattura-rapida-spese-sanitarie";
import { insert } from "mida4-web-app-utils/functions";
import {
  isRigaAutogenerata,
  isRigaCassaPrevidenziale,
  isRigaRitenuta,
  getRigaBollo,
  preparaDocInvio,
  hasCliente,
  campiRiga,
  // checkIntestazione,
  // checkHasRighe
} from "mida4-fattura-rapida-spese-sanitarie/doc";

import SchemaRigaFattura from "@/entities/schemas/riga-fattura.json";

const BASE_STATE = {
  documento: {
    destinazioneFattura: "STS",
    statoInvio: StatoInvio.LOCALE,
    dataDocumento: format(new Date(), DateDefaults.formats.CLIENT),
    dataPagamento: format(new Date(), DateDefaults.formats.CLIENT),
    modalitaPagamento: ModalitaPagamento.getDefault(),
    regimeFiscale: null,
    cliente: { sede: {} },
    righe: [],
  },
  meta: {
    readonly: false,
    editing: false,
    progressInvio: null,
    hasCampiMancanti: false,
    campiEsposti: {
      cassa: false,
      ritenuta: false,
    },
    caret: { id: null, pos: 0 },
    cliente: {
      isCompleto: false,
      required: [[], []],
      show: {
        ricerca: true,
        form: false,
        dati: false,
      },
    },
    bollo: {
      addebito: false,
      imposta: "0",
    },
  },
};

function _getNomeCampoDaNomeFunzione(nomeFunzione) {
  const inizio = "isRiga".length;
  const primaLettera = nomeFunzione.substring(inizio, inizio + 1);
  const resto = nomeFunzione.substring(inizio + 1);

  return primaLettera.toLowerCase() + resto;
}
function _rimuoviSeAutogenerata(rID, righe, checks) {
  for (let check of checks) {
    const isDaRimuovere = (id) => check.apply(this, [id]);
    const campo = _getNomeCampoDaNomeFunzione(check.name);

    if (isDaRimuovere(rID)) {
      // quando rimuovo una riga di cassa previdenziale o ritenuta
      // annullo la rivalsa o ritenuta per tutta la fattura
      righe = righe
        // imposto a false la rivalsa o ritenuta su tutte le righe
        .map((r) => ({ ...r, [campo]: false }))
        // rimuovo le righe di cassa previdenziale o ritenuta
        .filter((r) => !isDaRimuovere(r._id));
    }
  }
  return righe;
}

const elaborandaModule = {
  namespaced: true,

  state: () => ({ ...BASE_STATE }),

  mutations: {
    resetDoc(state, { user, defaults }) {
      state.documento = {
        ...BASE_STATE.documento,
        sezionaleDocumento: defaults.sezionaleSTS,
        regimeFiscale: user.regimeFiscale,
      };
    },
    setAddebitoBollo(state, val) {
      // this._vm.$log.debug("setAddebitoBollo", val);
      state.meta.bollo.addebito = val;
    },
    setImpostaBollo(state, val) {
      state.meta.bollo.imposta = val;
    },
    setDocumento(state, doc) {
      state.documento = doc;
    },
    setRighe(state, righe) {
      // this._vm.$log.debug("commit setRighe");
      state.documento.righe = righe;
    },
    setCliente(state, cli) {
      state.documento.cliente = cli;
    },
    setSedeCliente(state, sede) {
      state.documento.cliente.sede = sede;
    },
    setShowCliente(state, part) {
      Object.keys(state.meta.cliente.show).forEach(
        (k) => (state.meta.cliente.show[k] = k === part)
      );
    },
    setClienteRequired(state, fields) {
      // this._vm.$log.debug("setReq");
      state.meta.cliente.required = fields;
    },
    setIsClienteCompleto(state, isCompleto) {
      state.meta.cliente.isCompleto = isCompleto;
    },
    setCaretPos(state, caret) {
      state.meta.caret = caret;
    },
    setHasCampiMancanti(state, val = true) {
      state.meta.hasCampiMancanti = val;
    },

    setDestinazioneFattura(state, dest) {
      state.documento.destinazioneFattura = dest;
    },
    setDataDocumento(state, dataDoc) {
      state.documento.dataDocumento = dataDoc;
    },
    setDataPagamento(state, dataPag) {
      state.documento.dataPagamento = dataPag;
    },
    setRegimeFiscale(state, regFisc) {
      state.documento.regimeFiscale = regFisc;
    },
    setRimborsoDataDocumento(state, dataRimb) {
      state.documento.rimborsoDataDocumento = dataRimb;
    },
    setRimborsoNumeroDocumento(state, numRimb) {
      state.documento.rimborsoNumeroDocumento = numRimb;
    },
    setPagamentoTracciabile(state, isTracc) {
      state.documento.pagamentoTracciabile = isTracc;
    },
  },

  actions: {
    elaboraDocAttivo({ commit, rootGetters }) {
      // this._vm.$log.debug('elaboraDocAttivo', rootGetters['fatture/getDocAttivo'])
      const docAttivo = R.clone(rootGetters["fatture/getDocAttivo"]);
      const rigaBollo = getRigaBollo(docAttivo);
      commit("setDocumento", docAttivo);
      commit("setShowCliente", "dati");
      if (rigaBollo) {
        commit("setAddebitoBollo", true);
        commit("setImpostaBollo", rigaBollo.importoUnitario.toString());
      }
    },
    setAddebitoBollo(
      { commit, dispatch, getters: { impostaBollo } },
      addebitata = true
    ) {
      commit("setAddebitoBollo", addebitata);
      dispatch("applicaAddebitoBollo", addebitata === true && impostaBollo > 0);
    },
    setImpostaBollo(
      { commit, dispatch, getters: { addebitoBollo } },
      importoImposta
    ) {
      commit("setImpostaBollo", importoImposta);
      dispatch(
        "applicaAddebitoBollo",
        importoImposta > 0 && addebitoBollo === true
      );
    },
    setBollo({ commit, dispatch, getters }) {
      // this._vm.$log.debug("elaboranda setBollo");
      //if(! this.isModificabile) return
      const impostaBollo = ImpostaBollo.getImporto(getters.righe).toString();
      const applicaAddebito =
        getters.addebitoBollo === true && impostaBollo > 0;
      commit("setImpostaBollo", impostaBollo);
      commit("setAddebitoBollo", applicaAddebito);

      dispatch("applicaAddebitoBollo", applicaAddebito);
    },
    applicaAddebitoBollo({ commit, getters, rootGetters }, applica) {
      const esisteRigaBollo = !R.isNil(getters.rigaBollo);
      if (applica === true) {
        const rigaBollo = ImpostaBollo.getRigaFattura(getters.righe);

        if (rootGetters["user/getData"].regimeFiscale !== "RF01") {
          rigaBollo.IVA = "N2.2";
        }
        commit("setRighe", [
          ...getters.righe.filter((r) => r._id !== "bollo"),
          rigaBollo,
        ]);
      } else if (esisteRigaBollo) {
        commit("setRighe", [...getters.righe.filter((r) => r._id !== "bollo")]);
        commit(
          "setAddebitoBollo",
          ImpostaBollo.getImporto(getters.righe) === 0
        );
      }
    },
    aggiornaCassaPrevidenziale({ commit, dispatch, getters, rootGetters }) {
      // this._vm.$log.debug("elaboranda aggiornaCassaPrevidenziale");

      const userDefaults = rootGetters["user/getDefaults"];
      const baseRighe = R.clone(
        getters.righe.filter((r) => !isRigaAutogenerata(r._id))
      );

      const righeCassaPrevidenziale = CassaPrevidenziale.getRigheFattura(
        this._vm.DEFAULTS.DESTINAZIONE,
        baseRighe,
        userDefaults.tipoCassaPrevidenziale,
        userDefaults.aliquotaCassaPrevidenziale
      );

      commit("setRighe", baseRighe.concat(righeCassaPrevidenziale));
      dispatch("setBollo");
    },
    setDocumento({ commit }, doc) {
      commit("setDocumento", doc);
    },
    setRighe({ commit }, righe) {
      commit("setRighe", righe);
    },
    aggiungiRiga({ commit, dispatch, getters }, rID) {
      if (isRigaAutogenerata(rID)) return;

      const primaRiga = getters.righe.length === 0;
      const nuovaRiga = getters.generaNuovaRiga(primaRiga);
      const after = () =>
        rID === undefined ? 0 : getters.righe.findIndex((r) => r._id === rID);

      let setFocus = false;

      if (primaRiga) {
        setFocus = true;
        dispatch("setRighe", [nuovaRiga]);
        // this.righe = [ nuovaRiga ]
      } else {
        dispatch("persistRiga");
        if (getters.statoRigaModificanda.mancanti.length === 0) {
          setFocus = true;
          this._vm.$nextTick(() => {
            dispatch(
              "setRighe",
              insert({
                // eslint-disable-next-line no-unused-vars
                arr: R.clone(getters.righe).map(({ editing, ...r }) => r),
                el: nuovaRiga,
                after: after(),
              })
            );
          });
        } else {
          commit("setHasCampiMancanti", true);
        }
      }
      if (setFocus) {
        commit("setCaretPos", {
          id: `id_${nuovaRiga._id}_descrizione`,
          pos: 0,
        });
      }
    },
    toggleEditRiga({ commit, getters, dispatch }, rID) {
      if (isRigaAutogenerata(rID)) return;

      const riga = getters.righe.find((r) => r._id === rID);
      if (riga && riga.editing !== true) {
        const { mancanti, parzialmenteCompilata } =
          getters.statoRigaModificanda;

        if (parzialmenteCompilata) {
          dispatch("setHasCampiMancanti");
          return;
        }
        commit(
          "setRighe",
          getters.righe
            .filter((r) => (mancanti.length > 0 ? r.editing !== true : true))
            .map((r) => ({ ...r, editing: r._id === rID }))
        );
        commit("setCaretPos", { id: `id_${rID}_descrizione`, pos: 0 });
      }
      dispatch("resetHasCampiMancanti");
    },
    persistRiga({ commit, dispatch, getters, rootGetters }, rID) {
      if (rID === undefined) {
        if (getters.rigaModificanda !== undefined) {
          rID = getters.rigaModificanda._id;
        } else return;
      }
      const elementiRiga = Array.from(
        document.querySelectorAll(`#riga-${rID} td:not(.ignore)`)
      );
      const oldRiga = R.clone(getters.righe.find((rr) => rr._id === rID));
      const convertiElementoInRiga = (rigaRisultante, el) => {
        let ret = {
          ...rigaRisultante,
          [el.dataset.campo]: el.dataset.val === "null" ? null : el.dataset.val,
        };
        if (el.dataset.campo === "cassaPrevidenziale") {
          // su tipoIVA N1 non esiste la cassa previdenziale
          if (ret.IVA === "N1") {
            ret.cassaPrevidenziale = false;
          } else if (oldRiga.IVA === "N1") {
            // se è stato modificato il tipoIVA e
            // precedentemente era N1 imposto il valore
            // dai default documento
            ret.cassaPrevidenziale = rootGetters["user/getDefaultCassaVal"];
          } else {
            ret.cassaPrevidenziale = ret.cassaPrevidenziale === "true";
          }
        } else if (el.dataset.campo === "ritenuta") {
          ret.ritenuta = ret.ritenuta === "true";
        }
        return ret;
      };

      const aggiornaRiga = (rigaCorrente) =>
        rigaCorrente._id === rID
          ? elementiRiga.reduce(convertiElementoInRiga, R.clone(oldRiga))
          : rigaCorrente;

      // this.$log.debug('righe pre persist', this.righe)

      let righeAggiornate = R.clone(getters.righe).map(aggiornaRiga);
      if (
        !righeAggiornate.some(
          (r) => !isRigaAutogenerata(r._id) && r.cassaPrevidenziale === true
        )
      ) {
        righeAggiornate = righeAggiornate.filter(
          (r) => !isRigaCassaPrevidenziale(r._id)
        );
      }
      if (
        !righeAggiornate.some(
          (r) => !isRigaAutogenerata(r._id) && r.ritenuta === true
        )
      ) {
        righeAggiornate = righeAggiornate
          .filter((r) => !isRigaRitenuta(r._id))
          .map((r) => ({ ...r, ritenuta: false }));
      }
      commit("setRighe", righeAggiornate);
      // this.$log.debug('this.statoRigaModificanda', this.statoRigaModificanda)
      if (
        getters.statoRigaModificanda.parzialmenteCompilata ||
        getters.statoRigaModificanda.isVergine()
      ) {
        // this.$log.endGroup('TabellaRigheDettaglioDocumentoWebFe.persistRiga')
        return;
      } else {
        this._vm.$nextTick(() => dispatch("aggiornaCassaPrevidenziale"));
      }

      // this.$log.debug('righe post persist', this.righe)
      // this.$log.endGroup('TabellaRigheDettaglioDocumentoWebFe.persistRiga')
    },
    async eliminaRiga({ commit, dispatch, getters }, rID) {
      dispatch("resetHasCampiMancanti");
      // this.rimuoviEvidenzaCampiMancanti()
      let righe = _rimuoviSeAutogenerata(rID, R.clone(getters.righe), [
        isRigaCassaPrevidenziale,
        isRigaRitenuta,
      ]).filter((r) => r._id != rID);

      if (righe.every((r) => isRigaAutogenerata(r._id))) {
        righe = [];
      }

      commit("setRighe", righe);
      await this._vm.$nextTick();

      if (rID === "bollo") {
        dispatch("setAddebitoBollo", false);
        // this.setAddebitoBollo(false)
      }
      if (righe.length === 0) {
        await this._vm.$nextTick();
        dispatch("aggiungiRiga");
        // this.aggiungiRiga()
      }
      await this._vm.$nextTick();
      dispatch("aggiornaCassaPrevidenziale");
    },
    nuovoCliente({ commit, rootGetters }) {
      commit("resetDoc", {
        user: rootGetters["user/getData"],
        defaults: rootGetters["user/getDefaults"],
      });
      commit("setShowCliente", "form");
    },
    impostaCliente({ commit }, cli) {
      commit("setCliente", cli);
      commit("setShowCliente", "dati");
    },
    setCliente({ commit }, cli) {
      commit("setCliente", cli);
    },
    setSedeCliente({ commit }, sede) {
      commit("setSedeCliente", sede);
    },
    resetDoc({ commit, dispatch, getters, rootGetters }) {
      // this._vm.$log.debug("ESEGUO RESET DOC ELABORANDO");
      // this._vm.$log.debug("reset - userDefaults", rootGetters["user/getDefaults"]);
      commit("resetDoc", {
        user: rootGetters["user/getData"],
        defaults: rootGetters["user/getDefaults"],
      });
      commit("fatture/resetDocAttivo", "", { root: true });
      // this._vm.$log.debug("ora faccio set completo");
      commit("setIsClienteCompleto");
      commit(
        "setAddebitoBollo",
        rootGetters["user/getDefaults"].isAddebitoBollo
      );
      commit("setRighe", [getters.generaNuovaRiga()]);
      dispatch("aggiornaCassaPrevidenziale");
      // if (rootGetters["user/getDefaults"].isAddebitoBollo) {
      //   commit("setImpostaBollo", ImpostaBollo.importo.toString());
      // }
      commit("setShowCliente", "ricerca");
    },
    setShowCliente({ commit }, part) {
      commit("setShowCliente", part);
    },
    setClienteRequired({ commit }, fields) {
      commit("setClienteRequired", fields);
    },
    setIsClienteCompleto({ commit, getters }) {
      const completo = hasCliente(getters.cliente, getters.getClienteRequired);
      // this._vm.$log.debug("setCompleto", completo);
      commit("setIsClienteCompleto", completo);
    },
    toggleEditCliente({ commit, getters }) {
      if (getters.showCliente.form) {
        if (getters.isClienteCompleto) {
          commit("setShowCliente", "dati");
        } else {
          commit("setCliente", { ...BASE_STATE.documento.cliente });
          commit("setShowCliente", "ricerca");
        }
      } else {
        commit("setShowCliente", "form");
      }
    },
    setCaretPos({ commit }, caret) {
      commit("setCaretPos", caret);
    },
    setHasCampiMancanti({ commit }) {
      commit("setHasCampiMancanti", true);
    },
    resetHasCampiMancanti({ commit }) {
      commit("setHasCampiMancanti", false);
    },

    setDestinazioneFattura({ commit }, dest) {
      commit("setDestinazioneFattura", dest);
    },
    setDataDocumento({ commit }, dataDoc) {
      commit("setDataDocumento", dataDoc);
    },
    setDataPagamento({ commit }, dataPag) {
      commit("setDataPagamento", dataPag);
    },
    setRegimeFiscale({ commit }, regFisc) {
      commit("setRegimeFiscale", regFisc);
    },
    setRimborsoDataDocumento({ commit }, dataRimb) {
      commit("setRimborsoDataDocumento", dataRimb);
    },
    setRimborsoNumeroDocumento({ commit }, numRimb) {
      commit("setRimborsoNumeroDocumento", numRimb);
    },
    setPagamentoTracciabile({ commit }, isTracc) {
      commit("setPagamentoTracciabile", isTracc);
    },
  },
  getters: {
    formClienteKey: (_, getters) =>
      window.btoa(JSON.stringify(getters.showCliente)),
    tabellaRigheKey: (_, getters) => window.btoa(JSON.stringify(getters.righe)),
    showCliente: (state) => state.meta.cliente.show,
    getClienteRequired: (state) => state.meta.cliente.required,
    impostaBollo: (state) => state.meta.bollo.imposta,
    addebitoBollo: (state) => state.meta.bollo.addebito,
    // applicaRitenuta: (_s, getters, _gs, rootGetters) => Ritenuta.isApplicataDefault(getters.cliente, rootGetters['user/getDefaults'].tipoRitenuta),
    documento: (state) => state.documento,
    isClienteCompleto: (state) => state.meta.cliente.isCompleto,
    daInviare: (state, rootGetters) =>
      preparaDocInvio(state.documento, state.meta.bollo.imposta, {
        ...rootGetters["user/getDefaults"],
        sezionaleSDI: "SDI",
      }),
    cliente: (state) => state.documento.cliente,
    sedeCliente: (state) => state.documento.cliente.sede,
    tabellaMode: (state, _g, _rs, rootGetters) => {
      return {
        edit: rootGetters["fatture/isAttivoModificabile"],
        // && state.meta.cliente.show.dati,
        readOnly: !rootGetters["fatture/isAttivoModificabile"],
      };
    },
    showCassa: (_, getters, _rs, rootGetters) =>
      getters.tabellaMode.readOnly === true
        ? getters.righe.find((r) => isRigaCassaPrevidenziale(r._id))
        : rootGetters["user/getDefaults"].tipoCassaPrevidenziale !==
            "NESSUNA" &&
          ![R.isNil, R.isNil].some((fn) =>
            fn.call(
              null,
              rootGetters["user/getDefaults"].tipoCassaPrevidenziale
            )
          ),
    getCampiRiga: (_, getters) => {
      let campiEsclusi = [];
      if (!getters.showCassa) {
        campiEsclusi.push("cassaPrevidenziale");
      }
      if (!getters.showRitenuta) {
        campiEsclusi.push("ritenuta");
      }
      return campiRiga({ SchemaRigaFattura, campiEsclusi });
    },
    generaNuovaRiga:
      (_, getters, _gs, rootGetters) =>
      (primaRiga = true) => {
        const getFromDefaults = (k) => {
          if (k === "cassaPrevidenziale") {
            return rootGetters["user/getDefaultCassaVal"];
          } else if (k === "ritenuta") {
            return getters.applicaRitenuta;
            // this.$log.debug('getFromDefaults: this.applicaRitenuta', this.applicaRitenuta)
          } else if (k in rootGetters["user/getDefaults"]) {
            return rootGetters["user/getDefaults"][k];
          } else return null;
        };
        const descrizioneEImportoSoloPrimaRiga = (c) =>
          primaRiga ? true : !["descrizione", "importoUnitario"].includes(c);
        const templateRiga = {
          _id: nanoid(),
          editing: true,
          tipoRiga: "NORMALE",
          descrizione: "",
          importoUnitario: "",
          cassaPrevidenziale: false,
          tipoSpesa: "",
          // ritenuta: false
        };
        const nuovaRiga = [...getters.getCampiRiga]
          .filter(descrizioneEImportoSoloPrimaRiga)
          .reduce((o, k) => ({ ...o, [k]: getFromDefaults(k) }), templateRiga);
        return nuovaRiga;
      },
    righe: (state) => state.documento.righe,
    rigaModificanda: (state) =>
      state.documento.righe.find((r) => r.editing === true),
    rigaBollo: (state) => getRigaBollo(state.documento),
    statoRigaModificanda: (_, getters) => {
      if (!getters.rigaModificanda)
        return {
          id: null,
          mancanti: [],
          parzialmenteCompilata: false,
          isVergine: () => undefined,
        };
      const mancanti = keysOfUnsetValues(getters.rigaModificanda);
      const parzialmenteCompilata =
        0 < mancanti.length && mancanti.length < getters.getCampiRiga.length;
      return {
        id: getters.rigaModificanda._id,
        mancanti,
        parzialmenteCompilata,
        isVergine: () => mancanti.length > 0 && parzialmenteCompilata === false,
      };
    },
    hasCampiMancanti: (state) => state.meta.hasCampiMancanti,
    caret: (state) => state.meta.caret,
  },
};

export default elaborandaModule;

