<template>
  <div v-frag>
    <p id="scadenza" :class="scaduto ? 'scaduto' : null">
      <span class="intestazione-scadenza"
        >{{ scaduto ? "Scaduto il" : "Scadenza:" }}
      </span>
      <span id="data-scadenza">{{ scadenza }}</span>
    </p>
    <!-- <router-link to="/rinnovo" id="rinnovo">Rinnovo servizio</router-link> -->
  </div>
</template>

<script>
  import { format } from "date-fns";
  import { DateDefaults } from "mida4-web-app-utils";
  import { mapGetters } from "vuex";

  const NAME = "DataScadenzaSpSa";

  export default {
    name: NAME,
    data() {
      return {
        NAME: NAME,
      };
    },
    computed: {
      ...mapGetters({
        dataScadenza: "user/dataScadenza",
        scaduto: "user/scaduto",
      }),
      scadenza() {
        return format(this.dataScadenza, DateDefaults.formats.ONLY_DATE);
      },
    },
  };
</script>

<style
  src="@/styles/custom/components/_layout/_data_scadenza.scss"
  lang="scss"
></style>

