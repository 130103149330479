<template>
  <v-app>
    <vue-particles v-if="!loggedIn" color="#dedede" />
    <header v-if="loggedIn">
      <section id="info-mida4">
        <router-link :to="{ name: 'Home' }">
          <img src="@/assets/logo_bianco.png" alt="" />
        </router-link>
      </section>
      <section id="prima-riga">
        <h1 :class="isMobile || isSmallScreen ? 'mobile-header' : null">
          {{ isMobile || isSmallScreen ? "" : "Invio " }}Spese Sanitarie{{
            isMobile || isSmallScreen ? "" : ` - ${user.cognomeDenom}`
          }}
        </h1>
      </section>
      <section id="seconda-riga">
        <MenuApplicazioneSpSa
          @toggleRiquadroUtente="toggleRiquadroUtente"
          @toggleRiquadroAssistenza="toggleRiquadroAssistenza"
        />
        <RiquadroUtenteSpSa v-if="showRiquadroUtente && loggedIn" />
        <RiquadroAssistenzaSpSa v-if="showRiquadroAssistenza && loggedIn" />
      </section>
    </header>
    <main>
      <slot name="azioni" />
      <slot />
    </main>
    <footer :class="loggedIn ? 'inner' : null">
      <span v-html="footerTxt"></span>
    </footer>
    <LoaderSpSa />
  </v-app>
</template>

<script>
import { version } from "@/../package.json";
import { mapGetters } from "vuex";
import { REGEXES } from "mida4-web-app-utils";

import MenuApplicazioneSpSa from "@/components/_layout/components/MenuAppSpSa";
import RiquadroUtenteSpSa from "@/components/_layout/components/RiquadroUtenteSpSa";
import RiquadroAssistenzaSpSa from "@/components/_layout/components/RiquadroAssistenzaSpSa";
import LoaderSpSa from "@/components/_common/LoaderSpSa";

const NAME = "LayoutWebFe";

export default {
  name: NAME,
  components: {
    MenuApplicazioneSpSa,
    RiquadroUtenteSpSa,
    RiquadroAssistenzaSpSa,
    LoaderSpSa,
  },
  data() {
    return {
      isMobile: false,
      showRiquadroUtente: false,
      showRiquadroAssistenza: false,
      footerTxt: `Invio Spese Sanitarie ${version} - Servizio fornito da <a href="https://www.mida4.it" target="_blank">Mida4</a> s.r.l. Codice fiscale, Partita IVA e numero iscrizione C.C.I.A.A. TS 00797300324`,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getData",
      loggedIn: "auth/isUserLoggedIn",
    }),
    isSmallScreen() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.sm
      );
    },
  },
  mounted() {
    document.body.addEventListener("click", this.hideRiquadriMenu);
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  // beforeDestroy(){
  //   if (typeof window !== 'undefined') {
  //     window.removeEventListener('resize', this.onResize, { passive: true })
  //   }
  // },
  methods: {
    /**
     * riceve un evento e una regex
     * verifica se un qualunque ancestor dell'event target
     * corrisponde alla regular expression
     */
    inPath(e, regex) {
      return e.path ? e.path.some((e) => regex.test(e.id)) : true;
    },

    hideRiquadriMenu(e) {
      this.hideRiquadroUtente(e);
      this.hideRiquadroAssistenza(e);
    },

    toggleRiquadroUtente() {
      this.showRiquadroAssistenza = false;
      this.showRiquadroUtente = this.loggedIn && !this.showRiquadroUtente;
    },

    hideRiquadroUtente(e) {
      if (!this.inPath(e, REGEXES.RIQUADRO_UTENTE)) {
        this.showRiquadroUtente = false;
      }
    },

    toggleRiquadroAssistenza() {
      this.showRiquadroUtente = false;
      this.showRiquadroAssistenza =
        this.loggedIn && !this.showRiquadroAssistenza;
    },

    hideRiquadroAssistenza(e) {
      if (!this.inPath(e, REGEXES.RIQUADRO_ASSISTENZA)) {
        this.showRiquadroAssistenza = false;
      }
    },

    onResize() {
      this.isMobile =
        window.innerWidth < this.$vuetify.breakpoint.thresholds.sm;
    },
  },
};
</script>

