import { arrotonda, R } from "../../../mida4-web-app-utils";
const ImpostaBollo = Object.freeze({
  soglia: 77.47,
  natureSoggette: ["N1", "N2.1", "N2.2", "N3.4", "N3.5", "N4", "N6.9", "N7"],
  descrizione: "Imposta di bollo",
  natura: "N1",
  importo: 2.0,

  // public (non esiste, solo per organizzazione del codice)

  getRigaFattura(righe) {
    return {
      _id: "bollo",
      editing: false,
      tipoRiga: "BOLLO",
      descrizione: this.descrizione,
      tipoSpesa: this._getFromOperazSoggette(righe, this._tipoSpesa),
      importoUnitario: this.importo,
      quantita: "1",
      IVA: this.natura,
      cassaPrevidenziale: false,
    };
  },
  getImporto(righe) {
    return this._getFromOperazSoggette(righe, this._valoreOperazioni) >=
      this.soglia
      ? this.importo
      : 0.0;
  },

  // private (non esiste, solo per organizzazione del codice)

  _getFromOperazSoggette(righe, fn) {
    return fn.apply(this, [this._operazioniSoggette(righe)]);
  },
  _operazioniSoggette(righe) {
    return righe
      .filter((r) => r._id !== "bollo")
      .filter((r) => this.natureSoggette.includes(r.IVA));
  },
  _valoreOperazioni(operazioni) {
    return arrotonda(
      operazioni.reduce(
        (somma, r) =>
          parseFloat(r.importoUnitario || 0) * parseFloat(r.quantita || 0) +
          somma,
        0.0
      )
    );
  },
  _tipoSpesa(operazioni) {
    if (!(R.isNil(operazioni) || R.isEmpty(operazioni))) {
      return operazioni[0].tipoSpesa;
    }
  },
});

export default ImpostaBollo;
