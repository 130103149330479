const statsModule = {
  namespaced: true,
  state: () => ({
    stats: null,
  }),
  mutations: {
    setStats(state, stats) {
      state.stats = stats;
    },
  },
  actions: {
    async fetchStatistiche({ commit }) {
      const res = await this._vm.$api.statistiche();
      const stats = await res.json();

      if (
        !Array.isArray(stats.stats[0]) &&
        Object.prototype.hasOwnProperty.call(stats.stats[0], "STS")
      ) {
        for (let m in stats.stats) {
          stats.stats[m] = stats.stats[m].STS;
        }
      }

      commit("setStats", stats);
    },
  },
  getters: {
    statistiche: (state) => state.stats,
  },
};
export default statsModule;
