const Pulsanti = {
  SU: 38,
  GIU: 40,
  SX: 37,
  DX: 39,
  ENTER: 13,
  isFrecciaOInvio: function (k) {
    return [this.SU, this.GIU, this.DX, this.SX, this.ENTER].includes(k);
  },
};

export default Pulsanti;
