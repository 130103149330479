/* eslint-disable no-unreachable */
// import { DefaultsSpSa as DEFAULTS } from '@/entities/enums/defaultsSpSa'
import { startOfMonth } from "date-fns";
import { Log, R, DateDefaults } from "mida4-web-app-utils";
import { StatoInvio } from "mida4-fattura-rapida-spese-sanitarie";
import {
  convertiUsoClient,
  isSTSDaElaborare,
  isDaElaborare,
  getElencoMessaggi,
  getUltimiMessaggi,
} from "mida4-fattura-rapida-spese-sanitarie/doc";
import { StatusCodes } from "http-status-codes";

const preparaPerClient = (fatture) =>
  fatture
    .sort((a, b) => b.numeroDocumento - a.numeroDocumento)
    .map((f) => convertiUsoClient(f));

const filterSospeso = (f) =>
  [
    StatoInvio.DA_INVIARE,
    StatoInvio.STS_UNAVAILABLE,
    StatoInvio.ESITO_ERR,
  ].includes(f.statoInvio);
const filterDaInviare = (f) => f.statoInvio === StatoInvio.DA_INVIARE;
const filterDaCorreggere = (f) => f.statoInvio === StatoInvio.ESITO_ERR;
// FILTERSOSPESO NON VIENE AGGIORNATO CORREGGERE
const fattureModule = {
  namespaced: true,
  state: () => ({
    fatture: [],
    qryAttiva: {
      // dest: DEFAULTS.DESTINAZIONE,
      from: startOfMonth(new Date()),
      to: DateDefaults.OGGI,
    },
    docAttivo: null,
    // refreshingSDI: false,
    // lastRefresh: {
    //   ok: [],
    //   ko: []
    // }
  }),
  mutations: {
    setElenco(state, fatture) {
      // Log.group('store/fatture.setElenco')
      Log.debug("fatt", fatture);
      state.fatture = fatture;

      this.dispatch("fatture/setAttivoDocDaElaborare");
      // if(Log.debug("this.getters['fatture/isRefreshSDINeeded']", this.getters['fatture/isRefreshSDINeeded'])) {
      // Log.endGroup('store/fatture.setElenco')
    },
    setQryAttiva(state, qry) {
      // attenzione ! passo l'oggetto by value (altrimenti Bad Things Happen™)
      // state.qryAttiva = Log.debug('fatture/setQryAttiva', { ...qry })
      state.qryAttiva = { ...qry };
    },
    setDocAttivo(state, doc) {
      // state.docAttivo = Log.debug('fatture/setDocAttivo', { ...doc })
      state.docAttivo = { ...doc };
    },
    resetDocAttivo(state, deleted) {
      const bloccaEliminazione =
        R.isNil(state.docAttivo) || isSTSDaElaborare(state.docAttivo); // || isSDIDaElaborare(state.docAttivo)
      if (!bloccaEliminazione || deleted) {
        state.docAttivo = null;
      }
    },
    aggiungi(state, doc) {
      this.commit("fatture/setElenco", [doc, ...state.fatture]);
    },
    aggiorna(state, doc) {
      this.commit("fatture/setElenco", [
        ...state.fatture.map((cur) => (cur.id === doc.id ? { ...doc } : cur)),
      ]);
    },
    eliminaUltimoDoc(state, { id, numeroDocumento }) {
      if (state.fatture[0]?.id === id) {
        state.fatture.shift();
      } else {
        Log.warn(
          `fatture/eliminaUltimoDoc: non presente in memoria locale: STS/${numeroDocumento} - id: ${id}`
        );
      }
    },
    eliminaDoc(state, id) {
      state.fatture = [...state.fatture.filter((f) => f.id !== id)];
    },
  },
  actions: {
    async recuperaElenco({ commit, state }) {
      try {
        const res = await this._vm.$api.elencoFatture({
          from: state.qryAttiva.from,
          to: state.qryAttiva.to,
        });
        if (res.status !== StatusCodes.OK) {
          throw new Error(
            "fatture/recuperaElenco: Impossibile contattare il server"
          );
        }
        commit("setElenco", preparaPerClient(await res.json()));
      } catch (error) {
        this._vm.$log.error(
          "fatture/recuperaElenco: Errore durante la richiesta delle fatture al server Mida",
          error
        );
      }
    },
    async insertDoc({ commit, getters, dispatch }, elaboranda) {
      let _mutation = null;
      let elenco = getters.getFatture;

      if (R.isEmpty(elenco)) {
        await dispatch("recuperaElenco");
      }
      const f = getters.getFattura(elaboranda.id);

      if (R.isNil(f)) {
        _mutation = "aggiungi";
        // Log.debug('fatture/insertDoc - aggiungo', elaboranda)
      } else if (
        [
          StatoInvio.DA_INVIARE,
          StatoInvio.ESITO_ERR,
          StatoInvio.STS_UNAVAILABLE,
        ].includes(f.statoInvio)
      ) {
        _mutation = "aggiorna";
        // Log.debug('fatture/insertDoc - aggiorno', elaboranda)
      } else {
        // Log.warn(`fatture/insertDoc: tentativo di inserire una fattura con stato invio non ammesso: id: ${f.id} - stato: ${f.statoInvio}`)
      }

      if (_mutation !== null) {
        // Log.debug(`commit(${_mutation}, elaboranda)`)
        commit(_mutation, elaboranda);
      }
    },
    // async aggiornaInfoSDIAll({ commit, getters, state }) {
    //   return
    //   const okIDList = [], koIDList = []
    //   Log.debug('fatture/aggiornaInfoSDIAll - avvio aggiornamento dati SDI')
    //   try {
    //     state.refreshingSDI = true

    //     for(let id of getters.getIdAggiornabiliSDI) {
    //       Log.debug('fatture/aggiornaInfoSDIAll - aggiornamento dati SDI - id: ', id)
    //       const res = await this._vm.$api.aggiornaFatturaSDI(id)
    //       if(res.status === StatusCodes.OK) {
    //         okIDList.push(id)
    //         const fatt = await res.json()
    //         commit('aggiorna', convertiUsoClient(fatt))
    //       }
    //       else {
    //         koIDList.push(id)
    //       }
    //     }

    //     if(! R.isEmpty(koIDList)) {
    //       Log.error(`fatture/aggiornaInfoSDIAll: Presenti fatture non aggiornate: ${koIDList.join(',')}`)
    //     }
    //     state.lastRefresh = { ok: okIDList, ko: koIDList }
    //     state.refreshingSDI = false

    //     Log.debug('fatture/aggiornaInfoSDIAll - aggiornamento dati SDI terminato')

    //   }
    //   catch(error) {
    //     Log.error('fatture/aggiornaInfoSDIAll: Aggiornamento fatture SDI fallito:', error)
    //     state.refreshingSDI = false
    //   }
    // },
    // async aggiornaInfoSDIFattura({ commit, state }, id) {
    //   // return
    //   Log.debug('fatture/aggiornaInfoSDIFattura - avvio aggiornamento dati SDI - id: ', id)
    //   try {
    //     state.refreshingSDI = true

    //     Log.debug('fatture/aggiornaInfoSDIFattura - aggiornamento dati SDI - id: ', id)
    //     const res = await this._vm.$api.aggiornaFatturaSDI(id)
    //     if(res.status !== StatusCodes.OK) {
    //       throw new Error(`fatture/aggiornaInfoSDIFattura: Errore server durante l'aggiornamento SDI: ${await res.text()}`)
    //     }
    //     const fatt = await res.json()
    //     commit('aggiorna', convertiUsoClient(fatt))

    //     state.refreshingSDI = false
    //     state.lastRefresh = { ok: [id], ko: [] }
    //     Log.debug('fatture/aggiornaInfoSDIFattura - aggiornamento dati SDI terminato correttamente - id: ', id)
    //   }
    //   catch (error) {
    //     Log.error(error)
    //     state.refreshingSDI = false
    //     state.lastRefresh = { ok: [], ko: [id] }
    //     // AVVISO L'UTENTE
    //   }
    // },
    setDocAttivo({ commit }, doc) {
      commit("setDocAttivo", doc);
    },
    setAttivoDocDaElaborare({ commit, state }) {
      if (isDaElaborare(state.fatture[0])) {
        commit("setDocAttivo", state.fatture[0]);
      }
    },
    resetDocAttivo({ commit }, deleted) {
      commit("resetDocAttivo", deleted);
    },
    eliminaUltimoDoc({ commit }, destinazione) {
      commit("eliminaUltimoDoc", destinazione);
    },
    eliminaDoc({ commit }, id) {
      commit("eliminaDoc", id);
    },
  },
  getters: {
    getFatture: (st) => st.fatture,
    getDaCorreggere: (st) => st.fatture.filter(filterDaCorreggere),
    getDaInviare: (st) => st.fatture.filter(filterDaInviare),
    getLastId: (st) => (R.isNil(st.fatture[0]) ? null : st.fatture[0].id),
    isNuovoDocAbilitato: (st) => !isDaElaborare(st.fatture[0]) ?? false,
    // getFattureSDI:        st => st.fatture,
    getFattureSTS: (st) => st.fatture,
    getFattura: (st) => (id) => st.fatture.find((f) => f.id === id),
    getQryAttiva: (st) => st.qryAttiva,
    getDocAttivo: (st) => st.docAttivo,
    getDocDaElaborare: (st) =>
      isDaElaborare(st.fatture[0]) ? st.fatture[0] : null,
    isUltimaEmessa: (st) => {
      if (!R.isNil(st.docAttivo) && !R.isEmpty(st.fatture)) {
        return st.docAttivo.id === st.fatture[0].id;
      } else return undefined;
    },
    isAttivoModificabile: (st) =>
      R.isNil(st.docAttivo) || StatoInvio.isModificabile(st.docAttivo),
    isAttivoDaInviare: (st) => {
      if (!R.isNil(st.docAttivo)) {
        return st.docAttivo.statoInvio === StatoInvio.DA_INVIARE;
      } else return undefined;
    },
    isAttivoEliminabile: (st, getters) => {
      return (
        getters.isAttivoModificabile &&
        !R.isNil(st.docAttivo) &&
        getters.isUltimaEmessa &&
        getters.isAttivoDaInviare
      );
    },
    getDocDaCorreggere: (st) => st.fatture.filter(filterDaCorreggere)?.pop(),
    getDocDaInviare: (st) => st.fatture.filter(filterDaInviare)?.pop(),
    // getIdAggiornabiliSDI: st => st.fatture.filter(f => isAggiornabileSDI(f)).map(f => f.id),
    // isRefreshSDINeeded:   st => st.fatture.some(f => isAggiornabileSDI(f)),
    // isRefreshingSDI:      st => st.refreshingSDI,
    sospesoSTS: (st) => st.fatture.filter(filterSospeso),
    // sospesoSDI:           st => st.fatture.filter(filterSospeso),
    hasSospeso: (st) => st.fatture.filter(filterSospeso).length > 0,
    hasDaCorreggere: (st) => st.fatture.filter(filterDaCorreggere).length > 0,
    hasDaInviare: (st) => st.fatture.filter(filterDaInviare).length > 0,
    elencoMessaggi: (st) => getElencoMessaggi(st.docAttivo),
    ultimiMessaggi: (st) => getUltimiMessaggi(st.docAttivo),
    // lastRefresh:          st => st.lastRefresh,
  },
};
export default fattureModule;
