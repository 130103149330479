import { startOfMonth, subDays } from "date-fns";

const DateDefaults = {
  formats: {
    CLIENT: "yyyy-MM-dd",
    COMPAT: "yyyyMMdd",
    ISO_8601: "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
    VIEW: "dd-MM-yyyy",
    ONLY_DATE: "dd/MM/yyyy",
    LOG: "dd/MM/yy HH:mm:ss",
    COMPAT_MESE: "LLL",
    MESE_ANNO: "MMMM yyyy",
    ANNO: "yyyy",
  },

  DATA_INIZIO: new Date(2021, 1, 1),
  PRIMO_DEL_MESE: startOfMonth(new Date()),
  OGGI: new Date(),
  UN_MESE_FA: subDays(new Date(), 30),
};

export default DateDefaults;

