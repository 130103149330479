import Vue from "vue";
import Vuex from "vuex";
import VuexReset from "@ianwalter/vuex-reset";
// import VuexPersistence from 'vuex-persist'

import { R } from "mida4-web-app-utils";
import { getNumeroDoc } from "mida4-fattura-rapida-spese-sanitarie/doc";

import userModule from "./modules/user";
import authModule from "./modules/auth";
import fattureModule from "./modules/fatture";
import clientiModule from "./modules/clienti";
import statsModule from "./modules/stats";
import elaborandaModule from "./modules/elaboranda";

Vue.use(Vuex);

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage,
//   // reducer: (state) => ({
//   //   user: state.user
//   // })
//   //filter: (mutation) => mutation.type == 'addNavItem'
// })

const store = new Vuex.Store({
  state: {
    activeSection: null,
    activeId: null,
  },
  modules: {
    user: userModule,
    auth: authModule,
    elaboranda: elaborandaModule,
    fatture: fattureModule,
    clienti: clientiModule,
    stats: statsModule,
  },
  plugins: [VuexReset()],
  // plugins: [vuexLocal.plugin],
  mutations: {
    setActiveSection(state, section) {
      if (section != undefined) {
        state.activeSection = section;
      } else state.activeSection = null;
    },
    reset: () => {},
  },
  actions: {
    setActiveSection({ commit }, section) {
      commit("setActiveSection", section);
    },
  },
  getters: {
    getActiveSection(state) {
      return state.activeSection;
    },
    getActiveId(state) {
      return getNumeroDoc(state.fatture.docAttivo);
    },
  },
});

// Reset state
store.initialState = R.clone(store.state);
store.resetState = () => {
  store.replaceState(store.initialState);
};

export default store;
