<template>
  <div v-frag :key="'v_frag_' + hasFatture">
    <ButtonScaricaSpSa v-if="showBtn" :mute="! stato.showScaricaZip" :requesting="stato.requestingDownload" @scarica="$emit('scaricaFatture')"/>
    <table :id="`elenco-fatture${idPart}`">
      <caption v-if="stato.dashboard && stato.direzione === 'emesse'" class="dashboard">Da {{stato.subset}}</caption>
      <HeadersDocumentiSpSa
        v-if="hasFatture"
        :key="JSON.stringify(cols.headers)"
        :headers="cols.headers"
        :isDashboard="stato.dashboard"
        @orderBy="cols.orderBy"
        @resetOrder="cols.resetOrder"/>
      <slot :hasFatture="hasFatture"/>
    </table>
    <MsgNoFattureSpSa v-if="! hasFatture && ! stato.dashboard" :part="idPart"/>
  </div>
</template>

<script>
import ButtonScaricaSpSa from '@/components/DocumentiSpSa/_common/ButtonScaricaSpSa'
import HeadersDocumentiSpSa from '@/components/DocumentiSpSa/_common/HeadersDocumentiSpSa'
import MsgNoFattureSpSa from '@/components/DocumentiSpSa/_common/MsgNoFattureSpSa'

const NAME = 'ElencoDocumentiSpSa'

export default {
  components: {
    ButtonScaricaSpSa,
    HeadersDocumentiSpSa,
    MsgNoFattureSpSa
  },
  props: ['cols', 'fatture', 'stato'],
  data(){
    return {
      NAME: NAME
    }
  },
  computed: {
    hasFatture(){
      return this.fatture && this.fatture.length > 0
    },
    showBtn(){
      return !this.stato.dashboard && this.hasFatture
    },
    idPart(){
      return (this.isDashboard ? `-${this.subset}` : '')
    }
  }
}
</script>