<template>
  <div id="loader">
    <div class="lds-roller">
      <div v-for="n in 8" :key="n"></div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";

export default {
  name: "LoaderSpSa",
  computed: {
    loader() {
      return document.getElementById("loader");
    },
  },
  mounted() {
    bus.$on("showLoader", this.doShow);
    bus.$on("hideLoader", this.doHide);
  },
  beforeDestroy() {
    bus.$off("showLoader", this.doShow);
    bus.$off("hideLoader", this.doHide);
  },
  methods: {
    show() {
      bus.$emit("showLoader");
    },
    hide() {
      bus.$emit("hideLoader");
    },
    doShow() {
      this.loader.style.left = "0";
    },
    doHide() {
      this.loader.style.left = "-100%";
    },
  },
};
</script>

<style src="@/styles/custom/components/_common/_loader.scss" lang="scss"></style>