<template>
  <h5 id="msg-no-fatture">Non ci sono fatture corrispondenti ai parametri di ricerca impostati</h5>
</template>

<script>
const NAME = 'MsgNoFattureSpSa'
export default {
  name: NAME,
  props: ['part'],
  computed: {
    idPart(){
      return this.part || ''
    }
  }
}
</script>