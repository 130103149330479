const ModalitaPagamento = Object.freeze({
  MP01: "Contanti",
  MP02: "Assegno",
  MP05: "Bonifico",
  MP08: "Carta di credito",
  getEnum(opts) {
    const valPropName = opts?.valPropName ?? "value";
    const lblPropName = opts?.lblPropName ?? "label";
    // eslint-disable-next-line no-unused-vars
    return Object.entries(this)
      .filter(([_, v]) => typeof v === "string")
      .map(([k, v]) => ({
        [valPropName]: k,
        [lblPropName]: v,
      }));
  },
  getEnumWithCodes(opts) {
    return this.getEnum(opts).map((e) => ({
      ...e,
      label: e.label + " - " + e.value,
    }));
  },
  getDefault() {
    return "MP08";
  },
  isTracciabile(mod) {
    return mod !== "MP01";
  },
});

export default ModalitaPagamento;
