import Vue from "vue";
import frag from "vue-frag";

import VueNotifications from "vue-notifications";
import swal from "sweetalert2";
import VTooltip from "v-tooltip";
import VueApexCharts from "vue-apexcharts";
import VueParticles from "vue-particles";
import vuetify from "@/plugins/vuetify";

import { Forms, Log } from "mida4-web-app-utils";
import { DefaultsSpSa as APP_DEFAULTS } from "@/entities/enums/defaultsSpSa";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArchive,
  faArrowUp,
  faArrowLeft,
  faCheck,
  faCheckCircle,
  faCode,
  faDownload,
  faEdit,
  faExclamation,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileUpload,
  faInfoCircle,
  faLock,
  faPlus,
  faPrint,
  faQuestion,
  faQuestionCircle,
  faShareSquare,
  faSync,
  faSpinner,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlink,
  faUser,
  faUserCircle,
  faUsers,
  faSave,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./api";

function toast({ title, message, type, html, didClose }) {
  if (type === VueNotifications.types.warn) type = "warning";
  return html !== null && html !== undefined
    ? swal.fire({ icon: type, title: title, html: html, didClose: didClose })
    : swal.fire({
        icon: type,
        title: title,
        text: message,
        didClose: didClose,
      });
}

Vue.prototype.$analytics = Log.analytics;

window.addEventListener("error", function (e) {
  if (
    e.origin !== APP_DEFAULTS.IS_PROD
      ? process.env.VUE_APP_BASE_URL
      : process.env.VUE_APP_TEST_URL
  )
    return;
  Log.analytics.logEvent("error", { value: e.message });
});

const plugins = [
  { name: VTooltip },
  { name: VueApexCharts },
  { name: VueParticles },
  {
    name: VueNotifications,
    opts: {
      success: toast,
      error: toast,
      info: toast,
      warn: toast,
    },
  },
  { name: api },
];

plugins.forEach((p) => {
  try {
    if (p.opts === undefined) {
      Vue.use(p.name);
    } else {
      Vue.use(p.name, p.opts);
    }
  } catch (error) {
    Log.error(`errore nell'attivazione del plugin ${p.name}: `, error);
  }
});

Vue.component("apexchart", VueApexCharts);

library.add(
  faArchive,
  faArrowUp,
  faArrowLeft,
  faCheck,
  faCheckCircle,
  faCode,
  faDownload,
  faEdit,
  faExclamation,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileUpload,
  faInfoCircle,
  faLock,
  faPlus,
  faPrint,
  faQuestion,
  faQuestionCircle,
  faShareSquare,
  faSync,
  faSpinner,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlink,
  faUser,
  faUserCircle,
  faUsers,
  faSave,
  faRedo
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);

// permette di definire elementi root multipli
// rif: https://github.com/privatenumber/vue-frag
Vue.directive("frag", frag);
Vue.directive("noac", {
  // When the bound element is inserted into the DOM...
  componentUpdated: function (el) {
    Vue.nextTick(() => {
      Forms.disabilitaAutoComplete(el);
    });
  },
});

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== "production";

export const bus = new Vue();

Vue.prototype.toggleLoader = (show) =>
  bus.$emit(show ? "showLoader" : "hideLoader");
Vue.prototype.DEFAULTS = APP_DEFAULTS;
Vue.prototype.$log = Log;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#spsa_root");

