const ValoriIVA = Object.freeze({
  // aliquote
  5: { lbl: "5", appliedTo: ["STS", "SDI"] },
  10: { lbl: "10", appliedTo: ["STS", "SDI"] },
  22: { lbl: "22", appliedTo: ["STS", "SDI"] },
  // nature
  N1: { lbl: "Fuori Campo art. 15 DPR 633/72", appliedTo: ["STS", "SDI"] },
  "N2.1": {
    lbl: "non soggette ad IVA ai sensi degli artt. da 7 a 7-septies del DPR 633/72",
    appliedTo: ["SDI"],
  },
  "N2.2": { lbl: "Operazione non soggetta ad IVA", appliedTo: ["STS", "SDI"] },
  "N3.1": { lbl: "non imponibili - esportazioni", appliedTo: ["SDI"] },
  "N3.2": {
    lbl: "non imponibili - cessioni intracomunitarie",
    appliedTo: ["SDI"],
  },
  "N3.3": {
    lbl: "non imponibili - cessioni verso San Marino",
    appliedTo: ["SDI"],
  },
  "N3.4": {
    lbl: "non imponibili - operazioni assimilate alle cessioni all'esportazione",
    appliedTo: ["SDI"],
  },
  "N3.5": {
    lbl: "non imponibili - a seguito di dichiarazioni d'intento",
    appliedTo: ["SDI"],
  },
  "N3.6": {
    lbl: "non imponibili - altre operazioni che non concorrono alla formazione del plafond",
    appliedTo: ["SDI"],
  },
  N4: {
    lbl: "Operazione esente art. 10 DPR 633/72",
    appliedTo: ["STS", "SDI"],
  },
  N5: {
    lbl: "regime del margine / IVA non esposta in fattura",
    appliedTo: ["SDI"],
  },
  "N6.1": {
    lbl: "inversione contabile - cessione di rottami e altri materiali di recupero",
    appliedTo: ["SDI"],
  },
  "N6.2": {
    lbl: "inversione contabile - cessione di oro e argento ai sensi della legge 7/2000 nonché di oreficeria usata ad OPO",
    appliedTo: ["SDI"],
  },
  "N6.3": {
    lbl: "inversione contabile - subappalto nel settore edile",
    appliedTo: ["SDI"],
  },
  "N6.4": {
    lbl: "inversione contabile - cessione di fabbricati",
    appliedTo: ["SDI"],
  },
  "N6.5": {
    lbl: "inversione contabile - cessione di telefoni cellulari",
    appliedTo: ["SDI"],
  },
  "N6.6": {
    lbl: "inversione contabile - cessione di prodotti elettronici",
    appliedTo: ["SDI"],
  },
  "N6.7": {
    lbl: "inversione contabile - prestazioni comparto edile e settori connessi",
    appliedTo: ["SDI"],
  },
  "N6.8": {
    lbl: "inversione contabile - operazioni settore energetico",
    appliedTo: ["SDI"],
  },
  "N6.9": { lbl: "inversione contabile - altri casi", appliedTo: ["SDI"] },
  N7: {
    lbl: "IVA assolta in altro stato UE (prestazione di servizi di telecomunicazioni, tele-radiodiffusione ed elettronici ex art. 7-sexies lett. f, g, art. 74-sexies DPR 633/72)",
    appliedTo: ["SDI"],
  },

  getEntries(dest) {
    return Object.entries(this)
      .filter(([_, v]) => v.lbl !== undefined && v.appliedTo.includes(dest))
      .map(([k, v]) => [k, v.lbl]);
  },
  getEnum(dest, opts) {
    const valPropName = opts?.valPropName ?? "value";
    const lblPropName = opts?.lblPropName ?? "label";
    // eslint-disable-next-line no-unused-vars
    return Object.entries(this)
      .filter(([_, v]) => v.lbl !== undefined)
      .map(([k, v]) => ({
        [valPropName]: k,
        [lblPropName]: isNaN(v.lbl) ? `${k} - ${v.lbl}` : `${v.lbl}%`,
      }));
  },
  getDefault: (regimeFiscale, dest) => {
    if (dest === "SDI") {
      return regimeFiscale === "RF01" ? "22" : "N2.2";
    } else {
      return regimeFiscale === "RF01" ? "N4" : "N2.2";
    }
  },
});

export default ValoriIVA;
