import Vue from "vue";
import Vuetify from "vuetify/lib";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      eye: {
        // custom icon I want to use
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "eye"],
        },
      },
      eyeSlash: {
        // custom icon I want to use
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "eye-slash"],
        },
      },
    },
  },
});
