import { R } from "mida4-web-app-utils";
import { CassaPrevidenziale } from "mida4-fattura-rapida-spese-sanitarie";
import StatusCodes from "http-status-codes";
import { parseISO } from "date-fns";
import { getUserDefaults } from "mida4-fattura-rapida-spese-sanitarie/doc";
import Invianti from "@/entities/enums/invianti";

const userModule = {
  namespaced: true,
  state: () => ({
    data: null,
    daVerificare: null,
  }),
  mutations: {
    setUser(state, userData) {
      if (userData) {
        state.data = userData;
      }
    },
    setDaVerificare(state) {
      state.daVerificare = true;
    },
    setDatiSTS(state, datiSTS) {
      state.data = {
        ...state.data,
        ...datiSTS,
      };
    },
  },
  actions: {
    setUser({ commit }, userData) {
      commit("setUser", userData);
    },
    newUser({ commit }) {
      commit("setDaVerificare");
    },
    setDatiSTS({ commit }, datiSTS) {
      commit("setDatiSTS", datiSTS);
    },
    async richiediResetPassword(_, cfInput) {
      const res = await this._vm.$api.richiediResetPassword(cfInput);
      if (res.status != StatusCodes.OK) {
        const r = await res.json();
        throw Error(r.message);
      }
    },
    async refreshDatiPagamento({ dispatch, getters }) {
      const res = await this._vm.$api.datiUtente();
      if (res.status != StatusCodes.OK) {
        const r = await res.json();
        throw Error(r.message);
      } else {
        const datiUtente = await res.json();
        dispatch("setUser", {
          ...datiUtente,
          defaultDocumento: getUserDefaults(datiUtente.defaultDocumento),
        });
        return !getters.scaduto;
      }
    },
  },
  getters: {
    daVerificare: (state) => state.daVerificare,
    getData: (state) => state.data,
    getDefaults: (state) =>
      state.data === null ? {} : state.data.defaultDocumento,
    getDefaultCassaVal: (state) =>
      CassaPrevidenziale.enti[
        state.data.defaultDocumento.tipoCassaPrevidenziale
      ].applicatoDefault,
    dataScadenza: (state) => parseISO(state.data.dataScadenza),
    inProva: (state) => state.data.periodoProva,
    registrato: (state) =>
      !(
        R.isEmpty(state.data.codiceClienteMida4) ||
        R.isNil(state.data.codiceClienteMida4)
      ),
    scaduto: (state) => state.data.scaduto,
    isStruttura: (state) => Invianti.isStruttura(state.data.tipoSoggetto),
  },
};
export default userModule;

