import { Log, R, DateDefaults } from "mida4-web-app-utils";
import { endOfDay, format } from "date-fns";

const URL =
  process.env.NODE_ENV == "development"
    ? process.env.VUE_APP_TEST_URL
    : process.env.VUE_APP_BASE_URL;
const AUTHORIZE = true;
const INVIO_SDI_DISABILITATO = true;
const date2ApiStr = (d) =>
  encodeURIComponent(format(d, DateDefaults.formats.ISO_8601));

const api = {
  uris: {
    resetPassword: "/user/reset",
    requestResetPassword: "/user/resetRequest",
    verificaPassword: "/user/confirm-user",
    reimpostaCredenzialiServizio: "/user/reset-credenziali-sts",
    user: "/user",
    login: "/user/login",
    fatture: "/fatture",
    zipArchivioFatture: "/fatture/zip",
    xmlFattura: "/fatture/xml",
    invio: "/fatture/invia",
    statistiche: "/fatture/stats",
    rinnovi: "/stripe",
    clienti: "/clienti",
  },
  auth: null,
  getUrl(uri, auth, params) {
    let base = URL;
    let paramsString = "";

    if (auth) {
      base += "/auth";
    }
    if (!(R.isNil(params) || R.isEmpty(params))) {
      paramsString = Object.entries(params).reduce(
        (parStr, [k, v], i) => `${parStr}${i > 0 ? "&" : ""}${k}=${v}`,
        "?"
      );
    }
    return base + uri + paramsString;
  },
  setAuth({ token }) {
    this.auth = { Authorization: `Bearer ${token}` };
  },
  getAuthHeaders(headers) {
    if (this.auth === null) throw new Error("L'utente non risulta autenticato");
    return R.mergeLeft(headers, this.auth);
  },
  call({ method, uri, params, payload, auth, headers }) {
    if (auth == undefined) {
      auth = !AUTHORIZE;
    }

    const url = this.getUrl(uri, auth, params);

    if (R.isNil(headers)) {
      headers = { "Content-type": "application/json" };
    }

    if (auth) {
      headers = this.getAuthHeaders(headers);
    }

    let data = {
      method,
      headers,
    };

    if (R.isNil(payload)) {
      Log.info(`${method} ${url.substring(URL.length)}`);
    } else {
      Log.info(`${method} ${url.substring(URL.length)}`, Log.sanitize(payload));
      data.body = JSON.stringify(payload);
    }

    return fetch(url, data);
  },
  registraUtente(utente, recaptchaToken) {
    return this.call({
      method: "POST",
      params: {
        "g-recaptcha-response": recaptchaToken,
      },
      uri: this.uris.user,
      payload: utente,
    });
  },
  richiediResetPassword(codiceFiscale) {
    return this.call({
      method: "PUT",
      uri: `${this.uris.requestResetPassword}/${codiceFiscale}`,
    });
  },
  resetPassword(datiReset, recaptchaToken) {
    return this.call({
      method: "POST",
      params: {
        "g-recaptcha-response": recaptchaToken,
      },
      uri: this.uris.resetPassword,
      payload: datiReset,
    });
  },
  verificaPassword(password) {
    return this.call({
      method: "POST",
      uri: this.uris.verificaPassword,
      auth: AUTHORIZE,
      payload: {
        username: null,
        password,
      },
    });
  },
  reimpostaCredenzialiServizio(nuoveCredenziali) {
    return this.call({
      method: "PUT",
      uri: this.uris.reimpostaCredenzialiServizio,
      auth: AUTHORIZE,
      payload: nuoveCredenziali,
    });
  },
  login(datiLogin) {
    return this.call({
      method: "POST",
      uri: this.uris.login,
      payload: datiLogin,
    });
  },
  datiUtente() {
    return this.call({
      method: "GET",
      uri: this.uris.user,
      auth: AUTHORIZE,
    });
  },
  aggiornaDatiUtente(utente) {
    return this.call({
      method: "PUT",
      uri: this.uris.user,
      payload: utente,
      auth: AUTHORIZE,
    });
  },
  aggiungiFattura(fattura) {
    return this.call({
      method: "POST",
      uri: this.uris.fatture,
      payload: fattura,
      auth: AUTHORIZE,
    });
  },
  modificaFattura(fattura) {
    return this.call({
      method: "PUT",
      uri: `${this.uris.fatture}/${fattura.id}`,
      payload: fattura,
      auth: AUTHORIZE,
    });
  },
  aggiornaFatturaSDI(id, q) {
    let qry = R.isNil(q) ? `aggiorna/${id}` : `${q}/${id}`;
    return this.call({
      method: "GET",
      uri: `${this.uris.fatture}/${qry}`,
      auth: AUTHORIZE,
    });
  },
  eliminaFattura(fattura) {
    return this.call({
      method: "DELETE",
      uri: `${this.uris.fatture}/${fattura.id}`,
      auth: AUTHORIZE,
    });
  },
  inviaFattura(credenzialiSTS, id) {
    return this.call({
      method: "PUT",
      uri: `${this.uris.invio}/${id}`,
      payload: credenzialiSTS,
      auth: AUTHORIZE,
    });
  },
  impostaRinnovo() {
    return this.call({
      method: "GET",
      uri: this.uris.rinnovi,
      auth: AUTHORIZE,
    });
  },

  elencoClienti() {
    return this.call({
      method: "GET",
      uri: this.uris.clienti,
      auth: AUTHORIZE,
    });
  },

  salvaCliente(cliente) {
    return this.call({
      method: "POST",
      auth: AUTHORIZE,
      uri: this.uris.clienti,
      payload: cliente,
    });
  },

  modificaCliente(cliente) {
    return this.call({
      method: "PUT",
      auth: AUTHORIZE,
      uri: this.uris.clienti,
      payload: cliente,
    });
  },

  eliminaCliente(id) {
    return this.call({
      method: "DELETE",
      auth: AUTHORIZE,
      uri: `${this.uris.clienti}/${id}`,
    });
  },

  elencoFatture({ dest, from, to }) {
    if (INVIO_SDI_DISABILITATO) {
      dest = "STS";
    }
    return this.call({
      method: "GET",
      uri: this.uris.fatture,
      auth: AUTHORIZE,
      params: R.isNil(dest)
        ? {
            from: date2ApiStr(from),
            to: date2ApiStr(endOfDay(to)),
          }
        : {
            dest: dest,
            from: date2ApiStr(from),
            to: date2ApiStr(endOfDay(to)),
          },
    });
  },
  zipArchivioFatture({ dest, from, to }) {
    if (INVIO_SDI_DISABILITATO) {
      dest = "STS";
    }
    return this.call({
      method: "GET",
      uri: this.uris.zipArchivioFatture,
      auth: AUTHORIZE,
      params: R.isNil(dest)
        ? {
            from: date2ApiStr(from),
            to: date2ApiStr(endOfDay(to)),
          }
        : {
            dest: dest,
            from: date2ApiStr(from),
            to: date2ApiStr(endOfDay(to)),
          },
    });
  },
  xmlFattura(id) {
    return this.call({
      method: "GET",
      uri: `${this.uris.xmlFattura}/${id}.xml`,
      auth: AUTHORIZE,
    });
  },
  statistiche() {
    return this.call({
      method: "GET",
      uri: this.uris.statistiche,
      auth: AUTHORIZE,
    });
  },
};

// api definito come plugin di vue
export default {
  install: function (Vue) {
    Object.defineProperty(Vue.prototype, "$api", { value: api });
  },
};

