const REGEXES = Object.freeze({
  PRIMO_CARATTERE: /^./,
  MAIUSCOLE: /([A-Z])/g,
  NO_ALPHANUM: /^[^A-Za-z0-9]+$/,
  NO_ALPHANUM_SPACES: /^[^A-Za-z0-9\s]+$/,
  NO_ALPHA: /[^A-Za-z]/g,
  ALPHANUM: /[A-Za-z0-9]/g,
  ALPHANUM_SPACES: /[A-Za-z0-9\s]/g,
  PUNTO_E_ALTRO: /\..*$/,
  NUMERO_FORMATO_IT: /^(?!0|00)[0-9]*(,\d{3})*(\.[0-9]{0,2})?$/,
  NUMERO_FORMATO_US: /^(?!0|00)[0-9]*(\.\d{3})*(,[0-9]{0,2})?$/,
  DECIMALI_A_ZERO: /\D00$/,
  BOLLO: /bollo/,
  CASSA_PREVIDENZIALE: /^cassaPrevidenziale_/,
  RITENUTA: /^ritenuta_/,
  BOLLO_O_CASSA: /bollo|^cassaPrevidenziale_/,
  BOLLO_CASSA_RITENUTA: /bollo|^cassaPrevidenziale_|^ritenuta_/,
  ERR_INTERNO_FORM_UTENTE: /Forms\.check|Doc\.getUtenteDaSalvare/,
  ERR_INTERNO_LOGIN: /Doc\.getUserDefaults/,
  RIQUADRO_UTENTE: /.*riquadro-utente/,
  RIQUADRO_ASSISTENZA: /.*riquadro-assistenza/,
  NUM_O_SEP_DEC: /[\d,\.]/,
  CRED_STS_ASSENTI_MSG: /^Required request body is missing/,
  COD_REG_INVALID_MSG:
    /cvc-simple-type(.*)codiceregione value(.*)is not a valid instance of type/,
  COD_ASL_INVALID_MSG:
    /cvc-simple-type(.*)codiceasl value(.*)is not a valid instance of type/,
  COD_SSA_INVALID_MSG:
    /cvc-simple-type(.*)codicessa value(.*)is not a valid instance of type/,
  STS_AUTH_FAIL: /^Autenticazione negata/,
});

export default REGEXES;
