<template>
  <ElencoDocumentiSpSa
    :cols="cols"
    :fatture="fatture"
    :stato="stato"
    @scaricaFatture="$emit('scaricaFatture')"
  >
    <tbody class="elenco-fatture">
      <tr
        v-for="f in elenco"
        :key="f.id"
        :set="(attrs = getAttrsStatoInvio(f))"
        :class="f.statoInvio.toLowerCase()"
      >
        <td>{{ getNumeroDoc(f) }}</td>
        <td>{{ formatData(f.dataDocumento) }}</td>
        <td>{{ f.cliente.cognomeDenom }} {{ f.cliente.nome }}</td>
        <td class="importo">
          {{ f.tipoDocumento === "TD04" ? "- " : ""
          }}{{ getImportoTotale(f.righe) }} €
        </td>
        <td class="statoInvio" v-if="dashboard !== true && isMobile">
          <font-awesome-icon
            :icon="attrs.icoInvio"
            :color="attrs.icoInvioColor"
            v-tooltip="attrs.txtInvio"
          />
        </td>
        <td class="actionable">
          <font-awesome-icon
            @click="dettaglioFattura(f)"
            v-tooltip="attrs.txtDettaglio"
            :icon="attrs.icoDettaglio"
          />
        </td>
        <td class="actionable">
          <font-awesome-icon
            icon="print"
            v-if="attrs.stampabile"
            @click="stampaFattura(f)"
            v-tooltip="'Stampa'"
          />
        </td>
      </tr>
    </tbody>
  </ElencoDocumentiSpSa>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import {
  getNumeroDoc,
  formatDataOnlyDate,
  getUltimiMessaggi,
  asString,
  getImportoTotale,
} from "mida4-fattura-rapida-spese-sanitarie/doc";
import ElencoDocumentiSpSa from "@/components/DocumentiSpSa/_common/ElencoDocumentiSpSa";
import commonFunctions from "@/components/DocumentiSpSa/_mixins/commonFunctions";

const NAME = "FattureEmesseSpSa";

export default {
  components: {
    ElencoDocumentiSpSa,
  },
  props: [
    "fatture",
    "dashboard",
    "subset",
    "requestingDownload",
    "showScaricaZip",
  ],
  mixins: [commonFunctions],
  data() {
    return {
      NAME: NAME,
      elenco: [],
      // cols: {
      //   headers: [
      //     { value: "Numero", orderBy: { field: "numeroDocumento", dir: null } },
      //     { value: "Data", orderBy: { field: "dataDocumento", dir: null } },
      //     { value: "Cliente", orderBy: { field: "cliente", dir: null } },
      //     { value: "Importo Totale", class: "importo" },
      //     {
      //       value: "Stato invio",
      //       orderBy: { field: "statoInvio", dir: null },
      //       class: "statoInvio",
      //       hide: this.isDashboard,
      //     },
      //   ],
      //   orderBy: this.orderBy,
      //   resetOrder: this.resetOrder,
      // },
      stato: {
        direzione: "emesse",
        dashboard: this.dashboard,
        subset: this.subset,
        requestingDownload: this.requestingDownload,
        showScaricaZip: this.showScaricaZip,
      },
    };
  },
  watch: {
    fatture(val) {
      this.elenco = val;
    },
  },
  computed: {
    ...mapGetters({
      datiUtente: "user/getData",
      lastRefresh: "fatture/lastRefresh",
    }),
    isMobile() {
        return this.$vuetify.breakpoint.width > 600
    },
    cols() {
        let _headers = [
        { value: "Numero", orderBy: { field: "numeroDocumento", dir: null } },
        { value: "Data", orderBy: { field: "dataDocumento", dir: null } },
        { value: "Cliente", orderBy: { field: "cliente", dir: null } },
        { value: "Importo Totale", class: "importo"},
        {
          value: "Stato invio",
          orderBy: { field: "statoInvio", dir: null },
          class: "statoInvio",
          hide: this.isDashboard || this.$vuetify.breakpoint.width < 600 ,
        },
      ];

      return {
        headers: _headers,
        orderBy: this.orderBy,
        resetOrder: this.resetOrder,
      };
      }
  },
  methods: {
    ...mapMutations({
      setDocAttivo: "fatture/setDocAttivo",
    }),

    getNumeroDoc,
      setElencoFatture() {
        this.elenco = this.fatture;
      },
      formatData: (d) => formatDataOnlyDate(d),
      getImportoTotale: (righe) => asString(getImportoTotale(righe)),
      dettaglioFattura(f) {
        this.setDocAttivo(f);
        this.$router.push(`/fattura/${f.id}`);
      },
      stampaFattura(f) {
        // this.$log.info('stampo doc', f.id)
        const url =
          process.env.NODE_ENV == "development"
            ? process.env.VUE_APP_TEST_URL
            : process.env.VUE_APP_BASE_URL;
        window.open(
          `${url}/stampa/doc/${this.datiUtente.codiceFiscale}/${f.id}.pdf`,
          "_blank"
        );
      },
      getAttrsStatoInvio(f) {
        let o = null;
        switch (f.statoInvio) {
          case "LOCALE":
            break;
          case "DA_INVIARE":
            o = {
              txtInvio: "Da inviare",
              icoInvio: "share-square",
              icoInvioColor: "dimgray",
              icoDettaglio: "edit",
              txtDettaglio: "Modifica",
              stampabile: false,
            };
            break;
          case "ATTESA_ESITO":
            o = {
              txtInvio: "In attesa dell'esito invio",
              icoInvio: "spinner",
              icoInvioColor: "dimgray",
              icoDettaglio: "eye",
              txtDettaglio: "Visualizza",
              stampabile: false,
            };
            break;
          case "ESITO_OK":
            o = {
              txtInvio: "Inviato correttamente",
              icoInvio: "check-circle",
              icoInvioColor: "green",
              icoDettaglio: "eye",
              txtDettaglio: "Visualizza",
              stampabile: true,
            };
            break;
          case "ESITO_WARN":
            o = {
              txtInvio: `Inviato correttamente<hr>Segnalazioni:<br>${getUltimiMessaggi(
                f,
                true
              )}`,
              icoInvio: "check-circle",
              icoInvioColor: "#80ff80",
              icoDettaglio: "eye",
              txtDettaglio: "Visualizza",
              stampabile: true,
            };
            break;
          case "ESITO_ERR":
            o = {
              txtInvio: `Il Servizio Tessera Sanitaria ha restituito un errore - Inoltro fallito.<hr>Errori:<br>${getUltimiMessaggi(
                f,
                true
              )}`,
              icoInvio: "question-circle",
              icoInvioColor: "orange",
              icoDettaglio: "edit",
              txtDettaglio: "Apri fattura",
              stampabile: false,
            };
            break;
          case "STS_UNAVAILABLE":
            o = {
              txtInvio: `Non è stato possibile raggiungere il servizio STS. Ritentare l'invio`,
              icoInvio: "exclamation-triangle",
              icoInvioColor: "orange",
              icoDettaglio: "edit",
              txtDettaglio: "Invia nuovamente",
              stampabile: false,
            };
            break;
          case "SDI_TIMEOUT":
            o = {
              txtInvio: `In attesa di conferma di presa in carico da parte del SDI`,
              icoInvio: "exclamation-triangle",
              icoInvioColor: "orange",
              icoDettaglio: "eye",
              txtDettaglio: "Visualizza",
              stampabile: false,
            };
            break;
          default:
            o = {
              txtInvio: `Questo stato (SDI_FALLITO) non esiste in base alla logica concordata e non si verificherà`,
              icoInvio: "exclamation-triangle",
              icoInvioColor: "red",
              icoDettaglio: "eye",
              txtDettaglio: "Visualizza",
              stampabile: false,
            };
        }
        return o;
      },
    },
  };
</script>

<style
  src="@/styles/custom/components/documenti/_fatture_emesse.scss"
  lang="scss"
></style>
