<template>
  <button id="scarica-tutte" :class="btnClass" @click="handleClick" v-tooltip="btnTooltip">
    <font-awesome-icon :icon="btnIco" :spin="requesting"/>
    Scarica
  </button>
</template>

<script>
const NAME = 'ButtonScaricaSpSa'
export default {
  name: NAME,
  props: ['mute', 'requesting'],
  computed: {
    btnClass(){
      if(this.muted) 
        return 'muted-button'
      return null
    },
    btnIco(){
      return this.requesting
      ? 'spinner'
      : 'download'
    },
    btnTooltip(){
      return this.muted
      ? "Per scaricare le fatture del periodo visualizzato selezionare tutti i clienti"
      : "Scarica un archivio zip delle fatture correntemente visualizzate<br>predisposte per l'invio telematico"
    }
  },
  methods: {
    handleClick(){
      if(! this.muted)
        this.$emit('scarica')
    }
  }
}
</script>

<style src="@/styles/custom/components/documenti/_button_scarica.scss" lang="scss"></style>