import curry from "ramda/src/curry";
import tap from "ramda/src/tap";

const IS_PROD = process.env.NODE_ENV === "production";
const STOP_LOG = ["production", "test"].includes(process.env.NODE_ENV); // false //

const LogLevels = Object.freeze({
  DEBUG: 3,
  INFO: 2,
  WARN: 1,
  ERROR: 0,
});

const DA_OFFUSCARE = /.*(?:password|pin).*/i;
const LOG_LEVEL = LogLevels.DEBUG;

const Log = {
  analytics: IS_PROD ? { logEvent: () => {} } : { logEvent: console.log }, // firebase.analytics() : { logEvent: console.log },
  levels: LogLevels,
  sanitize(payload) {
    const ret = { ...payload };
    Object.keys(payload).forEach((k) => {
      if (DA_OFFUSCARE.test(k)) {
        ret[k] = "******";
      }
    });
    return ret;
  },
  JSONstr(tag, json) {
    if (json === undefined) {
      json = tag;
      tag = "dati";
    }
    let data = JSON.stringify(
      json,
      (_, value) => {
        // Filtering out properties
        if (value === null || value === "") {
          return undefined;
        }
        return value;
      },
      2
    );
    return Log.do(`${tag}:`, data);
  },
  group(tag, collapsed = true) {
    if (!STOP_LOG) {
      console.groupEnd();
      if (collapsed) {
        console.groupCollapsed(tag);
      } else {
        console.group(tag);
      }
    }
  },
  endGroup(tag) {
    if (!STOP_LOG) {
      console.groupEnd(tag);
    }
  },
  do(tag, val, level) {
    if (STOP_LOG || (level && level > LOG_LEVEL)) {
      return val;
    }

    let isTable = false;
    let _log = console.log;

    switch (level) {
      case this.levels.WARN:
        tag = `W: ${tag}`;
        _log = console.warn;
        break;

      case this.levels.ERROR:
        tag = `E: ${tag}`;
        _log = console.error;
        break;

      case this.levels.DEBUG:
        tag = `D: ${tag}`;
        break;

      default:
        tag = `I: ${tag}`;
    }

    if (Array.isArray(val) && !IS_PROD) {
      if (tag !== undefined) {
        console.log(tag);
      }
      tag = val;
      val = undefined;
      isTable = true;
      _log = console.table;
    }

    return tap(
      curry((t, m) => {
        if (m !== undefined) {
          _log(t, m);
        } else {
          _log(t);
        }
      })(tag),
      isTable ? tag : val
    );
  },
  // TODO: utilizzare il parametro cat per derfinire sezioni di loog
  debug(tag, val, cat) {
    return this.do(tag, val, this.levels.DEBUG, cat);
  },
  info(tag, val, cat) {
    return this.do(tag, val, this.levels.INFO, cat);
  },
  warn(tag, val, cat) {
    return this.do(tag, val, this.levels.WARN, cat);
  },
  error(tag, val, cat) {
    return this.do(tag, val, this.levels.ERROR, cat);
  },
};
export default Log;
