<template>
  <div class="sections">
    <a
      :class="active ? 'active sliding' : 'sliding'"
      :href="route"
      @mouseenter="linkHover"
      @mouseleave="linkFree"
    >
      <font-awesome-icon :icon="icon" />
      <span ref="linkSpan" class="hover-underline-animation">{{ text }}</span>
    </a>
  </div>
</template>

<script>
export default {
  props: ["route", "icon", "text", "active"],

  name: "MenuSliderSpSa",

  methods: {
    linkHover() {
      this.$refs.linkSpan.classList.add("hovered");
    },
    linkFree() {
      this.$refs.linkSpan.classList.remove("hovered");
    },
  },
};
</script>

<style
  src="@/styles/custom/components/_layout/_menu_slider.scss"
  lang="scss"
></style>

