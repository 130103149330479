const RegimeFiscale = Object.freeze({
  RF01: "Ordinario",
  RF02: "Contribuenti minimi (art.1, c.96-117, L. 244/07)",
  RF19: "Regime forfettario (art.1, c.54-89, L. 190/2014)",
  puoApplicareRitenuta(regimeFiscale) {
    return !["RF02", "RF19"].includes(regimeFiscale);
  },
  getEnum(opts) {
    const valPropName = opts?.valPropName ?? "value";
    const lblPropName = opts?.lblPropName ?? "label";
    // eslint-disable-next-line no-unused-vars
    return Object.entries(this)
      .filter(([_, v]) => typeof v === "string")
      .map(([k, v]) => ({
        [valPropName]: k,
        [lblPropName]: v,
      }));
  },
  getDefault() {
    return "RF01";
  },
});

export default RegimeFiscale;
