import * as R from "ramda";
import { capitalizeAll } from "ramda-extension";
import Log from "./log";
import CassaPrevidenziale from "../mida4-fattura-rapida-spese-sanitarie/entities/regole/cassa-previdenziale";
import Ritenuta from "../mida4-fattura-rapida-spese-sanitarie/entities/regole/ritenuta";
import ValoriIVA from "../mida4-fattura-rapida-spese-sanitarie/entities/enums/valori-iva";

const Forms = {
  // FUNZIONI GENERALI

  /**
   * A partire da un elemento html
   * che sia un select o contenga un select
   * imposta a selezionato l'elemento con value == ''
   * che è l'elemento creato sulla base
   * dell'attributo placeholder del select
   */
  selezionaPlaceholder(el) {
    el.querySelectorAll("option").forEach((o) => {
      if (o.value !== "") {
        o.removeAttribute("selected");
      } else {
        o.selected = true;
      }
    });
    el.dispatchEvent(new Event("change"));
  },
  detectAutofill(element) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          window
            .getComputedStyle(element, null)
            .getPropertyValue("appearance") === "menulist-button"
        );
      }, 600);
    });
  },
  getOptionEl({ val, lbl, selected }) {
    const opt = document.createElement("option");
    opt.value = val;
    opt.textContent = lbl;
    opt.selected = selected;
    return opt;
  },
  /**
   * Reimposta il focus e la posizione del cursore
   * utilizzato dopo il salvataggio dei campi
   * generato dall'evento input
   * per ogni campo di ogni riga
   *
   * @param {object} focusData
   * @property {string} focusData.id - id dell'elemento
   * @property {number} focusData.pos - posizione del cursore
   */
  restoreCaretPosition({ id, pos }) {
    const el = document.getElementById(id);
    if (!R.isNil(el)) {
      el.focus();
      if (!R.isNil(pos)) {
        el.setSelectionRange(pos, pos);
      }
    }
  },
  disabilitaAutoComplete() {
    document
      .querySelectorAll("input")
      .forEach((el) => (el.autocomplete = "off"));
  },
  highlightRequired(fields) {
    document
      .querySelectorAll(`label`)
      .forEach((e) => e.classList.remove("required"));
    fields.forEach((f) =>
      document.querySelector(`label[for^=${f}]`)?.classList.add("required")
    );
  },
  evtTargetPreventPatternInvalid(evt) {
    const el = evt.target;
    if (el.pattern) {
      const newVal = "" + el.value + evt.key;
      const regex = new RegExp(el.pattern);
      if (!regex.test(newVal)) {
        evt.preventDefault();
        return false;
      }
    }
  },
  evTargetToUpper(evt) {
    evt.target.value = R.toUpper(evt.target.value);
  },
  evTargetCapitalize(evt) {
    evt.target.value = capitalizeAll(evt.target.value);
  },
  buildAvviso(msg) {
    const avviso = document.createElement("span");
    avviso.className = "invalid_warn";
    avviso.innerHTML = msg;
    return avviso;
  },

  // FUNZIONI SPECIFICHE PER FATTURA RAPIDA - SPESE SANITARIE

  /**
   * Imposta i valori di default per i componenti del form
   *
   * riceve un array di array: gli elementi interni sono le regole
   * nel formato [selettore CSS, attributo da valorizzare, valore]
   */
  impostaDefault(rules) {
    rules.forEach(
      ([sel, attr, val]) => (document.querySelector(sel)[attr] = val)
    );
  },

  /**
   * Riordina i form autogenerati
   *
   * CREA UN DIV con ID *id* IN CUI INSERISCE GLI
   * ELEMENTI CORRISPONDENTI A *content*
   * (opzionale) E LO INSERISCE PRIMA DELL'ELEMENTO
   * INDIVIDUATO DA *before*
   * DI DEFAULT POSIZIONA IL DIV ALLA FINE DEL FORM
   *
   * accetta un componente vue e un oggetto, le cui entries hanno
   *
   * - come chiave il nome di un componente.$ref che punta
   *   ad un form FormSchemaNative
   *
   * - come valore un array di oggetti così composti:
   *
   *  - id: l'id del div da creare. Gli input verranno
   *    inseriti all'interno di questo div
   *
   *  - before(opzionale): il selettore CSS corrispondente ad un
   *    elemento PRIMA del quale verrà inserito il div
   *    ** SE il campo viene omesso il div verrà inserito
   *    alla fine dell'elemento **
   *
   *  - content: un array di nomi campo che verranno utilizzati
   *    nell'espressione [data-fs-field^="NOMECAMPO"]
   *    per identificarli e spostarli all'interno del div
   *    identificato dal parametro id
   *
   *  - gridTemplateColumns: un'espressione css da inserire
   *    nella corrispondente regola, per definire il layout
   *    orizzontale
   */
  riordina(component, forms) {
    Object.entries(forms).forEach(([name, rules]) => {
      const form = component.$refs[name].form();
      const spostaIn = (el, contentArray) =>
        contentArray.forEach((i) => el.append(i));

      rules.forEach((r) => {
        let div = document.createElement("div");
        div.id = r.id;
        div.classList.add("non-bianco");
        if (!R.isNil(r.gridTemplateColumns)) {
          div.style.display = "grid";
          div.style.width = "100%";
          div.style.gap = "calc(1 * var(--u))";
          div.style.gridTemplateColumns = r.gridTemplateColumns;
        }
        if (!R.isNil(r.before)) {
          form.insertBefore(div, form.querySelector(r.before));
        } else {
          form.appendChild(div);
        }
        spostaIn(
          div,
          r.content.map((e) => form.querySelector(`[data-fs-field^="${e}"]`))
        );
      });
    });
  },

  /**
   * Validazione form multipli
   *
   * riceve una lista di form generati da FormSchemNative
   * e per ciascuno esegue la funzione checkValidity
   *
   * restituisce un array di id non validi
   */
  check(forms) {
    try {
      const errori = forms
        .filter((frm) => typeof frm.checkValidity === "function")
        .reduce((errs, frm) => {
          Log.debug("check form", frm);
          if (frm.checkValidity()) {
            return errs;
          } else {
            return [frm.$attrs.id, ...errs];
          }
        }, []);
      return errori.length === 0 ? null : errori;
    } catch (error) {
      error.message = `Forms.check | ${error.message}`;
      throw Error(error);
    }
  },
  handleErrors(inputEl, form) {
    inputEl.addEventListener("invalid", (e) => {
      let logGroupLabel = `invalid ${e.target.id}`;

      // Log.group(logGroupLabel);

      e.target.classList.add("has-error");
      form.setErrorMessage(`Dati non validi: ${e.target.title}`);

      Log.warn("ins:", e.target.value);
      Log.endGroup(logGroupLabel);

      window.scroll(0, 0);
    });
    const campiData = [
      "dataDocumento",
      "dataPagamento",
      "rimborsoDataDocumento",
    ];
    const triggeringEvt = campiData.includes(inputEl.name) ? "change" : "input";
    inputEl.addEventListener(triggeringEvt, (e) => {
      e.target.classList.remove("has-error");
      form.clearErrorMessage();
    });
  },
  impostaDefaultFormDocumento(oggi, dest = "STS") {
    let regoleComuni = [
      ['[data-fs-field^="dataDocumento"] input', "value", oggi],

      ['[data-fs-field^="importoTotale"]', "step", "0.01"],
      ['[data-fs-field^="importoTotale"]', "min", 0],
    ];
    let regoleSTS = [['[data-fs-field^="dataPagamento"] input', "value", oggi]];
    let regoleSDI = [];

    const regole =
      dest === "STS"
        ? [...regoleComuni, ...regoleSTS]
        : [...regoleComuni, ...regoleSDI];

    this.impostaDefault(regole);
  },
  /**
   * Compatta graficamente il form utente
   * inserendo più campi su ogni riga
   *
   * per il funzionamento fare riferimento a
   * Forms.riordina
   *
   * @param {object} v Vue instance
   * @param {string} dest destinazione fattura
   */
  riordinaFormUtente(v, dest) {
    // TODO: dest = v.DEFAULTS.DESTINAZIONE
    const isRegistrazione = !document
      .querySelector("section#dati-utente")
      .classList.contains("modifica-dati");

    // le regole possono essere specifiche o comuni
    // e possono essere determinate anche dal
    // posizionamento relativo gli uni con gli altri
    // degli elementi del form: se
    //

    const regoleComuni = {
      formUtente: [
        {
          id: "dati-fiscali",
          before: '[data-fs-field^="cognome"]',
          content: ["codiceFiscale", "partitaIVA"],
          gridTemplateColumns: "repeat(2, 6fr)",
        },
      ],
      formIndirizzo: [
        {
          id: "riga-ind-1",
          content: ["via", "numeroCivico"],
          gridTemplateColumns: "8fr 2fr",
        },
        {
          id: "riga-ind-2",
          content: ["comune", "provincia", "cap", "nazione"],
          gridTemplateColumns: "4fr 2fr 2fr 2fr",
        },
      ],
      formDefaultDoc: [],
    };
    const regoleSpecifiche = {
      STS: {
        formUtente: [
          // {
          //   id: "codici-sts",
          //   before: "fieldset",
          //   content: ["cfTitolare", "codiceRegione", "codiceASL", "codiceSSA"],
          //   gridTemplateColumns: "repeat(4, 3fr)",
          // },
          {
            id: "dati-contatto",
            before: "fieldset",
            content: ["email", "telefono"],
            gridTemplateColumns: "repeat(2, 6fr)",
          },
          {
            id: "dati-soggetto",
            before: "#dati-contatto",
            content: ["tipoSoggetto", "regimeFiscale", "iban"],
            gridTemplateColumns: "repeat(3, 4fr)",
          },
        ],
        formIndirizzo: [],
        formDefaultDoc: [
          {
            id: "dati-cassa-previdenziale",
            before: '[data-fs-field^="tipoSpesa"]',
            content: ["tipoCassaPrevidenziale", "aliquotaCassaPrevidenziale"],
            gridTemplateColumns: "7fr 3fr",
          },
          {
            id: "default-fattura",
            content: ["sezionaleSTS", "importo", "IVA"],
            gridTemplateColumns: "repeat(3, 4fr)",
          },
        ],
      },
      SDI: {
        formUtente: [
          {
            id: "dati-contatto",
            before: "fieldset",
            content: [
              "regimeFiscale",
              "email",
              "telefono",
              "codiceUtenteDownload",
            ],
            gridTemplateColumns: "repeat(4, 3fr)",
          },
          // {
          //   id: "dati-soggetto",
          //   before: "#dati-contatto",
          //   content: ["regimeFiscale", "iban"],
          //   gridTemplateColumns: "repeat(2, 6fr)",
          // },
        ],
        formIndirizzo: [],
        formDefaultDoc: [
          {
            id: "dati-ritenuta",
            before: '[data-fs-field^="causaleRitenuta"]',
            content: ["tipoRitenuta", "aliquotaRitenuta"],
            gridTemplateColumns: "7fr 3fr",
          },
          {
            id: "default-fattura-3",
            before: "#dati-ritenuta",
            content: ["sezionaleSDI", "is-default-bollo"],
            gridTemplateColumns: "3fr 7fr",
          },
          {
            id: "default-fattura-2",
            before: "#default-fattura-3",
            content: ["modalitaPagamento", "iban"],
            gridTemplateColumns: "3fr 7fr",
          },
          {
            id: "default-fattura",
            before: "#default-fattura-2",
            content: ["importo", "IVA"],
            gridTemplateColumns: "3fr 3fr 4fr",
          },
          {
            id: "dati-cassa-previdenziale",
            content: ["tipoCassaPrevidenziale", "aliquotaCassaPrevidenziale"],
            gridTemplateColumns: "7fr 3fr",
          },
        ],
      },
    };
    const regole = {
      formUtente: [
        ...regoleComuni.formUtente,
        ...regoleSpecifiche[dest].formUtente,
      ],
      formIndirizzo: [
        ...regoleComuni.formIndirizzo,
        ...regoleSpecifiche[dest].formIndirizzo,
      ],
      formDefaultDoc: [
        ...regoleComuni.formDefaultDoc,
        ...regoleSpecifiche[dest].formDefaultDoc,
      ],
    };

    this.riordina(v, regole);

    if (isRegistrazione) {
      const defaultsContainer = document.querySelector(
        "div#form-default-documento"
      );
      defaultsContainer.insertBefore(
        document.querySelector("#info-default"),
        defaultsContainer.querySelector("form")
      );
    }
  },
  riordinaFormDocumento(v, dest) {
    // TODO: dest = v.DEFAULTS.DESTINAZIONE
    const regoleComuni = [];
    const regoleSpecifiche = {
      STS: [
        {
          id: "riga-int-1",
          before: '[data-fs-field^="importoTotale"]',
          content: [
            "destinazioneFattura",
            "dataDocumento",
            "dataPagamento",
            "modalitaPagamento",
            "tipoDocumento",
          ],
          gridTemplateColumns: "repeat(4, 3fr)",
        },
        {
          id: "riga-int-2",
          before: '[data-fs-field^="importoTotale"]',
          content: ["rimborsoDataDocumento", "rimborsoNumeroDocumento"],
        },
      ],
      SDI: [
        {
          id: "riga-int-1",
          before: '[data-fs-field^="importoTotale"]',
          content: [
            "destinazioneFattura",
            "dataDocumento",
            "modalitaPagamento",
            "tipoDocumento",
          ],
          gridTemplateColumns: "repeat(3, 4fr)",
        },
      ],
    };
    const regole = {
      formDettaglioDoc: [...regoleComuni, ...regoleSpecifiche[dest]],
    };

    this.riordina(v, regole);
  },
  riordinaFormClienteDocumento(v, dest) {
    // TODO: dest = v.DEFAULTS.DESTINAZIONE
    const regoleComuni = {
      formCliente: [
        {
          id: "dati-contatto",
          before: "fieldset",
          content: ["telefono", "email"],
          gridTemplateColumns: "4fr 4fr",
        },
      ],
      formIndirizzo: [
        {
          id: "riga-ind-1",
          content: ["via", "numeroCivico"],
          gridTemplateColumns: "8fr 2fr",
        },
        {
          id: "riga-ind-2",
          content: ["comune", "provincia", "cap", "nazione"],
          gridTemplateColumns: "4fr 2fr 2fr 2fr",
        },
      ],
    };
    const regoleSpecifiche = {
      STS: {
        formCliente: [],
      },
      SDI: {
        formCliente: [
          {
            id: "dati-fiscali",
            before: '[data-fs-field^="cognome"]',
            content: [
              "codiceFiscale",
              "partitaIVA",
              "paesePartitaIVA",
              "codiceSDI",
            ],
            gridTemplateColumns: "4fr 4fr 2fr 4fr",
          },
        ],
      },
    };
    const regole = {
      formCliente: [
        ...regoleSpecifiche[dest].formCliente,
        ...regoleComuni.formCliente,
      ],
      formIndirizzo: regoleComuni.formIndirizzo,
    };
    this.riordina(v, regole);
  },
  toggleCampoSDIFattura(v) {
    v.$refs.formCliente.$el.querySelector(
      '[data-fs-field^="codiceSDI"]'
    ).style.display =
      v.documento.destinazioneFattura === "SDI" ? "block" : "none";
  },
  setReadOnlyIntestazioneFattura(v) {
    const campiComuni = [
      "destinazioneFattura",
      "dataDocumento",
      "modalitaPagamento",
      "tipoDocumento",
    ];

    const campiSTS = [
      "dataPagamento",
      "rimborsoDataDocumento",
      "rimborsoNumeroDocumento",
    ];
    const campiSDI = [];

    const campi = campiComuni.concat(
      v.DEFAULTS.DESTINAZIONE === "STS" ? campiSTS : campiSDI
    );

    campi.forEach((fName) => {
      const el = document.querySelector(`#nuovo-documento [name=${fName}]`);
      el.style.background = "none";
      el.setAttribute("readonly", "readonly");
      el.setAttribute("disabled", "disabled");
      el.addEventListener("click", (e) => e.preventDefault());
    });
    const switchControl = document.getElementById("switch-control");
    if (switchControl) switchControl.classList.add("readonly");
  },
  sostituisciControlloDest() {
    document
      .querySelector('[data-fs-field^="destinazioneFattura"]')
      .append(document.querySelector("#switch-control"));
    document.querySelector('select[name="destinazioneFattura"]').style.display =
      "none";
  },
  aggiungiNotaCampiObbligatori() {
    const reqNote = document.createElement("span");
    reqNote.id = "required_note";
    reqNote.innerHTML =
      "* I campi contrassegnati da un asterisco sono obbligatori";
    document.querySelector("#dati-utente h1").appendChild(reqNote);
  },
  aggiungiClasseCampiObbligatori(requiredFields) {
    requiredFields.forEach((f) => {
      const lbl = document.querySelector(`label[for^=${f}]`);
      if (!R.isNil(lbl)) {
        lbl.classList.add("required");
      }
    });
  },
  toggleUserFormEditable(parentClass, disabled) {
    document
      .querySelectorAll("input, select")
      .forEach((el) => (el.disabled = disabled));
    document
      .querySelectorAll("select")
      .forEach(
        (el) =>
          (el.style.background = disabled
            ? "none"
            : "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAJBAMAAADN8WE8AAAAJ1BMVEUAAABHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB9YSk7AAAADXRSTlPXABaehSjPsTwKw2xUcKPlSQAAADtJREFUCNdjMGBgYGAWBAKGACCLFcwSAbIcwSyhBAY2RTBLcAMDtyCENYthJZQlw3AQyhIsF4SxOiAsAFMMCKPY35E7AAAAAElFTkSuQmCC) right center no-repeat")
      );
    document.querySelector(`#btn${parentClass}`).style.display = disabled
      ? "none"
      : "inline-block";
    if (!disabled) {
      document
        .querySelectorAll("input")
        .forEach((el) => (el.autocomplete = "off"));
    }
  },
  /**
   * Al blur del campo codice fiscale verifica la lunghezza del valore inserito
   * Se non corrisponde alle lunghezze ammesse visualizza un errore
   * Se corrisponde ad una partita IVA toglie l'obbligatorietà dal campo nome
   *
   * @param {object} v vue
   */
  convalidaCF(v) {
    const ERR_CLASS = "has-error";
    const lunghezzeAmmesse = [11, 16];

    const uForm = v.$refs.formUtente;
    const cfInput = v.$refs.formUtente.$el.querySelector(
      "input[name^=codiceFiscale]"
    );
    const cfLabel = v.$refs.formUtente.$el.querySelector(
      "label[for^=codiceFiscale]"
    );
    // eslint-disable-next-line no-unused-vars
    const nomeInput = v.$refs.formUtente.$el.querySelector("input[name^=nome]");
    const nomeLabel = v.$refs.formUtente.$el.querySelector("label[for^=nome]");

    const REGOLA = "Richiesti esattamente 11 o 13 caratteri";
    const msg = this.buildAvviso(REGOLA);

    cfInput.addEventListener("blur", function (ev) {
      const valLength = ev.target.value.length;
      if (!valLength) return;
      // SE DIVERSO DA LUNGHEZZE AMMESSE MOSTRO REGOLA
      if (!lunghezzeAmmesse.includes(valLength)) {
        cfLabel.appendChild(msg);
        cfInput.classList.add(ERR_CLASS);
      } else if (valLength === 11) {
        nomeInput.removeAttribute("required");
        nomeLabel.classList.remove("required");
      }
      // SE LUNGHEZZA == CF (16) RENDO OBBLIGATORIO IL CAMPO NOME
      else if (valLength === 16) {
        nomeInput.setAttribute("required", "required");
        nomeLabel.classList.add("required");
      }
    });
    // eslint-disable-next-line no-unused-vars
    cfInput.addEventListener("focus", function (_) {
      uForm.clearErrorMessage();
      const s = cfLabel.querySelector("span.invalid_warn");
      if (!R.isNil(s)) {
        cfLabel.removeChild(s);
      }
      cfInput.classList.remove(ERR_CLASS);
      nomeInput.classList.remove(ERR_CLASS);
    });
  },
  /**
   * Modifica il tipo iva se cambia regime fiscale dell'utente
   *
   * @param {object} opts
   * @property {object} opts.v Vue instance
   * @property {string} opts.regimeFiscale regime fiscale impostato
   */
  handleRegimeFiscaleChange({ v, regimeFiscale }) {
    const listIVAInputEl =
      v.$refs.formDefaultDoc.$el.querySelector("select[name=IVA]");

    if (!(R.isNil(regimeFiscale) || R.isEmpty(regimeFiscale))) {
      listIVAInputEl.value = ValoriIVA.getDefault(
        regimeFiscale,
        v.DEFAULTS.DESTINAZIONE
      );
      listIVAInputEl.dispatchEvent(new Event("change"));
    }
  },
  placeholder: {
    cassaPrevidenziale: [
      "Selezionare il tipo cassa",
      "Inserire l'aliquota per la cassa previdenziale: es. 2",
    ],
    ritenuta: [
      "Selezionare il tipo ritenuta",
      "Inserire l'aliquota per la ritenuta: es. 2",
    ],
  },
  /**
   * Valorizza automaticamente l'aliquota cassa previdenziale
   * se la cassa viene selezionata. Se non è selezionato
   * alcun tipo di cassa il campo viene impostato a readonly
   *
   * @param {object} opts v = vue, u = utente, impostaAliquotaDefault = true se va impostata (default), false altrimenti
   */
  handleCassaPrevidenzialeChange({ v, u, impostaAliquotaDefault = true }) {
    const aliquotaCassaPrevidenzialeEl =
      v.$refs.formDefaultDoc.$el.querySelector(
        "[name=aliquotaCassaPrevidenziale]"
      );
    const campiForm = document.querySelector(
      "[data-fs-field^=aliquotaCassaPrevidenziale]"
    );

    const cassaValorizzata =
      u.defaultDocumento &&
      !(
        R.isNil(u.defaultDocumento.tipoCassaPrevidenziale) ||
        R.isEmpty(u.defaultDocumento.tipoCassaPrevidenziale)
      );
    if (
      Log.debug(
        "handleCassaPrevidenzialeChange cassaValorizzata",
        cassaValorizzata
      )
    ) {
      aliquotaCassaPrevidenzialeEl.readOnly = false;
      if (impostaAliquotaDefault) {
        aliquotaCassaPrevidenzialeEl.value =
          CassaPrevidenziale.enti[
            u.defaultDocumento.tipoCassaPrevidenziale
          ].aliquotaDefault;
      }
      aliquotaCassaPrevidenzialeEl.placeholder =
        Forms.placeholder.cassaPrevidenziale[1];
      mostraCampo(campiForm);
    } else {
      aliquotaCassaPrevidenzialeEl.value = null;
      aliquotaCassaPrevidenzialeEl.readOnly = true;
      aliquotaCassaPrevidenzialeEl.placeholder =
        Forms.placeholder.cassaPrevidenziale[0];
      nascondiCampo(campiForm);
    }
    aliquotaCassaPrevidenzialeEl.dispatchEvent(new Event("change"));
  },
  /**
   * Valorizza automaticamente l'aliquota ritenuta
   * se la ritenuta viene selezionata. Se non è selezionato
   * alcun tipo di ritenuta il campo viene impostato a readonly
   *
   * @param {object} opts v = vue, u = utente, impostaAliquotaDefault = true se va impostata (default), false altrimenti
   */
  handleRitenutaChange({ v, u, impostaAliquotaDefault = true }) {
    const aliquotaRitenutaEl = v.$refs.formDefaultDoc.$el.querySelector(
      "[name=aliquotaRitenuta]"
    );
    const campiFormAliquotaRitenuta = document.querySelector(
      "[data-fs-field^=aliquotaRitenuta]"
    );
    const causaleRitenutaEl = v.$refs.formDefaultDoc.$el.querySelector(
      "[name=causaleRitenuta]"
    );
    const campiFormCausaleRitenuta = document.querySelector(
      "[data-fs-field^=causaleRitenuta]"
    );

    const ritenutaValorizzata =
      u.defaultDocumento &&
      !(
        R.isNil(u.defaultDocumento.tipoRitenuta) ||
        R.isEmpty(u.defaultDocumento.tipoRitenuta)
      );

    if (
      Log.debug("handleRitenutaChange ritenutaValorizzata", ritenutaValorizzata)
    ) {
      // ritenuta valorizzata: mostro i campi
      if (impostaAliquotaDefault) {
        aliquotaRitenutaEl.value =
          Ritenuta.enti[u.defaultDocumento.tipoRitenuta].aliquotaDefault;
      }
      aliquotaRitenutaEl.placeholder = Forms.placeholder.ritenuta[1];
      mostraCampo(campiFormAliquotaRitenuta);

      mostraCampo(campiFormCausaleRitenuta);
    } else {
      // ritenuta null: svuoto e nascondo i campi
      aliquotaRitenutaEl.value = null;
      aliquotaRitenutaEl.placeholder = Forms.placeholder.ritenuta[0];
      nascondiCampo(campiFormAliquotaRitenuta);

      causaleRitenutaEl.value = null;
      causaleRitenutaEl
        .querySelectorAll("option")
        .forEach((el) => el.removeAttribute("selected"));
      Forms.selezionaPlaceholder(causaleRitenutaEl);
      nascondiCampo(campiFormCausaleRitenuta);
    }
    aliquotaRitenutaEl.dispatchEvent(new Event("change"));
    causaleRitenutaEl.dispatchEvent(new Event("change"));
  },

  mostraNascondiCausaleRitenuta(tipoRitenuta) {
    const causaleRitenutaEl = document.querySelector(
      '[data-fs-field^="causaleRitenuta"]'
    );
    if (
      R.isNil(tipoRitenuta) ||
      R.isEmpty(tipoRitenuta) ||
      tipoRitenuta === "NESSUNA"
    ) {
      causaleRitenutaEl
        .querySelectorAll("option")
        .forEach((el) => el.removeAttribute("selected"));
      causaleRitenutaEl.style.display = "none";
    } else {
      causaleRitenutaEl.style.display = "block";
    }
  },
};

function nascondiCampo(campo) {
  campo.style.display = "none";
}
function mostraCampo(campo) {
  campo.style.display = "block";
}

export default Forms;
