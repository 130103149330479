// rif: https://en.wikipedia.org/wiki/Mask_(computing)

// per rigenerare le bitmask vedere appunti/invianti-spese.txt

const Invianti = Object.freeze({
  Farmacia: 10447,
  "Struttura accreditata pubblica o privata o militare": 16131,
  "Medico o Odontoiatra": 13056,
  "Struttura Autorizzata": 16128,
  Parafarmacia: 10444,
  "Farmacia ANMIG": 10444,
  Ottico: 8256,
  "Professionista sanitario": 8224,
  Infermiere: 32,
  Veterinario: 8216,
  "Sogg. Di cui art. 70": 8200,
  isStruttura(tipoSoggetto) {
    return [
      "Farmacia",
      "Struttura accreditata pubblica o privata o militare",
      "Struttura Autorizzata",
      "Parafarmacia",
      "Farmacia ANMIG",
      "Ottico",
    ].includes(tipoSoggetto);
  },
});

export default Invianti;
