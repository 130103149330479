const StatoInvio = Object.freeze({
  LOCALE: "LOCAL",
  DA_INVIARE: "DA_INVIARE",
  SDI_TIMEOUT: "SDI_TIMEOUT",
  SDI_FALLITO: "SDI_FALLITO",
  STS_UNAVAILABLE: "STS_UNAVAILABLE",
  ATTESA_ESITO: "ATTESA_ESITO",
  ESITO_OK: "ESITO_OK",
  ESITO_WARN: "ESITO_WARN",
  ESITO_ERR: "ESITO_ERR",

  isModificabile({ statoInvio }) {
    return this._modificabili().includes(statoInvio);
  },
  isStampabile({ statoInvio }) {
    return !(
      this.isModificabile({ statoInvio }) || statoInvio === this.ATTESA_ESITO
    );
  },
  /**
   * Verifica se la fattura è in uno stato che prevede la presenza di messaggi
   *
   * @param {object} fattura
   * @returns {boolean}
   */
  hasMessaggi({ statoInvio }) {
    return [this.ESITO_OK, this.ESITO_WARN, this.ESITO_ERR].includes(
      statoInvio
    );
  },
  isInviato({ statoInvio }) {
    return [this.ESITO_OK, this.ESITO_WARN, this.ATTESA_ESITO].includes(
      statoInvio
    );
  },

  _modificabili() {
    return [this.LOCALE, this.DA_INVIARE, this.ESITO_ERR, this.STS_UNAVAILABLE];
  },
});

export default StatoInvio;

