const ErroriReset = Object.freeze({
  A0: "Errore captcha - provare di nuovo, se l'errore persiste contattare l'assistenza",
  A1: "L'utente non risulta registrato, per reimpostare le credenziali è necessario registrarsi al sistema",
  A2: "Non risulta una richiesta di reset a nome dell'utente indicato",
  B1: "Token errato - contattare l'assistenza",
  B2: "E' necessario reinviare la richiesta di reset",
  /**
   * Resituisce il messaggio di errore
   * relativo all'errore ricevuto se presente,
   * altrimenti il messaggio ricevuto
   *
   * @param {string} msg
   * @returns error message
   */
  getMsg: function (msg) {
    const splitMsg = msg.split(" - ");
    return splitMsg[1] === undefined ? msg : this[splitMsg[1]];
  },
});

export default ErroriReset;
