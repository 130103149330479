import { R } from "mida4-web-app-utils";

const MUL = {
  asc: 1,
  desc: -1,
};

export default {
  mounted() {
    this.setElencoFatture();
  },
  computed: {
    isDashboard() {
      return !R.isNil(this.dashboard);
    },
  },
  methods: {
    resetOrder() {
      this.cols.headers = this.cols.headers.map((h) =>
        h.orderBy ? { ...h, orderBy: { ...h.orderBy, dir: null } } : h
      );
      this.setElencoFatture();
    },
    updateColHeaders({ field, dir }) {
      this.cols.headers = [...this.cols.headers].map((h) =>
        h.orderBy && h.orderBy.field === field
          ? { ...h, orderBy: { ...h.orderBy, dir } }
          : h
      );
    },
    compareItems([a, b], field, dir, fnGet) {
      return (
        (fnGet(a, field) < fnGet(b, field)
          ? -1
          : fnGet(a, field) > fnGet(b, field)
          ? 1
          : 0) * MUL[dir]
      );
    },
    orderBy({ field, dir }) {
      if (this.dashboard) return;

      dir = dir === "asc" ? "desc" : "asc";
      this.elenco = [...this.elenco].sort((a, b) =>
        this.compareItems([a, b], field, dir, this.getSortable)
      );
      // this.$log.debug('sort', this.elenco)
      this.updateColHeaders({ field, dir });
    },
    getSortable(o, field) {
      return field === "cliente" ? o[field]["cognomeDenom"] : o[field];
    },
  },
};
