import { arrotonda } from "../../../mida4-web-app-utils";
import { isRigaAutogenerata } from "../../doc";
const CassaPrevidenziale = Object.freeze({
  enti: {
    TC01: {
      descrizione:
        "Cassa nazionale previdenza e assistenza avvocati e procuratori legali",
      abbreviazione: "",
      aliquotaDefault: 4,
      applicatoDefault: true,
      isSTS: false,
    },
    TC02: {
      descrizione: "Cassa previdenza dottori commercialisti",
      abbreviazione: "",
      aliquotaDefault: 4,
      applicatoDefault: true,
      isSTS: false,
    },
    TC03: {
      descrizione: "Cassa previdenza e assistenza geometri",
      abbreviazione: "",
      aliquotaDefault: 5,
      applicatoDefault: true,
      isSTS: false,
    },
    TC04: {
      descrizione:
        "Cassa nazionale previdenza e assistenza ingegneri e architetti liberi professionisti",
      abbreviazione: "",
      aliquotaDefault: 4,
      applicatoDefault: true,
      isSTS: false,
    },
    TC05: {
      descrizione: "Cassa nazionale del notariato",
      abbreviazione: "",
      aliquotaDefault: null,
      applicatoDefault: true,
      isSTS: false,
    },
    TC06: {
      descrizione:
        "Cassa nazionale previdenza e assistenza ragionieri e periti commerciali",
      abbreviazione: "",
      aliquotaDefault: null,
      applicatoDefault: true,
      isSTS: false,
    },
    TC07: {
      descrizione:
        "Ente nazionale assistenza agenti e rappresentanti di commercio (ENASARCO)",
      abbreviazione: "ENASARCO",
      aliquotaDefault: null,
      applicatoDefault: true,
      isSTS: false,
    },
    TC08: {
      descrizione:
        "Ente nazionale previdenza e assistenza consulenti del lavoro (ENPACL)",
      abbreviazione: "ENPACL",
      aliquotaDefault: null,
      applicatoDefault: true,
      isSTS: false,
    },
    TC09: {
      descrizione: "Ente nazionale previdenza e assistenza medici (ENPAM)",
      abbreviazione: "ENPAM",
      aliquotaDefault: 2,
      applicatoDefault: false,
      isSTS: true,
    },
    TC10: {
      descrizione: "Ente nazionale previdenza e assistenza farmacisti (ENPAF)",
      abbreviazione: "ENPAF",
      aliquotaDefault: null,
      applicatoDefault: true,
      isSTS: true,
    },
    TC11: {
      descrizione: "Ente nazionale previdenza e assistenza veterinari (ENPAV)",
      abbreviazione: "ENPAV",
      aliquotaDefault: 2,
      applicatoDefault: true,
      isSTS: true,
    },
    TC12: {
      descrizione:
        "Ente nazionale previdenza e assistenza impiegati dell'agricoltura (ENPAIA)",
      abbreviazione: "ENPAIA",
      aliquotaDefault: null,
      applicatoDefault: false,
      isSTS: false,
    },
    TC13: {
      descrizione:
        "Fondo previdenza impiegati imprese di spedizione e agenzie marittime",
      abbreviazione: "",
      aliquotaDefault: null,
      applicatoDefault: false,
      isSTS: false,
    },
    TC14: {
      descrizione: "Istituto nazionale previdenza giornalisti italiani (INPGI)",
      abbreviazione: "INPGI",
      aliquotaDefault: null,
      applicatoDefault: true,
      isSTS: false,
    },
    TC15: {
      descrizione:
        "Opera nazionale assistenza orfani sanitari italiani (ONAOSI)",
      abbreviazione: "ONAOSI",
      aliquotaDefault: null,
      applicatoDefault: false,
      isSTS: false,
    },
    TC16: {
      descrizione:
        "Cassa autonoma assistenza integrativa giornalisti italiani (CASAGIT)",
      abbreviazione: "CASAGIT",
      aliquotaDefault: null,
      applicatoDefault: false,
      isSTS: false,
    },
    TC17: {
      descrizione:
        "Ente previdenza periti industriali e periti industriali laureati (EPPI)",
      abbreviazione: "EPPI",
      aliquotaDefault: null,
      applicatoDefault: true,
      isSTS: false,
    },
    TC18: {
      descrizione: "Ente previdenza e assistenza pluricategoriale (EPAP)",
      abbreviazione: "EPAP",
      aliquotaDefault: null,
      applicatoDefault: false,
      isSTS: false,
    },
    TC19: {
      descrizione: "Ente nazionale previdenza e assistenza biologi (ENPAB)",
      abbreviazione: "ENPAB",
      aliquotaDefault: 2,
      applicatoDefault: true,
      isSTS: true,
    },
    TC20: {
      descrizione:
        "Ente nazionale previdenza e assistenza professione infermieristica (ENPAPI)",
      abbreviazione: "ENPAPI",
      aliquotaDefault: 4,
      applicatoDefault: true,
      isSTS: true,
    },
    TC21: {
      descrizione: "Ente nazionale previdenza e assistenza psicologi (ENPAP)",
      abbreviazione: "ENPAP",
      aliquotaDefault: 2,
      applicatoDefault: true,
      isSTS: true,
    },
    TC22: {
      descrizione: "INPS",
      abbreviazione: "INPS",
      aliquotaDefault: 4,
      applicatoDefault: true,
      isSTS: true,
    },
  },
  isSoggettoARitenuta(ente) {
    return ente === "TC22";
  },
  getDefaultDocEnum(dest, opts) {
    const valPropName = opts?.valPropName ?? "value";
    const lblPropName = opts?.lblPropName ?? "label";
    return Object.entries(this.enti)
      .filter(([_, v]) => (dest === "STS" ? v.isSTS : true))
      .map(([k, v]) => ({ [valPropName]: k, [lblPropName]: v.descrizione }));
  },
  getDefaultDocEnumWithCodes(dest, opts) {
    return this.getDefaultDocEnum(dest, opts).map((e) => ({
      ...e,
      label: e.label + " - " + e.value,
    }));
  },
  getDescrizioneRiga(ente, aliquota) {
    if (ente === undefined || aliquota === undefined) {
      throw new Error("campi richiesti: ente, aliquota");
    }
    return `${this.enti[ente].descrizione} ${aliquota}%`;
  },
  espandiDescrizioneCassaPrevidenziale(descrizione) {
    const ENTE = 0,
      ALIQUOTA = 1;
    const datiCassa = descrizione.split("-");
    return {
      descrizione: this.getDescrizioneRiga(
        datiCassa[ENTE],
        datiCassa[ALIQUOTA]
      ),
      ente: datiCassa[ENTE],
      aliquota: datiCassa[ALIQUOTA],
    };
  },
  compattaDescrizioneCassaPrevidenziale(ente, aliquota) {
    return `${ente}-${aliquota}`;
  },
  getRigheFattura(dest, righe, tipoCassa, aliquota) {
    return Object.entries(this._raggruppaPerIVA(righe, dest))
      .reverse()
      .map(([iva, imponibile]) =>
        this._generaRiga({
          descrizione: this.getDescrizioneRiga(tipoCassa, aliquota),
          importo: arrotonda((imponibile * aliquota) / 100),
          tipoSpesa: this._getTipoSpesa(dest, righe),
          ritenuta: this._getRitenuta(dest, tipoCassa, righe),
          ente: tipoCassa,
          aliquota: aliquota,
          iva,
        })
      );
  },
  _getRigheConRivalsa(righe, dest = "STS") {
    const applicata = (r) =>
      r.cassaPrevidenziale === true &&
      (dest !== "STS" || r.importoUnitario > 0);
    return righe.filter(applicata);
  },
  _getTipoSpesa(dest, righe) {
    if (dest === "STS") {
      return this._getRigheConRivalsa(righe)[0].tipoSpesa;
    }
  },
  _getRitenuta(dest, tipoCassa, righe) {
    if (dest === "SDI") {
      const _righe = righe.filter((r) => !isRigaAutogenerata(r._id));
      return (
        this.isSoggettoARitenuta(tipoCassa) &&
        _righe.some((r) => r.ritenuta === true)
      );
    }
  },
  _raggruppaPerIVA(righe, dest = "STS") {
    return this._getRigheConRivalsa(righe, dest).reduce(
      (_imponibili, r) => ({
        ..._imponibili,
        [r.IVA]: arrotonda((_imponibili[r.IVA] || 0.0) + this._imponibile(r)),
      }),
      {}
    );
  },
  _imponibile(riga) {
    return arrotonda(
      parseFloat(riga.importoUnitario || 0) * parseFloat(riga.quantita || 0)
    );
  },
  _generaRiga({
    descrizione,
    importo,
    tipoSpesa,
    ritenuta,
    iva,
    ente,
    aliquota,
  }) {
    return {
      _id: `cassaPrevidenziale_${iva}`,
      editing: false,
      tipoRiga: "CASSA_PREVIDENZIALE",
      ente: ente,
      aliquota: aliquota,
      descrizione: descrizione,
      tipoSpesa: tipoSpesa,
      ritenuta: ritenuta,
      importoUnitario: importo,
      quantita: "1",
      IVA: iva,
    };
  },
});

export default CassaPrevidenziale;
