import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import HomeSpSa from "../views/HomeSpSa";
import { Log } from "mida4-web-app-utils";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeSpSa,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reset/:token",
    name: "Reset",
    component: () =>
      import(/* webpackChunkName: "reset" */ "../views/ResetPasswordSpSa"),
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/modifica-password",
    name: "Modifica Password",
    component: () =>
      import(
        /* webpackChunkName: "modifica-password" */ "../views/RichiediResetSpSa"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/modifica-credenziali-sts",
    name: "Modifica Credenziali STS",
    component: () =>
      import(
        /* webpackChunkName: "modifica-credenziali-sts" */ "../views/ResetCredenzialiServizioSpSa"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/registrazione",
    name: "Registrazione",
    component: () =>
      import(
        /* webpackChunkName: "registrazione" */ "../views/RegistrazioneSpSa"
      ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginViewSpSa"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/dati-utente",
    name: "Dati Utente",
    component: () =>
      import(/* webpackChunkName: "dati-utente" */ "../views/DatiUtenteSpSa"),
    meta: {
      requiresAuth: true,
      section: "Dati Utente",
    },
  },
  {
    path: "/fattura/:idFattura?",
    name: "Fattura",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "fattura" */ "../views/DettaglioDocumentoSpSa"
      ),
    meta: {
      requiresAuth: true,
      section: "Fattura",
    },
  },
  {
    path: "/fatture-emesse",
    name: "Fatture Emesse",
    component: () =>
      import(/* webpackChunkName: "documenti" */ "../views/DocumentiSpSa"),
    meta: {
      requiresAuth: true,
      section: "Fatture Emesse",
    },
  },
  {
    path: "/clienti/:idCliente?",
    name: "Clienti",
    props: true,
    component: () =>
      import(/* webpackChunkName: "rinnovo" */ "../views/ClientiSpSa"),
    meta: {
      requiresAuth: true,
      section: "Clienti",
    },
  },
  {
    path: "/rinnovo",
    name: "Rinnovo",
    component: () =>
      import(/* webpackChunkName: "rinnovo" */ "../views/RinnovoSpSa"),
    meta: {
      requiresAuth: true,
      section: "Rinnovo",
    },
  },
];

const router = new VueRouter({
  // base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // redirect su route errata
  if (to.matched.length === 0) {
    next("/");
  }

  const toPage = to.name || "Home";
  const toParams = to.params.idFattura || "";
  let doResetDocAttivo = false;

  Log.analytics.logEvent("page_view", {
    type: "internal",
    page_location: `${toPage}/${toParams}`,
    page_title: toPage,
  });

  if (to.meta.section === "Fattura") {
    // Log.debug(
    //   "from.meta.section === 'Fattura'",
    //   from.meta.section === 'Fattura'
    // )
    // Log.debug(
    //   'store.state.fatture.isNuovoDocAbilitato',
    //   store.state.fatture.isNuovoDocAbilitato
    // )
    if (
      from.meta.section === "Fattura" &&
      store.state.fatture.isNuovoDocAbilitato
    ) {
      doResetDocAttivo = true;
    }
  } else {
    doResetDocAttivo = true;
  }
  if (doResetDocAttivo) {
    Log.debug("resetDocAttivo");
    store.dispatch("fatture/resetDocAttivo");
  }

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !store.state.auth.isUserLoggedIn) {
    store.dispatch("setActiveSection", null);
    next("/login");
  } else if (
    to.meta.section === "Rinnovo" &&
    store.getters["user/scaduto"] === false
  ) {
    // se l'utente digita rinnovo nella barra degli indirizzi
    // e l'account è attivo, viene reindirizzato alla home
    next("/");
  } else {
    store.dispatch(
      "setActiveSection",
      store.state.auth.isUserLoggedIn ? to.meta.section : null
    );
    next();
  }
});

export default router;
