import isNil from "ramda/es/isNil";
import isEmpty from "ramda/es/isEmpty";
import mergeLeft from "ramda/es/mergeLeft";
import toUpper from "ramda/es/toUpper";
import clone from "ramda/es/clone";
import equals from "ramda/es/equals";

import Log from "./log";
import Forms from "./forms";
import DateDefaults from "./date-defaults";

import REGEXES from "./regexes";
import Pulsanti from "./pulsanti";

import {
  kebabCase,
  camelCase,
  toTitleCase,
  allCaps,
  insert,
  mergeArrayOfObjects,
  sortArrayOfObjectsBy,
  downloadFile,
  arrotonda,
  uniq,
  keysOfUnsetValues,
  emptyPropertiesDeleted,
} from "./functions";

export const R = { isNil, isEmpty, mergeLeft, toUpper, clone, equals };
export {
  Log,
  Forms,
  DateDefaults,
  REGEXES,
  Pulsanti,
  insert,
  uniq,
  kebabCase,
  camelCase,
  toTitleCase,
  allCaps,
  mergeArrayOfObjects,
  sortArrayOfObjectsBy,
  downloadFile,
  arrotonda,
  keysOfUnsetValues,
  emptyPropertiesDeleted,
};
