const CausaleRitenuta = Object.freeze({
  A: "A - Prestazioni di lavoro autonomo",
  B: "B - Autore o inventore opere di ingegno (730)",
  C: "C - Utili associazione partecipazione solo lavoro (730)",
  D: "D - Utili spettanti ai soci promotori (730)",
  E: "E - Levata protesti cambiari segretari (730)",
  F: "F - Giudici onorari di pace e ai vice procuratori onorari (730)",
  G: "G - Cessazione attività sportiva",
  H: "H - Indennità cessazione rapporti agenzia",
  I: "I - Indennità cessazione funzioni notarili",
  J: "J - Raccoglitori occasionali di tartufi",
  K: "K - Servizio civile (art.16 S.lgs. 40 6/3/2017",
  L: "L - Redd. derivanti opere ingegno titolo gratuito (730)",
  L1: "L1 - Redd. derivanti opere ingegno acquistati (730)",
  M: "M - Prestazioni lavoro autonomo non eserc. abitualmente (730)",
  M1: "M1 - Redditi derivanti dall'assunzione di obblighi fare, non fare o permett. (730)",
  M2: "M2 - Prestazioni di lavoro autonomo non esercitate abitualmente per le quali sussiste l’obbligo di iscrizione alla Gestione Separata ENPAPI (730)",
  N: "N - Inden di trasf, rimb. forf, premi e compensi (730)",
  O: "O - Prest. di lav. autonomo non esercitate abitual. no gest. separata (730)",
  O1: "O1 - Redditi derivanti dall'assunzione di obblighi di fare no gest. separata (730)",
  P: "P - Compensi sogg. non residenti privi stabile org.",
  Q: "Q - Agente o rappresentante monomandatario",
  R: "R - Agente o rappresentante plurimandatario",
  S: "S - Commissionario",
  T: "T - Mediatore",
  U: "U - Procacciatore d'affari",
  V: "V - Incaricati per le vendite a domicilio e porta a porta",
  V1: "V1 - Redditi derivanti da attività commerciali non esercitate abitualmente (730)",
  V2: "V2 - Redditi derivanti dalle prestazioni non esercitate abitualmente rese dagli incaricati alla vendita diretta a domicilio",
  W: "W - Contratti d'appalto",
  X: "X - Canoni corrisposti 2004 a società/enti residenti",
  Y: "Y - Canoni corr. dal 1/1/2005 a società/enti residenti",
  ZO: "ZO - Titolo diverso precedenti",
  getEnum(opts) {
    const valPropName = opts?.valPropName ?? "value";
    const lblPropName = opts?.lblPropName ?? "label";
    // eslint-disable-next-line no-unused-vars
    return Object.entries(this)
      .filter(([_, v]) => typeof v === "string")
      .map(([k, v]) => ({
        [valPropName]: k,
        [lblPropName]: v,
      }));
  },
});

export default CausaleRitenuta;
