<template>
  <div v-frag>
    <div v-if="credenzialiAssenti">
      <p id="msg-vai-reset-creds">{{ msgVaiReset }}</p>
      <router-link
        id="btn-vai-reset-creds"
        :to="{ name: 'Modifica Credenziali STS' }"
        tag="button"
        >Clicca qui per inserire le credenziali</router-link
      >
    </div>
    <div v-if="scaduto">
      <span id="msg-scaduto">Il servizio di invio è scaduto !</span>
      <span id="rinnova-abbonamento-scaduto"
        ><router-link to="/rinnovo" tag="button"
          >Rinnova l'abbonamento</router-link
        ></span
      >
    </div>
    <div v-else>
      ti ricordiamo che il servizio di invio è attivo fino al {{ scadenza }}
    </div>
  </div>
</template>
<script>
const NAME = "ReminderStatoAccountSpSa";

import { format } from "date-fns";
import { mapGetters } from "vuex";

import { DateDefaults } from "mida4-web-app-utils";

export default {
  name: NAME,
  computed: {
    ...mapGetters({
      credenzialiAssenti: "auth/credenzialiAssenti",
      dataScadenza: "user/dataScadenza",
      scaduto: "user/scaduto",
    }),
    scadenza() {
      return format(this.dataScadenza, DateDefaults.formats.ONLY_DATE);
    },
    msgVaiReset() {
      return this.credenzialiAssenti
        ? "E' ora necessario inserire le credenziali del Servizio Tessera Sanitaria, le quali saranno criptate e salvate all'interno del sistema."
        : "Per poter inviare le fatture al servizio Tessera Sanitaria è necessario inserire le credenziali del servizio.";
    },
  },
};
</script>
<style
  src="@/styles/custom/components/home/_reminder_stato_account.scss"
  lang="scss"
></style>

