const DefaultsSpSa = Object.freeze({
  IS_PROD: process.env.NODE_ENV === "production",

  DESTINAZIONE: "STS",

  RITENTA_INVIO: false,
  MAX_TENTATIVI_INVIO: 1,

  MSG_BOLLO_STS:
    "Si ricorda che è necessario apporre una marca da bollo da 2,00€",

  IVA: "N4",
});

export { DefaultsSpSa };

