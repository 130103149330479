<template>
  <a id="toggle-riquadro-utente">
    <font-awesome-layers v-if="scaduto" class="fa-fw" id="user-icon">
      <font-awesome-icon icon="user" />
      <font-awesome-icon
        icon="exclamation"
        transform="shrink-6 up-6 right-8"
        :style="{ color: 'red' }"
      />
    </font-awesome-layers>
    <font-awesome-icon v-else icon="user" id="user-icon" />
  </a>
</template>

<script>
import { mapGetters } from "vuex";

const NAME = "MenuIconaUtenteSpSa";

export default {
  name: NAME,
  computed: {
    ...mapGetters({
      scaduto: "user/scaduto",
    }),
  },
};
</script>
<style src="@/styles/custom/components/_layout/_menu_icona_utente.scss" lang="scss"></style>