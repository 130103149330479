<template>
  <apexchart
    width="800"
    height="350"
    type="line"
    :options="options"
    :series="series"
  />
</template>

<script>
import { eachMonthOfInterval, subMonths, format } from "date-fns";
import { DateDefaults } from "mida4-web-app-utils";
import { mapGetters } from "vuex";
import it from "date-fns/locale/it";

const NAME = "StatsSpSa";

const POS_FATTURE = 0;
const POS_IMPORTO = 1;

export default {
  name: NAME,
  computed: {
    ...mapGetters({
      stats: "stats/statistiche",
    }),
    series() {
      return [
        {
          name: "Ricavi",
          type: "column",
          data: this.statsEntrate,
        },
        {
          name: "Fatture",
          type: "line",
          data: this.statsSTS,
        },
      ];
    },
    options() {
      return {
        chart: {
          type: "line",
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [1, 4],
        },
        title: {
          text: "Andamento degli ultimi 13 mesi",
          align: "center",
        },
        xaxis: {
          categories: this.mesiAsseX,
        },
        yaxis: [
          {
            seriesNAme: "Ricavi",
            tickAmount: 1,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#3573D1",
            },
            labels: {
              style: {
                colors: "#3573D1",
              },
              formatter: function (val) {
                return Intl.NumberFormat("it-IT", {
                  style: "currency",
                  currency: "EUR",
                }).format(val);
              },
            },
            title: {
              text: "Ricavi",
              style: {
                color: "#3573D1",
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          {
            seriesName: "Fatture",
            tickAmount: 1,
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#00E396",
            },
            labels: {
              style: {
                colors: "#00E396",
              },
              formatter: function (val) {
                return val.toFixed(0);
              },
            },
            title: {
              text: "Fatture",
              style: {
                color: "#00E396",
              },
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60,
          },
          x: {
            show: false,
          },
        },
        legend: {
          horizontalAlign: "left",
          offsetX: 40,
        },
      };
    },
    mesiAsseX() {
      return eachMonthOfInterval({
        start: subMonths(new Date(), 12),
        end: new Date(),
      }).map((d) =>
        format(d, DateDefaults.formats.COMPAT_MESE, { locale: it })
      );
    },
    // statsSDI() {
    //   return this.getStatsFatture(DestinazioneFattura.SDI)
    // },
    statsSTS() {
      return this.getStatsFatture();
    },
    statsEntrate() {
      return Object.values(this.stats.stats)
        .reverse()
        .map((m) => m[POS_IMPORTO]);
    },
  },
  methods: {
    getStatsFatture() {
      return Object.values(this.stats.stats)
        .reverse()
        .map((m) => parseInt(m[POS_FATTURE]));
    },
  },
};
</script>

<style src="@/styles/custom/components/home/_stats.scss" lang="scss"></style>