import { Log, R, sortArrayOfObjectsBy } from "mida4-web-app-utils";
import { StatusCodes } from "http-status-codes";

const bySurnameAsc = sortArrayOfObjectsBy("cognomeDenom");

const clientiModule = {
  namespaced: true,

  state: () => ({
    elenco: [],
    attivo: null,
  }),

  mutations: {
    setElenco(state, clienti) {
      state.elenco = clienti;
    },

    setAttivo(state, cli) {
      state.attivo = cli;
    },

    resetDocAttivo(state) {
      state.attivo = null;
    },

    aggiungi(state, cli) {
      state.elenco.unshift({ ...cli });
    },

    aggiorna(state, cli) {
      state.elenco = [
        ...state.elenco.map((it) =>
          it.codiceFiscale === cli.codiceFiscale ? { ...cli } : it
        ),
      ];
    },

    resetOrder(state) {
      state.elenco = bySurnameAsc(state.elenco);
    },

    setRequired(state, required) {
      state.required = required;
    },
  },

  actions: {
    async recuperaElenco({ commit }) {
      try {
        const res = await this._vm.$api.elencoClienti();
        if (res.status !== StatusCodes.OK) {
          throw new Error("Errore server durante l'aggiornamento SDI");
        }
        const clienti = await res.json();
        commit(
          "setElenco",
          clienti.sort((a, b) => (a.cognomeDenom > b.cognomeDenom ? 1 : -1))
        );
      } catch (error) {
        Log.error(error);
      }
    },

    async insertCliente({ commit, state, dispatch }, cli) {
      if (R.isEmpty(state.elenco)) {
        await dispatch("recuperaElenco");
      }
      let trovato = state.elenco.find((it) => it.id === cli.id);
      if (R.isNil(trovato)) {
        Log.debug("clienti - aggiungo", cli);
        commit("aggiungi", cli);
      } else {
        // TODO: if cambiato
        Log.debug("clienti - aggiorno", cli);
        commit("aggiorna", cli);
      }
    },

    async deleteCliente({ commit, state }, id) {
      if (R.isNil(id) || R.isEmpty(id)) {
        throw new Error(
          "Impossibile eliminare il cliente, id non specificato."
        );
      }
      const res = await this._vm.$api.eliminaCliente(id);
      if (res.status !== StatusCodes.OK) {
        throw new Error((await res.json()).message);
      }
      const clienti = state.elenco.filter((c) => c.id !== id);
      commit("setElenco", clienti);
    },

    async saveCliente({ dispatch }, cli) {
      const res = await this._vm.$api.salvaCliente(cli);
      if (res.status !== StatusCodes.OK) {
        throw new Error((await res.json()).message);
      }
      dispatch("insertCliente", { ...cli, id: parseInt(await res.text()) });
    },

    setAttivo({ commit }, cli) {
      commit("setAttivo", cli);
    },

    resetAttivo({ commit }) {
      commit("resetAttivo");
    },

    orderBy({ commit }, { field, dir }) {
      commit("orderBy", { field, dir });
    },

    resetOrder({ commit }) {
      commit("resetOrder");
    },

    setRequired({ commit }, required) {
      commit("setRequired", required);
    },
  },

  getters: {
    getClienti: (state) => state.elenco,
    getAttivo: (state) => state.attivo,
  },
};
export default clientiModule;

