<template>
  <section id="riquadro-assistenza">
    <span class="back">
      <font-awesome-icon icon="arrow-left" />
      Torna Indietro
    </span>
    <h5>Aiuto Online</h5>
    <p>
      Hai controllato se le informazioni che cerchi si trovano nel manuale
      utente ?
    </p>
    <a :href="urlManuale" target="_blank" id="manuale-utente"
      >Clicca qui per il Manuale Utente</a
    >
    <p>
      Se hai necessità di contattare uno dei nostri operatori, puoi acquistare
      una richiesta di assistenza
      <template v-if="registrato"
        >cliccando sul pulsante qui sotto.
        <button @click.stop="apriAssistenza()">RICHIESTA ASSISTENZA</button>
      </template>
      <template v-else
        >inviando una mail all'indirizzo
        <a href="mailto:commerciale@mida4.it" :subject="mailSubject"
          >commerciale@mida4.it</a
        >
      </template>
    </p>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

// se utente pre pagamento -> mail a commerciale
// se utente attivo -> assistenza mida

export default {
  data() {
    return {
      urlManuale: "http://s.mida4.it/ManualeSpeseSanitarieCloud",
    };
  },

  methods: {
    apriAssistenza() {
      const url = `https://assistenza.mida4.it?u=${this.user.codiceClienteMida4}`;
      window.open(url, "_blank");
    },
  },

  computed: {
    ...mapGetters({
      inProva: "user/inProva",
      registrato: "user/registrato",
      user: "user/getData",
    }),

    mailSubject() {
      return `Spese Sanitarie Web|${this.user.codiceFiscale} ${
        this.user.cognomeDenom
      }${this.user.nome ? " " + this.user.nome : ""}|Richiesta di assistenza`;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/components/buttons";
#riquadro-assistenza {
  z-index: 12;
  min-width: calc(28 * var(--u));
  position: absolute;
  border: 1px solid #0e51aa66; //#e5ebf3;
  background: #fafcfe;
  border-radius: calc(0.25 * var(--u));
  padding: calc(1 * var(--u)) calc(2 * var(--u));
  right: -1rem;
  top: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;

  h5 {
    display: block;
    width: 100%;
    color: #0e51aa;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 1rem 0 0 0;
    text-align: center;
    border: 3px solid #0e51aa;
    border-left: none;
    border-right: none;
    padding: 1rem;
    &.form-header {
      font-weight: normal;
      width: 97%;
      text-align: center;
      font-size: 1.2rem;
      margin: 0;
    }
    svg {
      float: left;
    }
  }
  p {
    margin: calc(1.5 * var(--u)) 0;
  }

  button,
  .button {
    @extend .full-button;
    margin-top: calc(2 * var(--u)) !important;
    font-size: var(--u) !important;

    //height: 2rem;
    // padding: 0.5rem 1rem;
    width: 97%;
    // border-radius: 0.4rem;
  }
  form {
    width: 97%;
    input,
    textarea {
      font-size: 0.8rem;
      background: white;
      box-shadow: 0 0 3px #0e51aa33 inset;
    }
    textarea {
      height: 25vh;
    }
    button {
      margin-top: 1rem;
      width: 100%;
    }
  }
}
</style>

