import StatusCodes from "http-status-codes";

import CodiciErroreInvio from "../entities/enums/codici-errore-invio";
import StatoInvio from "../entities/enums/stato-invio";
import { Log, R } from "../../mida4-web-app-utils";
import { convertiUsoClient } from "../doc";

/* EVENTI

error.platform.aggiungiFattura
error.platform.aggiornaFattura
error.platform.inviaFattura

done.invoke.aggiungiFattura
done.invoke.aggiornaFattura
done.invoke.inviaFattura

*/

// ERR AGGIUNGI O AGGIORNA
const gestisciErrMida = async function (op, errorDialog) {
  if (op.context.response.status === StatusCodes.FORBIDDEN) {
    Log.warn("gestisciEsito: errore aggiungi fattura - Utente non attivo");
    errorDialog({
      title: "Utente non attivo",
      message:
        "Per poter inoltrare la fattura è necessario procedere al rinnovo del servizio",
    });
  } else {
    Log.warn("gestisciEsito: errore aggiungi fattura - notifico all'utente");
    errorDialog({
      title: "Errore aggiungi fattura",
      message: (await op.context.response.clone().json()).message,
    });
  }
};
// TODO: verifica che funzioni
const possibleSDITimeout = (context) =>
  !R.isNil(context.fattura) &&
  context.fattura.statoInvio === StatoInvio.SDI_TIMEOUT; // .idTrasmissione.toLowerCase().substring(0, 4) === 'file'

// ERR INVIA
const gestisciErrServMin = async function (
  op,
  alertDialog,
  errorDialog,
  generaElencoMessaggi
) {
  let dialogMsg;
  let opts = { title: "Errore invio documento" };

  //eslint-disable-next-line no-debugger
  // debugger
  if (R.isEmpty(op.context.messaggi) || R.isNil(op.context.messaggi)) {
    if (possibleSDITimeout(op.context)) {
      //SIAMO QUI - GESTIONE TIMEOUT SDI

      // opts.message = `Impossibile contattare l'infrastruttura Mida4`
      alertDialog({
        title: "Timeout SDI",
        html: "Il Servizio Di Interscambio non ha risposto in tempo all'invio della fattura, che potrebbe comunque essere stata presa in carico.<br><br>Lo stato del documento verrà aggiornato automaticamente in base a quanto comunicato dal Servizio Di Interscambio dell'AdE.",
      });
      return;
    } else {
      const resString = await op.context.response.clone().text();
      try {
        // eslint-disable-next-line no-debugger
        // debugger
        dialogMsg = JSON.parse(resString).message;
        Log.warn("gestisciEsito: errore invio fattura (JSON)", dialogMsg);
      } catch (error) {
        dialogMsg = resString;
        Log.warn("gestisciEsito: errore invio fattura (TEXT)", dialogMsg);
      }
      let reportedError = _getInviaErrorMessage(dialogMsg);
      reportedError = R.isNil(reportedError) ? "" : `:\n${reportedError}`;
      opts.message = `Si è verificato un errore nell'invio della fattura${reportedError}`;
    }
  } else {
    let errorTxt = op.context.messaggi;
    errorTxt = _trasformaErroreSeCodiciErrati(errorTxt);
    opts.html = generaElencoMessaggi({ tipo: "errore", messaggi: errorTxt });
  }
  errorDialog(opts);
};

const _trasformaErroreSeCodiciErrati = function (reportedError) {
  if (
    RegExp(
      "cvc-simple-type(.*)codiceregione value(.*)is not a valid instance of type"
    ).test(reportedError.toLowerCase())
  ) {
    return "Formato codice regione non valido: richiesti 3 caratteri alfanumerici";
  } else if (
    RegExp(
      "cvc-simple-type(.*)codiceasl value(.*)is not a valid instance of type"
    ).test(reportedError.toLowerCase())
  ) {
    return "Formato codice Asl non valido: richiesti 3 caratteri alfanumerici";
  } else if (
    RegExp(
      "cvc-simple-type(.*)codicessa value(.*)is not a valid instance of type"
    ).test(reportedError.toLowerCase())
  ) {
    return "Formato codice SSA non valido: richiesti 3 caratteri alfanumerici";
  } else return reportedError;
};

const _getInviaErrorMessage = function (responseMsg) {
  if (/^Required request body is missing/.test(responseMsg)) {
    responseMsg = "Credenziali STS assenti";
  } else if (/^Autenticazione negata/.test(responseMsg)) {
    responseMsg = "Autenticazione STS fallita";
  }
  return Log.debug(
    "gestisciEsito: getInviaErrorMessage(responseMsg)",
    responseMsg
  );
};

// ERR RETE: PUO' ESSERE SCATENATO IN DIVERSI STATI
const gestisciErrRete = async function (op, errorDialog) {
  const getMsg = async () => {
    const res = await op.context.response;
    if (R.isNil(res)) return "";

    const msg = (await res.json()).message;
    return R.isNil(msg) ? "" : msg;
  };

  let title, message, html;
  switch (op.event.type) {
    case "error.platform.aggiungiFattura":
    case "error.platform.aggiornaFattura":
    case "done.invoke.aggiungiFattura":
    case "done.invoke.aggiornaFattura":
      if (
        !R.isNil(op.response) &&
        op.response.status === StatusCodes.BAD_REQUEST
      ) {
        Log.warn("gestisciEsito: errore - Utente non attivo");
        title = "Utente non attivo";
        message =
          "Per poter inoltrare la fattura è necessario procedere al rinnovo del servizio";
      } else {
        Log.warn(
          "gestisciEsito: errore aggiungi fattura - notifico all'utente"
        );
        title = "Impossibile contattare l'infrastruttura Mida4";
        message = `Errore registrazione fattura ${await getMsg()}`;
      }
      break;
    case "error.platform.inviaFattura":
    case "done.invoke.inviaFattura":
      Log.warn("errore di rete - fattura non inviata");
      // eslint-disable-next-line no-case-declarations
      let msg = await getMsg();
      title = "Errore";

      if (msg.toLowerCase() === "forbidden") {
        title = "Servizio scaduto";
        msg = "Servizio scaduto";
      }

      message = `La fattura è stata registrata sui server Mida4 ma non è stato possibile procedere con l'inoltro: ${
        R.isEmpty(msg) ? "errore di rete" : msg
      }`;
  }
  if (html === undefined) {
    errorDialog({ title, message });
  } else {
    errorDialog({ title, html });
  }
};

export default function gestioneEsito({
  op,
  errorDialog,
  alertDialog,
  successDialog,
  onInviato,
  generaElencoMessaggi,
}) {
  const { fattura, messaggi, errore } = op.context;
  let fattClient, dest, opts;

  Log.debug("----> errore", errore);
  switch (errore) {
    case CodiciErroreInvio.ERR_RETE:
      if (
        op.event.type.split(".").pop() === "inviaFattura" &&
        !R.isNil(fattura)
      ) {
        fattClient = convertiUsoClient(
          Log.debug("gestioneEsito convertiUsoClient", fattura)
        );
      }
      gestisciErrRete(op, errorDialog);
      break;
    case CodiciErroreInvio.ERR_AGGIUNGI:
    case CodiciErroreInvio.ERR_AGGIORNA:
      gestisciErrMida(op, errorDialog);
      break;
    case CodiciErroreInvio.ERR_INVIA:
      fattClient = convertiUsoClient(fattura);
      gestisciErrServMin(op, alertDialog, errorDialog, generaElencoMessaggi);
      break;
    default:
      fattClient = convertiUsoClient(fattura);
      dest = fattClient.destinazioneFattura || "SDI";
      opts = {
        didClose: onInviato,
      };
      if (dest === "SDI") {
        opts.title = "Operazione conclusa";
        opts.message =
          "Il documento è stato trasmesso al Sistema di Interscambio";
      } else {
        if (
          R.isEmpty(messaggi) ||
          R.isNil(messaggi) ||
          messaggi.toLowerCase() === "operazione eseguita correttamente"
        ) {
          opts.title = "Operazione conclusa";
          opts.message = "Il documento è stato accolto dal sistema";
        } else {
          opts.title = "Operazione conclusa con segnalazioni";
          opts.html = generaElencoMessaggi({
            tipo: "segnalazioni",
            messaggi: messaggi,
          });
        }
      }
      successDialog(opts);
  }
  return fattClient;
}

