/**
 * Registrazione / Aggiornamento / Invio documento
 *
 * .gestioneInvio: FSM - lo stato coincide con la fase di trasmissione del doc
 *
 * - lo stato iniziale è sempre <waiting>
 *
 * - in base al valore delle *guards* (vedi sotto) la fattura può passare a
 *     - <da aggiungere> -> il doc esiste solo in locale, va registrato sul server Mida
 *     - <da aggiornare> -> il doc è stato modificato rispetto a quanto presente
 *                          sul server Mida, va aggiornato
 *     - <da inviare> -> il doc, registrato sul server Mida, è pronto pr l'invio
 *
 * - lo stato successivo deriva dal valore delle *guards* successivamente alla chiamata al server
 * - gli stati terminali sono <failure> o <inviata>
 *
 * - raggiunto uno stato terminale, l'oggetto op (operazione)
 *   viene passato a
 *
 * .gestioneEsito: uno switch che gestisce il feedback utente e
 *                 se l'esito è positivo trasforma la fattura ricevuta nel
 *                 formato client
 *
 */

/* eslint-disable no-unused-vars */

import { createMachine, assign } from "xstate";
import StatusCodes from "http-status-codes";

import Log from "../../mida4-web-app-utils/log.js";
import { equals, convertiUsoClient, getUltimiMessaggi } from "../doc.js";
import * as R from "ramda";

import StatoInvio from "../entities/enums/stato-invio";
import CodiciErroreInvio from "../entities/enums/codici-errore-invio";
import gestioneEsito from "./gestisciEsito";

// const RITENTA_INVIO = false
const MAX_TENTATIVI_INVIO = 1;

// *guards* - verifiche di stato (non sono validazioni !)

const isDaAggiungere = (ctx, _) => {
  return Log.debug(
    "invioService:isDaAggiungere ",
    [ctx.fattura.id, ctx.docAttivo].every(R.isNil)
  );
};
const isDaAggiornare = (ctx, _) => {
  return (
    Log.debug(
      "invioService:isDaAggiornare ! R.isNil(ctx.docAttivo)",
      !R.isNil(ctx.docAttivo)
    ) &&
    // ! (ctx.docAttivo.statoInvio === 'DA_INVIARE' && ctx.fattura.statoInvio === 'ESITO_ERR'),
    Log.debug(
      "invioService:isDaAggiornare ! equals(ctx.docAttivo, convertiUsoClient(ctx.fattura), ctx)",
      !equals(ctx.docAttivo, convertiUsoClient(ctx.fattura), ctx)
    )
  );
};
const isDaInviare = (ctx, evt) => {
  return Log.debug(
    "invioService:isDaInviare ",
    !isDaAggiungere(ctx, evt) && !isDaAggiornare(ctx, evt)
  );
};
const isAcquisita = (_, evt) => {
  return Log.debug(
    "invioService:isAcquisita ",
    evt.data.response.status === 200 && StatoInvio.isInviato(evt.data.fattura)
  );
};
const isRigettata = (_, evt) => {
  return Log.debug(
    `invioService:isRigettata ${evt.data.response.status} `,
    evt.data.response.status === 200 && !StatoInvio.isInviato(evt.data.fattura)
  );
};
const shouldRetry = (ctx, _) => {
  return Log.debug(
    `invioService:shouldRetry ${ctx.retry ? "on" : "off"}/#${ctx.tentativi} :`,
    ctx.retry && ctx.tentativi < MAX_TENTATIVI_INVIO
  );
};
const isRetryDone = (ctx, evt) => {
  return Log.debug(
    "invioService:isRetryDone ",
    !shouldRetry(ctx, evt) ||
      ctx.errore === CodiciErroreInvio.ERR_INVIA ||
      ctx.errore === CodiciErroreInvio.ERR_AGGIUNGI ||
      ctx.errore === CodiciErroreInvio.ERR_AGGIORNA
  );
};

// actions
const _parseApiCall = async (response) => {
  let fattura, errore, text;
  try {
    const jsonRes = await response.clone().json();
    if (response.ok) {
      fattura = jsonRes;
    } else {
      errore = jsonRes;
    }
  } catch (e) {
    errore = Log.error(
      "InvioService:_parseApiCall - impossibile estrarre il json dal response",
      e
    );
    text = await response.clone().text();
  }
  return { response, fattura, errore, text };
};

const aggiungiFattura = async ({ api, fattura }) =>
  Log.debug(
    "invioService: _parseApiCall( await api.aggiungiFattura(fattura) )",
    _parseApiCall(await api.aggiungiFattura(fattura))
  );
const aggiornaFattura = async ({ api, fattura }) =>
  Log.debug(
    "invioService: _parseApiCall( await api.modificaFattura(fattura) )",
    _parseApiCall(await api.modificaFattura(fattura))
  );
const inviaFattura = async ({
  api,
  credenzialiSTS,
  destinazioneFattura,
  id,
}) => {
  return Log.debug(
    `invioService [dest: ${destinazioneFattura}]: _parseApiCall( await api.inviaFattura() )`,
    _parseApiCall(
      destinazioneFattura === "STS"
        ? await api.inviaFattura(credenzialiSTS, id)
        : await api.inviaFattura(id)
    )
  );
};

const InvioService = {
  gestioneInvio: createMachine({
    id: "invioService",
    initial: "waiting",
    context: {
      // per l'invio, non cambiano
      SchemaCliente: null,
      SchemaIndirizzo: null,
      SchemaFattura: null,
      SchemaRigaFattura: null,
      api: null,
      credenzialiSTS: null,
      // cambiano
      docAttivo: null,
      fattura: null,
      aggiornata: false,
      response: null,
      tentativi: 0,
      errore: null,
      messaggi: [],
      retry: null,
    },
    states: {
      waiting: {
        always: [
          { target: "daAggiungere", cond: isDaAggiungere },
          { target: "daAggiornare", cond: isDaAggiornare },
          { target: "daInviare", cond: isDaInviare },
        ],
      },
      daAggiungere: {
        invoke: {
          id: "aggiungiFattura",
          src: ({ api, fattura }, _) => aggiungiFattura({ api, fattura }),
          onDone: [
            {
              target: "waiting",
              cond: (_, evt) => evt.data.response.status === StatusCodes.OK,
              actions: assign({
                response: (_, evt) => evt.data.response,
                fattura: (_, evt) => evt.data.fattura,
                tentativi: 0,
              }),
            },
            {
              target: "failure.aggiungi",
              cond: (_, evt) => evt.data.response.status !== StatusCodes.OK,
              actions: assign({
                response: (_, evt) => evt.data.response,
                tentativi: (ctx, _) => ctx.tentativi + 1,
                errore: (ctx, _) => CodiciErroreInvio.ERR_AGGIUNGI,
              }),
            },
          ],
          onError: {
            target: "failure.rete",
            actions: assign({
              response: (_, evt) => evt.data.response,
              tentativi: (ctx, _) => ctx.tentativi + 1,
              errore: (ctx, _) => CodiciErroreInvio.ERR_RETE,
            }),
          },
        },
      },
      daAggiornare: {
        invoke: {
          id: "aggiornaFattura",
          src: ({ api, fattura }, _) => aggiornaFattura({ api, fattura }),
          onDone: [
            {
              target: "waiting",
              cond: (_, evt) => evt.data.response.status === StatusCodes.OK,
              actions: assign({
                response: (_, evt) => evt.data.response,
                fattura: (_, evt) => evt.data.fattura,
                docAttivo: (_, evt) => convertiUsoClient(evt.data.fattura),
                tentativi: 0,
              }),
            },
            {
              target: "failure.aggiorna",
              cond: (_, evt) => evt.data.response.status !== StatusCodes.OK,
              actions: assign({
                response: (_, evt) => evt.data.response,
                tentativi: (ctx, _) => ctx.tentativi + 1,
                errore: (ctx, _) => CodiciErroreInvio.ERR_AGGIORNA,
              }),
            },
          ],
          onError: {
            target: "failure.rete",
            actions: assign({
              response: (_, evt) => evt.data.response,
              tentativi: (ctx, _) => ctx.tentativi + 1,
              errore: (ctx, _) => CodiciErroreInvio.ERR_RETE,
            }),
          },
        },
      },
      daInviare: {
        invoke: {
          id: "inviaFattura",
          src: (
            { api, credenzialiSTS, fattura: { id, destinazioneFattura } },
            event
          ) => inviaFattura({ api, credenzialiSTS, destinazioneFattura, id }),
          onDone: [
            {
              // doc OK
              target: "inviata",
              cond: isAcquisita,
              actions: assign({
                response: (_, evt) => evt.data.response,
                fattura: (_, evt) => evt.data.fattura,
                messaggi: (_, evt) => getUltimiMessaggi(evt.data.fattura),
                tentativi: null,
              }),
            },
            {
              // doc KO
              target: "failure.invia",
              cond: isRigettata,
              actions: assign({
                response: (_, evt) => evt.data.response,
                fattura: (_, evt) => evt.data.fattura,
                //docAttivo: (_, evt) => convertiUsoClient(evt.data.fattura),
                messaggi: (_, evt) => getUltimiMessaggi(evt.data.fattura),
                tentativi: (ctx, _) => ctx.tentativi + 1,
                errore: CodiciErroreInvio.ERR_INVIA,
              }),
            },
            {
              // timeout SDI finisce qui
              target: "failure.invia",
              cond: (_, evt) => evt.data.response.status !== StatusCodes.OK,
              actions: assign({
                response: (_, evt) => evt.data.response,
                fattura: (_, evt) => evt.data.fattura,
                tentativi: (ctx, _) => ctx.tentativi + 1,
                errore: (ctx, _) => CodiciErroreInvio.ERR_RETE,
                // ctx.tentativi === CodiciErroreInvio.MAX_TENTATIVI_INVIO
                // ? CodiciErroreInvio.ERR_RETE
                // : null
              }),
            },
          ],
          onError: {
            target: "failure.rete",
            actions: assign({
              response: (_, evt) => evt.data.response,
              tentativi: (ctx, _) => ctx.tentativi + 1,
              errore: (ctx, _) => CodiciErroreInvio.ERR_RETE,
            }),
          },
        },
      },
      inviata: {
        type: "final",
      },
      failure: {
        initial: "rete",
        states: {
          rete: {
            always: [
              { target: "#invioService.waiting", cond: shouldRetry },
              { target: "#invioService.errore", cond: isRetryDone },
            ],
          },
          aggiungi: {
            always: [
              { target: "#invioService.waiting", cond: shouldRetry },
              { target: "#invioService.errore", cond: isRetryDone },
            ],
          },
          aggiorna: {
            always: [
              { target: "#invioService.waiting", cond: shouldRetry },
              { target: "#invioService.errore", cond: isRetryDone },
            ],
          },
          invia: {
            always: [
              { target: "#invioService.waiting", cond: shouldRetry },
              { target: "#invioService.errore", cond: isRetryDone },
            ],
          },
        },
      },
      errore: {
        type: "final",
      },
    },
    guards: {
      isDaAggiungere,
      isDaAggiornare,
      isDaInviare,
      isAcquisita,
      isRigettata,
      shouldRetry,
      isRetryDone,
    },
  }),
  gestioneEsito: gestioneEsito,
};

export default InvioService;
