<template>
  <LayoutSpSa>
    <section id="benvenuto" v-if="datiUtente !== null" :class="benvenutoClass">
      <div>
        Buongiorno {{ datiUtente.nome !== null ? datiUtente.nome + " " : ""
        }}{{ datiUtente.cognomeDenom }}
        <ReminderStatoAccountSpSa />
      </div>
    </section>
    <section id="logout-msg" v-else>Mida4 Invio Spese Sanitarie</section>
    <section
      id="stats"
      v-if="stats !== null && datiUtente !== null && !credenzialiAssenti"
    >
      <StatsSpSa />
    </section>
    <section id="sospeso" v-if="datiUtente !== null && !credenzialiAssenti">
      <div v-frag v-if="hasSospeso">
        <!-- <ElencoDocumentiSpSa v-if="sospesoSDI.length" :fatture="sospesoSDI" :dashboard="'SDI'"/> -->
        <FattureEmesseSpSa
          v-if="sospesoSTS.length"
          :fatture="sospesoSTS"
          :dashboard="'STS'"
        />
      </div>
      <div id="nessuno" v-else>Non ci sono documenti in sospeso</div>
    </section>
   
  </LayoutSpSa>
</template>
<script>
const NAME = "HomeSpSa";

import LayoutSpSa from "@/components/_layout/LayoutSpSa";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { R, Log } from "mida4-web-app-utils";
import { getNumeroDoc } from "mida4-fattura-rapida-spese-sanitarie/doc";

import ReminderStatoAccountSpSa from "@/components/HomeSpSa/ReminderStatoAccountSpSa";
import StatsSpSa from "@/components/HomeSpSa/StatsSpSa";
import FattureEmesseSpSa from "@/components/DocumentiSpSa/FattureEmesseSpSa";

export default {
  name: NAME,
  components: {
    LayoutSpSa,
    ReminderStatoAccountSpSa,
    FattureEmesseSpSa,
    StatsSpSa,
  },
  data() {
    return {
      NAME: NAME,
    };
  },
  async mounted() {
    const tasks = [this.recuperaStatistiche()];
    this.toggleLoader(true);
    if (R.isEmpty(this.allFatture)) {
      tasks.push(this.recuperaElenco());
    }
    try {
      await Promise.all(tasks);
      this.toggleLoader(false);
    } catch (e) {
      Log.warn(
        "Home - errore inizializzazione (recuperaStatistiche & recuperaElenco)",
        e.message
      );
      this.toggleLoader(false);
      this.showError();
    }
  },
  computed: {
    ...mapGetters({
      credenzialiAssenti: "auth/credenzialiAssenti",
      scaduto: "user/scaduto",
      allFatture: "fatture/getFatture",
      allFattureSTS: "fatture/getFattureSTS",
      // allFattureSDI: 'fatture/getFattureSDI',
      sospesoSTS: "fatture/sospesoSTS",
      // sospesoSDI: 'fatture/sospesoSDI',
      hasSospeso: "fatture/hasSospeso",
      datiUtente: "user/getData",
      docDaElaborare: "fatture/getDocDaElaborare",
      stats: "stats/statistiche",
    }),
    benvenutoClass() {
      return this.scaduto ? "scaduto" : undefined;
    },
    txtFatturaButton() {
      return this.hasSospeso
        ? `COMPLETARE ${getNumeroDoc(this.docDaElaborare)}`
        : "NUOVA";
    },
  },
  methods: {
    ...mapMutations({
      setDocAttivo: "fatture/setDocAttivo",
    }),
    ...mapActions({
      recuperaStatistiche: "stats/fetchStatistiche",
      recuperaElenco: "fatture/recuperaElenco",
    }),
    showFattura() {
      this.setDocAttivo(this.docDaElaborare);
      this.$router.push("/fattura");
    },
  },
  notifications: {
    showError: {
      title: "Errore di rete",
      message:
        "Non è stato possibile recuperare l'elenco delle fatture. Controllare la connessione.",
      type: "error",
    },
  },
};
</script>
<style src="@/styles/custom/views/_home.scss" lang="scss"></style>
