import { arrotonda, R } from "../../../mida4-web-app-utils";
import { hasPIVA } from "../../doc";
import RegimeFiscale from "../enums/regime-fiscale";
/**
 * La ritenuta d’acconto al 20% va inserita nel documento contabile
 * solo se la prestazione professionale è svolta a favore di persone fisiche titolari di partita Iva
 * o persone giuridiche.
 *
 * La ritenuta d’acconto NON viene mai applicata
 * se il soggetto rientra nei seguenti regimi fiscali (vedere ../enums/regime-fiscale)
 *
 * RF02 Contribuenti minimi (art.1, c.96-117, L. 244/07)
 * RF19 Regime forfettario (art.1, c.54-89, L. 190/2014)
 */

const Ritenuta = Object.freeze({
  enti: {
    RT01: {
      descrizione: "Ritenuta persone fisiche",
      abbreviazione: null,
      aliquotaDefault: 20,
      applicatoDefault: true,
    },
    RT02: {
      descrizione: "Ritenuta persone giuridiche",
      abbreviazione: null,
      aliquotaDefault: null,
      applicatoDefault: true,
    },
    RT03: {
      descrizione: "Contributo INPS",
      abbreviazione: null,
      aliquotaDefault: null,
      applicatoDefault: true,
    },
    RT04: {
      descrizione: "Contributo ENASARCO",
      abbreviazione: null,
      aliquotaDefault: null,
      applicatoDefault: false,
    },
    RT05: {
      descrizione: "Contributo ENPAM",
      abbreviazione: null,
      aliquotaDefault: null,
      applicatoDefault: false,
    },
    RT06: {
      descrizione: "Altro contributo previdenziale",
      abbreviazione: null,
      aliquotaDefault: null,
      applicatoDefault: false,
    },
  },
  isRegimeConRitenuta(regimeFiscale) {
    return RegimeFiscale.puoApplicareRitenuta(regimeFiscale);
  },
  isApplicataDefault(cliente, ente) {
    const ENTI_CON_DEFAULT_RITENUTA = ["RT04"];
    return hasPIVA(cliente) && ENTI_CON_DEFAULT_RITENUTA.includes(ente);
  },
  getDefaultDocEnum(opts) {
    const valPropName = opts?.valPropName ?? "value";
    const lblPropName = opts?.lblPropName ?? "label";
    return Object.entries(this.enti).map(([k, v]) => ({
      [valPropName]: k,
      [lblPropName]: v.descrizione,
    }));
  },
  getDefaultDocEnumWithCodes(dest, opts) {
    return this.getDefaultDocEnum(dest, opts).map((e) => ({
      ...e,
      label: e.label + " - " + e.value,
    }));
  },
  espandiDescrizioneRitenuta(descrizione) {
    const ENTE = 0,
      ALIQUOTA = 1,
      CAUSALE = 2;
    const datiCassa = descrizione.split("-");
    return {
      descrizione: this._getDescrizioneRiga(
        datiCassa[ENTE],
        datiCassa[ALIQUOTA]
      ),
      ente: datiCassa[ENTE],
      aliquota: datiCassa[ALIQUOTA],
      causale: datiCassa[CAUSALE],
    };
  },
  compattaDescrizioneRitenuta(ente, aliquota, causale) {
    const _ali = aliquota.toString().replace(",", ".");
    return `${ente}-${_ali}-${causale}`;
  },
  getRigaFattura(righe, tipoRitenuta, aliquota, causale) {
    const importo = arrotonda((this._getImporto(righe) * aliquota) / 100);
    if (importo > 0) {
      return this._generaRiga({
        descrizione: this._getDescrizioneRiga(tipoRitenuta, aliquota),
        importo: importo,
        ente: tipoRitenuta,
        aliquota: aliquota,
        causale: causale,
        iva: 0,
      });
    }
  },
  _getImporto(righe) {
    return this._getRigheConRitenuta(righe).reduce(
      (_tot, r) => arrotonda(_tot + this._imponibile(r)),
      0.0
    );
  },
  _getDescrizioneRiga(ente, aliquota) {
    if ([ente, aliquota].some(R.isNil)) {
      throw new Error("campi richiesti: ente, aliquota");
    }
    return `Ritenuta ${this.enti[ente].descrizione} ${aliquota}%`;
  },
  _getRigheConRitenuta(righe) {
    const applicata = (r) => r.ritenuta === true; //&& r.importoUnitario > 0
    return righe.filter(applicata);
  },
  _imponibile(riga) {
    return arrotonda(
      parseFloat(riga.importoUnitario || 0) * parseFloat(riga.quantita || 0)
    );
  },
  _generaRiga({ descrizione, importo, iva, ente, aliquota, causale }) {
    return {
      _id: `ritenuta_${iva}`,
      editing: false,
      tipoRiga: "RITENUTA",
      ente: ente,
      aliquota: aliquota,
      causale,
      descrizione: descrizione,
      importoUnitario: importo,
      quantita: "1",
      IVA: iva,
    };
  },
});

export default Ritenuta;
