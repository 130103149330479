/** @module doc */

import { nanoid } from "nanoid";
import { parse, isValid, parseISO, compareAsc } from "date-fns";
import { format } from "date-fns-tz";

import isNil from "ramda/es/isNil";
import isEmpty from "ramda/es/isEmpty";
import clone from "ramda/es/clone";

import Log from "../mida4-web-app-utils/log";
import { insert } from "../mida4-web-app-utils/functions";

import REGEXES from "../mida4-web-app-utils/regexes";
import DateDefaults from "../mida4-web-app-utils/date-defaults";
import StatoInvio from "./entities/enums/stato-invio";
import ValoriIVA from "./entities/enums/valori-iva";

import CassaPrevidenziale from "./entities/regole/cassa-previdenziale";
import Ritenuta from "./entities/regole/ritenuta";

const R = { isNil, isEmpty, clone };

/**
 * Restituisce un array di campi per la riga fattura aggiungendo
 * IVA, che è un campo compatto utilizzato sul client
 * e non sul server, dopo quantita in modo da generare
 * correttamente la riga html
 *
 * @module doc
 * @function campiRiga
 *
 * @param {object} props
 * @property {object} props.SchemaRigaFattura
 * @property {array} props.campiEsclusi campi da escludere
 *
 * @returns {array} campi riga
 */
export function campiRiga({ SchemaRigaFattura, campiEsclusi }) {
  const schemaFiltrato = Object.keys(SchemaRigaFattura.properties).filter(
    (c) => ![...campiEsclusi, "tipoRiga"].includes(c)
  );
  return insert({
    arr: schemaFiltrato,
    el: "IVA",
    after: schemaFiltrato.indexOf("quantita"),
  });
}
/**
 * parseData
 *
 * @module doc
 * @function parseData
 * @param {string} d stringa da convertire in data
 * @param {string} f formato
 * @returns {date}
 */
export function parseData(d, f = DateDefaults.formats.CLIENT) {
  try {
    let r = parse(d, f, new Date());
    if (isNaN(r)) {
      r = parseISO(d);
    }
    return r;
  } catch (error) {
    return parseISO(d);
  }
}
/**
 * formatData
 *
 * @module doc
 * @function formatData
 * @param {date} d data da formattare
 * @param {string} f formato
 * @returns {string}
 */
export function formatData(d, f = DateDefaults.formats.CLIENT) {
  return format(d, f, { timeZone: "Europe/Rome" });
}
/**
 * formatDataView
 * formatta la data ad uso visualizzazione
 *
 * @module doc
 * @function formatDataView
 * @param {string} d data da formattare
 * @returns {string}
 */
export function formatDataView(d) {
  return formatData(parseData(d), DateDefaults.formats.VIEW);
}
export function formatDataOnlyDate(d) {
  return formatData(parseData(d), DateDefaults.formats.ONLY_DATE);
}
/**
 * formatDataClient
 * formatta la data ricevuta dal server ad uso client
 * (librerie)
 *
 * @module doc
 * @function formatDataClient
 * @param {string} d data da formattare
 * @returns {string}
 */
export function formatDataClient(d) {
  return formatData(parseISO(d));
}
/**
 * formatDataLog
 * formatta la data per i log
 *
 * @module doc
 * @function formatDataLog
 * @param {string} d data da formattare
 * @returns {string}
 */
export function formatDataLog(d) {
  return formatData(parseISO(d), DateDefaults.formats.LOG);
}
/**
 * compareData
 * confronta la data del documento
 * con la data fornita
 *
 * @module doc
 * @function compareData
 * @param {object} doc
 * @param {date} giorno
 * @returns {number}
 */
export function compareData(doc, giorno) {
  return compareAsc(parseData(doc.dataDocumento), giorno);
}
/**
 * afterOrEq
 * verifica se la data del documento
 * è successiva o uguale
 * alla data fornita
 *
 * @module doc
 * @function afterOrEq
 * @param {object} doc
 * @param {date} giorno
 * @returns {boolean}
 */
export function afterOrEq(doc, giorno) {
  return compareData(doc, giorno) >= 0;
}
/**
 * beforeOrEq
 * verifica se la data del documento
 * è precedente o uguale
 * alla data fornita
 *
 * @module doc
 * @function beforeOrEq
 * @param {object} doc
 * @param {date} giorno
 * @returns {boolean}
 */
export function beforeOrEq(doc, giorno) {
  return compareData(doc, giorno) <= 0;
}
/**
 * Verifica che una fattura abbia il cliente correttamente
 * valorizzato
 *
 * @module doc
 * @function hasCliente
 *
 * @param {Object} cliente cliente
 * @param {Object} schema oggetto contenente due array per i campi obbligatori di cliente ed indirizzo
 * @returns {boolean}
 */
export function hasCliente(cliente, [reqCliente, reqIndirizzo]) {
  Log.group("Doc.hasCliente");
  Log.debug("cliente", cliente);

  const datiCompleti = (dati, campi) =>
    campi.reduce(
      (mancanti, campo) =>
        mancanti ||
        Log.debug(
          `dati ${campo} mancanti ?`,
          R.isNil(dati[campo]) || R.isEmpty(dati[campo])
        ),
      false
    ) === false;

  const result =
    Log.debug("cliente NOT NULL", !R.isNil(cliente)) &&
    Log.debug("datiCompleti cliente", datiCompleti(cliente, reqCliente)) &&
    Log.debug(
      "datiCompleti cliente.sede",
      datiCompleti(cliente.sede, reqIndirizzo)
    );

  Log.debug("result", result);
  Log.endGroup("Doc.hasCliente");

  return result;
}
/**
 * Verifica se la fattura è in uno stato che prevede la presenza di messaggi
 *
 * @module doc
 * @function hasMessaggi
 *
 * @param {object} d
 * @returns {boolean}
 */
export function hasMessaggi(d) {
  return StatoInvio.hasMessaggi(d);
}
/**
 * isSTS
 * verifica se è un documento per STS
 *
 * @module doc
 * @function isSTS
 * @param {object} d
 * @returns {boolean}
 */
export function isSTS(d) {
  return d.destinazioneFattura === "STS";
}
/**
 * isSDI
 * verifica se è un documento per SDI
 *
 * @module doc
 * @function isSDI
 * @param {object} d
 * @returns {boolean}
 */
export function isSDI(d) {
  return d.destinazioneFattura === "SDI";
}
/**
 * isAggiornabileSDI
 * verifica se un documento SDI
 * richiede aggiornamento
 * (ATTESA_ESITO o SDI_TIMEOUT)
 *
 * @module doc
 * @function isAggiornabileSDI
 * @param {object} d
 * @returns {boolean}
 */
export function isAggiornabileSDI(d) {
  return (
    !R.isNil(d) &&
    [StatoInvio.ATTESA_ESITO, StatoInvio.SDI_TIMEOUT].includes(d.statoInvio)
  );
}
/**
 * isDaElaborare
 * verifica se un documento
 * è in stato
 * (DA_INVIARE o ESITO_ERR o STS_UNAVAILABLE)
 *
 * @module doc
 * @function isDaElaborare
 * @param {object} d
 * @returns {boolean}
 */
export function isDaElaborare(d) {
  return (
    !R.isNil(d) &&
    [
      StatoInvio.DA_INVIARE,
      StatoInvio.ESITO_ERR,
      StatoInvio.STS_UNAVAILABLE,
    ].includes(d.statoInvio)
  );
}
/**
 * isSTSDaElaborare
 *
 * @module doc
 * @function isSTSDaElaborare
 */
export function isSTSDaElaborare(d) {
  return isDaElaborare(d) && isSTS(d);
}
/**
 * isSDIDaElaborare
 *
 * @module doc
 * @function isSDIDaElaborare
 */
export function isSDIDaElaborare(d) {
  return isDaElaborare(d) && !isSTS(d);
}
/**
 * isDestEditable
 *
 * @module doc
 * @function isDestEditable
 */
export function isDestEditable(docAttivo) {
  return (
    R.isNil(docAttivo) || [StatoInvio.LOCALE].includes(docAttivo.statoInvio)
  );
}
/**
 * hasPIVA
 *
 * @module doc
 * @function hasPIVA
 */
export function hasPIVA(persona) {
  return !(R.isEmpty(persona.partitaIVA) || R.isNil(persona.partitaIVA));
}
/**
 * clienteConPIVA
 *
 * @module doc
 * @function clienteConPIVA
 */
export function clienteConPIVA(d) {
  return hasPIVA(d.cliente);
}
/**
 * isRigaBollo
 *
 * @module doc
 * @function isRigaBollo
 */
export function isRigaBollo(rID) {
  return REGEXES.BOLLO.test(rID);
}
/**
 * isRigaCassaPrevidenziale
 *
 * @module doc
 * @function isRigaCassaPrevidenziale
 */
export function isRigaCassaPrevidenziale(rID) {
  return REGEXES.CASSA_PREVIDENZIALE.test(rID);
}
/**
 * isRigaRitenuta
 *
 * @module doc
 * @function isRigaRitenuta
 */
export function isRigaRitenuta(rID) {
  return REGEXES.RITENUTA.test(rID);
}
/**
 * isRigaAutogenerata
 *
 * @module doc
 * @function isRigaAutogenerata
 */
export function isRigaAutogenerata(rID) {
  return REGEXES.BOLLO_CASSA_RITENUTA.test(rID);
}
/**
 * isErroreDaCorreggere
 *
 * @module doc
 * @function isErroreDaCorreggere
 */
export function isErroreDaCorreggere(d) {
  let msg = getElencoMessaggi(d)[0].descrizione.toLowerCase();
  return ![
    "errore non gestito",
    "credenziali invalide",
    "cvc-simple-type(.*)[codiceRegione|codiceAsl|codiceSSA] value(.*)is not a valid instance of type",
    "cvc-simple-type(.*)[codiceRegione|codiceAsl|codiceSSA] (.*)may not be empty",
    "cvc-simple-type(.*)[codiceRegione|codiceAsl|codiceSSA]",
    "pincode del medico errato o non piu' attivo",
  ].some((str) => RegExp(str).test(msg));
}
/**
 * getStatoInvioDoc
 *
 * @module doc
 * @function getStatoInvioDoc
 * @param {object} d
 */
export function getStatoInvioDoc(d) {
  return !R.isNil(d) && d.statoInvio !== StatoInvio.LOCALE
    ? d.statoInvio
    : null;
}
/**
 * getElencoMessaggi
 *
 * @module doc
 * @function getElencoMessaggi
 * @param {object} d
 */
export function getElencoMessaggi(d) {
  let ret = [];
  if (!R.isNil(d) && !R.isEmpty(d.logInvii)) {
    ret = [...d.logInvii].map((e) => ({ ...e })).reverse(); // converto l'observer nell'oggetto contenuto
  }
  return ret;
}
/**
 * getUltimiMessaggi
 *
 * @module doc
 * @function getUltimiMessaggi
 * @param {object} d
 * @returns {string}
 */
export function getUltimiMessaggi(d, html = false) {
  const last = getElencoMessaggi(d).shift();
  if (last) return last.descrizione.replace("\n", html ? "<br>" : "\n") || "";
}
/**
 * Converte a uso client i valori per il default documento
 * ricevuti dal server
 *
 * restituisce l'oggetto da salvare nello store vuex
 *
 * @module doc
 * @function getUserDefaults
 *
 * @param {object} userDefaults
 */
export function getUserDefaults({
  iva,
  cassaPrevidenziale,
  ritenuta,
  importo,
  ...storedDefaults
}) {
  Log.group("Doc.getUserDefaults");
  try {
    Log.debug("cassaPrevidenziale", cassaPrevidenziale);

    // imposto l'aliquota a 0 se cassa NESSUNA
    if (R.isNil(cassaPrevidenziale) || cassaPrevidenziale.cassa === "NESSUNA") {
      cassaPrevidenziale = {
        cassa: null,
        aliquota: 0,
      };
    }

    // imposto l'aliquota a 0 se tipo NESSUNA
    if (R.isNil(ritenuta) || ritenuta.tipo === "NESSUNA") {
      ritenuta = {
        tipo: null,
        causale: "",
        aliquota: 0,
      };
    }
    const _storedDefaults = {
      ...R.clone(storedDefaults),
      IVA: compattaTipoIVA(iva),
      quantita: 1,
      tipoCassaPrevidenziale: cassaPrevidenziale.cassa,
      aliquotaCassaPrevidenziale: cassaPrevidenziale.aliquota,
      tipoRitenuta: ritenuta.tipo,
      causaleRitenuta: ritenuta.causale,
      aliquotaRitenuta: ritenuta.aliquota,
    };

    Log.debug("_defaultDocumento", _storedDefaults);
    ["iva", "cassaPrevidenziale", "importo"].forEach(
      (attr) => delete _storedDefaults[attr]
    );

    Log.debug(
      "R.isNil(cassaPrevidenziale) ? null : cassaPrevidenziale.cassa",
      R.isNil(cassaPrevidenziale) ? null : cassaPrevidenziale.cassa
    );
    Log.debug(
      "cassaPrevidenziale.cassa !== 'NESSUNA' ? cassaPrevidenziale.cassa : null",
      cassaPrevidenziale.cassa !== "NESSUNA" ? cassaPrevidenziale.cassa : null
    );
    Log.debug(
      "_storedDefaults.tipoCassaPrevidenziale",
      _storedDefaults.tipoCassaPrevidenziale
    );
    Log.endGroup("Doc.getUserDefaults");

    return _storedDefaults;
  } catch (error) {
    Log.endGroup("Doc.getUserDefaults");

    error.message = `Doc.getUserDefaults | ${error.message}`;
    throw Error(error);
  }
}
/**
 * getCassaPrevDaSalvare
 *
 * @module doc
 * @function getCassaPrevDaSalvare
 */
export function getCassaPrevDaSalvare(defaultDoc) {
  Log.debug("tipoCassa");
  const tipoCassa =
    R.isEmpty(defaultDoc.tipoCassaPrevidenziale) ||
    R.isNil(defaultDoc.tipoCassaPrevidenziale)
      ? "NESSUNA"
      : defaultDoc.tipoCassaPrevidenziale;

  Log.debug("aliquotaCassa");
  const aliquotaCassa =
    R.isEmpty(defaultDoc.aliquotaCassaPrevidenziale) ||
    R.isNil(defaultDoc.aliquotaCassaPrevidenziale)
      ? "0"
      : defaultDoc.aliquotaCassaPrevidenziale.toString();

  return {
    cassa: tipoCassa,
    aliquota: aliquotaCassa,
  };
}
/**
 * getRitenutaDaSalvare
 *
 * @module doc
 * @function getRitenutaDaSalvare
 */
export function getRitenutaDaSalvare(defaultDoc) {
  const hasVal = (f) => !(R.isEmpty(f) || R.isNil(f));

  let tipoRitenuta = "NESSUNA";
  let causaleRitenuta = "";
  let aliquotaRitenuta = "0";

  if (hasVal(defaultDoc.tipoRitenuta)) {
    tipoRitenuta = defaultDoc.tipoRitenuta;
    aliquotaRitenuta = hasVal(defaultDoc.aliquotaRitenuta)
      ? defaultDoc.aliquotaRitenuta.toString()
      : "0";
    causaleRitenuta = hasVal(defaultDoc.causaleRitenuta)
      ? defaultDoc.causaleRitenuta
      : "";
  }

  return {
    tipo: tipoRitenuta,
    causale: causaleRitenuta,
    aliquota: aliquotaRitenuta,
  };
}
/**
 * Converte l'utente locale per il salvataggio sul server
 *
 * default documento:
 * - espande iva in un oggetto con natura e aliquota
 * - cassaPrevidenziale: compatta i campi tipoCassa e aliquota
 *   in un oggetto unico
 *
 * restituisce l'oggetto da salvare sul server
 *
 * @module doc
 * @function getUtenteDaSalvare
 *
 * @param {object} u
 */
export function getUtenteDaSalvare(u, dest = "STS") {
  if (dest === "SDI") {
    u.iban = u.defaultDocumento.iban;
    delete u.defaultDocumento.iban;
  }

  try {
    const defaultDocIva = R.isEmpty(u.defaultDocumento.IVA)
      ? ValoriIVA.getDefault()
      : u.defaultDocumento.IVA.toString();

    const utenteDaSalvare = Log.debug("utenteDaSalvare", {
      ...R.clone(u),
      sede: R.clone(u.sede),
      defaultDocumento: {
        ...R.clone(u.defaultDocumento),
        iva: espandiTipoIVA(defaultDocIva),
        cassaPrevidenziale: getCassaPrevDaSalvare(u.defaultDocumento),
        ritenuta: getRitenutaDaSalvare(u.defaultDocumento),
        // TODO: verificare - i seguenti campi non dovrebbero servire
        IVA: null,
        tipoCassaPrevidenziale: null,
        aliquotaCassaPrevidenziale: null,
      },
    });

    return utenteDaSalvare;
  } catch (error) {
    error.message = `Doc.getUtenteDaSalvare | ${error.message}`;
    throw Error(error);
  }
}
/**
 * getNumeroDoc
 *
 * @module doc
 * @function getNumeroDoc
 */
export function getNumeroDoc(d) {
  return R.isNil(d)
    ? null
    : `${d.sezionaleDocumento}/${d.numeroDocumento}/${format(
        parseData(d.dataDocumento),
        DateDefaults.formats.ANNO
      )}`;
}
/**
 * getRigaBollo
 *
 * @module doc
 * @function getRigaBollo
 */
export function getRigaBollo(d) {
  return d.righe.find((r) => r._id === "bollo");
}
/**
 * getImportoTotale
 *
 * @module doc
 * @function getImportoTotale
 */
export function getImportoTotale(righe) {
  let _righe = righe.filter((r) => !isRigaRitenuta(r._id));
  return parseFloat(sommaRighe(_righe, importoRiga));
}
/**
 * getNettoAPagare
 *
 * @module doc
 * @function getNettoAPagare
 */
export function getNettoAPagare(righe) {
  const importoRitenuta = parseFloat(
    sommaRighe(
      righe.filter((r) => isRigaRitenuta(r._id)),
      importoRiga
    )
  );
  return getImportoTotale(righe) - importoRitenuta;
}
/**
 * getIVATotale
 *
 * @module doc
 * @function getIVATotale
 */
export function getIVATotale(righe) {
  let _righe = righe.filter((r) => !isRigaRitenuta(r._id));
  return parseFloat(sommaRighe(_righe, IVARiga));
}
/**
 * Restituisce i campi obbligatori per il cliente
 * in base al fatto che si tratti di persona fisica
 * o persona giuridica
 *
 * @module doc
 * @function getClienteRequiredFields
 *
 * @param {boolean} isPersGiuridica
 * @param {object} SchemaCliente
 * @returns {array} campi obbligatori per il cliente
 */
export function getClienteRequiredFields(
  isPersGiuridica,
  SchemaCliente,
  nazione = "IT",
  cf = ""
) {
  const filtraCodiceFiscaleENome = (e) =>
    !["codiceFiscale", "nome"].includes(e);
  const soloPersGiuridicaNoIVA = (e) =>
    !["partitaIVA", "paesePartitaIVA", "nome"].includes(e);
  const soloPersFisica = (e) => !["partitaIVA", "paesePartitaIVA"].includes(e);

  let campi;

  if (nazione !== "IT") {
    campi = new Set([
      ...SchemaCliente.required.filter(filtraCodiceFiscaleENome),
      "partitaIVA",
      "paesePartitaIVA",
    ]);
  } else if (isPersGiuridica) {
    const requireIVA = cf ? !cf.startsWith(9) && !cf.startsWith(8) : true;
    campi = requireIVA
      ? new Set([
          ...SchemaCliente.required.filter(filtraCodiceFiscaleENome),
          "partitaIVA",
          "paesePartitaIVA",
        ])
      : new Set([...SchemaCliente.required.filter(soloPersGiuridicaNoIVA)]);
  } else {
    campi = new Set([...SchemaCliente.required.filter(soloPersFisica), "nome"]);
  }

  // console.log("getClienteRequiredFields: campi", [...campi])

  return [...campi];
}
/**
 * checkHasRighe
 *
 * @module doc
 * @function checkHasRighe
 */
export function checkHasRighe(d) {
  return !R.isNil(d.righe) && d.righe.length > 0;
}
/**
 * checkIntestazione
 *
 * @module doc
 * @function checkIntestazione
 */
export function checkIntestazione(d) {
  // data pagamento deve essere coincidente o maggiore rispetto alla data di emissione.
  // Può essere minore rispetto alla data di emissione solo se valorizzato a 1 il flagpagamento anticipato

  const [dataDoc, dataPag] = [d.dataDocumento, d.dataPagamento].map((g) =>
    parseData(g)
  );

  return (
    isValid(dataDoc) &&
    isValid(dataPag) &&
    // && compareAsc(dataPag, dataDoc) >= 0 // TODO FORSE VA TOLTO
    ["STS", "SDI"].includes(d.destinazioneFattura) &&
    ![d.modalitaPagamento, d.pagamentoTracciabile].some(R.isNil)
  );
}
/**
 * A CAUSA DEL BINDING TRA FORM E SCHEMA, CAPITA CHE
 * ALCUNI VALORI RISULTINO DA UNA PARTE NULL E DALL'ALTRA STRINGA VUOTA
 *
 * verifica che i valori siano uguali o entrambi concretamente vuoti
 */
function _eqValue(v1, v2) {
  return v1 === v2 || [v1, v2].every((i) => R.isEmpty(i) || R.isNil(i));
}
/**
 * Verifica se due documenti sono uguali
 *
 * @module doc
 * @function equals
 *
 * @param {object} d1 documento
 * @param {object} d2 documento
 * @param {object} { SchemaCliente, SchemaIndirizzo, SchemaFattura, SchemaRigaFattura }
 * @returns {boolean} uguali
 */
export function equals(
  d1,
  d2,
  { SchemaCliente, SchemaIndirizzo, SchemaFattura, SchemaRigaFattura }
) {
  // per ogni proprietà dello schema fattura verifico che coincidano
  // confronto il sezionale solo se è la prima fattura e non è inviata
  const escludiProp = (prop) => {
    let daEscludere = ["statoInvio"];
    if (!R.isNil(d1) && parseInt(d1.numeroDocumento) === 1) {
      daEscludere.push("sezionaleDocumento");
    }
    return !daEscludere.includes(prop);
  };
  const stessoDoc = () =>
    Object.keys(SchemaFattura.properties)
      .filter(escludiProp)
      .reduce((res, prop) => {
        const ret = res && _eqValue(d1[prop], d2[prop]);
        if (ret === false)
          Log.debug(
            "! stessoDoc",
            JSON.stringify({ [prop]: [d1[prop], d2[prop]] }, null, 2)
          );
        return ret;
      }, true);
  // per ogni proprietà dello schema cliente e cliente.indirizzo verifico che coincidano
  const stessoCliente = () => {
    const stessiDati = Object.keys(SchemaCliente.properties)
      .filter((k) => k !== "id")
      .reduce((res, prop) => {
        const ret = res && _eqValue(d1.cliente[prop], d2.cliente[prop]);
        if (!ret)
          Log.debug(
            "stessoCliente -> stessiDati -> ! d1.cliente[prop] == d2.cliente[prop]",
            JSON.stringify(
              { [`prop: ${prop}`]: [d1.cliente[prop], d2.cliente[prop]] },
              null,
              2
            )
          );
        return ret;
      }, true);
    const stessaSede = Object.keys(SchemaIndirizzo.properties).reduce(
      (res, prop) => {
        const ret =
          res && _eqValue(d1.cliente.sede[prop], d2.cliente.sede[prop]);
        if (!ret)
          Log.debug(
            "stessoCliente -> stessaSede -> ! d1.cliente.sede[prop] == d2.cliente.sede[prop]",
            JSON.stringify(
              {
                [`prop: ${prop}`]: [
                  d1.cliente.sede[prop],
                  d2.cliente.sede[prop],
                ],
              },
              null,
              2
            )
          );
        return ret;
      },
      true
    );
    return stessiDati && stessaSede;
  };
  // verifico che abbiano le stesse righe con gli stessi valori
  const stesseRighe = () => {
    if (d1.righe.length !== d2.righe.length) return false;
    const _campiRiga = [...Object.keys(SchemaRigaFattura.properties), "IVA"];
    // creo un array di coppie di righe per confrontarle una a una
    const zipRighe = d1.righe.map((r, i) => [r, d2.righe[i]]);
    // per ogni coppia di righe
    return zipRighe.reduce((res, rr) => {
      // confronto ciascun campo
      return (
        res &&
        _campiRiga.reduce((eq, it) => {
          //verifico perchè iva non uguale
          return (
            eq &&
            Log.debug(
              `__  ${it} - 0: ${rr[0][it]} (${typeof rr[0][it]}) | 1: ${
                rr[1][it]
              } (${typeof rr[1][it]})`,
              _eqValue(rr[0][it], rr[1][it])
            )
          );
        }, true)
      );
    }, true);
  };

  if (R.isEmpty(d1) || R.isEmpty(d2))
    throw Error("Una delle due fatture è un'oggetto vuoto");

  return (
    Log.debug("checkModificato: stessoDoc()", stessoDoc()) &&
    Log.debug("checkModificato: stessoCliente()", stessoCliente()) &&
    Log.debug("checkModificato: stesseRighe()", stesseRighe())
  );
}
/**
 * Converte un documento ricevuto dal server per uso locale
 *
 * - impostaBollo viene convertito in stringa e valorizzato a 0 se nullo
 * - le date vengono formattate per la visualizzazione
 * - i null vengono convertiti in stringa vuota
 *
 * - per ogni riga:
 *   - viene generato un id, che per la riga del bollo è 'bollo'
 *     e per le righe di cassa previdenziale
 *     è `cassaPrevidenziale_${r.IVA}` (dove iva è l'aliquota o il codice natura)
 *   - l'oggetto iva viene convertito in una stringa
 *     che contiene l'aliquota o il codice della natura IVA
 *   - se si tratta di una riga di cassa previdenziale la descrizione
 *     viene valorizzata con una stringa per la visualizzazione e
 *     vengono aggiunti gli attributi ente e aliquota
 *   - i null vengono convertiti in stringa vuota
 *
 * @module doc
 * @function convertiUsoClient
 *
 * @returns {Object} documento
 */
export function convertiUsoClient(serverDoc) {
  // Log.group("Doc.convertiUsoClient");
  const documento = { ...serverDoc };
  const dest = documento.destinazioneFattura || "SDI";
  documento.impostaBollo = R.isNil(documento.impostaBollo)
    ? "0.0"
    : documento.impostaBollo.toString();
  // converto le date
  const dateDaFormattare =
    dest === "SDI" ? ["dataDocumento"] : ["dataDocumento", "dataPagamento"];
  dateDaFormattare.forEach(
    (v) => (documento[v] = formatDataClient(documento[v]))
  );
  if (
    dest === "STS" &&
    Log.debug(
      "convertiUsoClient: documento.tipoDocumento === 'TD04'",
      documento.tipoDocumento === "TD04"
    ) &&
    documento.rimborsoDataDocumento
  ) {
    documento.rimborsoDataDocumento = formatDataClient(
      documento.rimborsoDataDocumento
    );
  }
  // converto a stringa vuota i null
  const campiDaAnnullare =
    dest === "SDI"
      ? ["idTrasmissione"]
      : ["idTrasmissione", "rimborsoDataDocumento", "rimborsoNumeroDocumento"];
  campiDaAnnullare
    .filter((v) => documento[v] === "")
    .forEach((v) => (documento[v] = null));
  // converto a stringa vuota i null nel cliente
  [
    "codiceFiscale",
    "codiceSDI",
    "cognomeDenom",
    "email",
    "nome",
    "paesePartitaIVA",
    "partitaIVA",
    "telefono",
  ]
    .filter((v) => documento.cliente[v] === "")
    .forEach((v) => (documento.cliente[v] = null));
  // converto a stringa vuota i null sede cliente se sede cliente valorizzato
  if (!R.isNil(documento.cliente.sede)) {
    ["cap", "comune", "nazione", "numeroCivico", "provincia", "via"]
      .filter((v) => documento.cliente.sede[v] === "")
      .forEach((v) => (documento.cliente.sede[v] = null));
  }
  // compatto tipoIVA
  // converto in stringhe i numeri delle righe,
  // ed eventualmente a stringa vuota i null
  documento.righe = documento.righe.map(({ tipoIVA, ...r }) => {
    r.IVA = compattaTipoIVA(tipoIVA);
    if (r.tipoRiga === "BOLLO") {
      r._id = "bollo";
    } else if (r.tipoRiga === "CASSA_PREVIDENZIALE") {
      const datiCassa = CassaPrevidenziale.espandiDescrizioneCassaPrevidenziale(
        r.descrizione
      );
      r._id = `cassaPrevidenziale_${r.IVA}`;
      r.descrizione = datiCassa.descrizione;
      r.aliquota = datiCassa.aliquota;
      r.ente = datiCassa.ente;
    } else if (r.tipoRiga === "RITENUTA") {
      const datiRitenuta = Ritenuta.espandiDescrizioneRitenuta(r.descrizione);
      r._id = `ritenuta_${r.IVA}`;
      r.descrizione = datiRitenuta.descrizione;
      r.aliquota = datiRitenuta.aliquota;
      r.ente = datiRitenuta.ente;
    } else {
      r._id = nanoid();
    }
    const campiRigaDaAnnullare =
      dest === "SDI" ? ["descrizione"] : ["descrizione", "tipoSpesa"];
    campiRigaDaAnnullare
      .filter((v) => r[v] === null)
      .forEach((v) => (r[v] = ""));
    ["importoUnitario", "quantita"].forEach((v) => {
      if (r[v] === null) r[v] = "";
      else r[v] = r[v].toString();
    });
    return r;
  });
  // Log.endGroup("Doc.convertiUsoClient");
  return documento;
}
/**
 * Converte un documento locale per l'invio al server
 *
 * - popola il sezionale in base ai defaults e alla destinazione del doc
 * - se lo statoInvio è LOCALE lo imposta a null
 * - se tipo documento non è valorizzato lo imposta a Fattura
 * - genera la descrizione per la riga ritenuta o cassa previdenziale
 * - espande il campo iva da stringa a oggetto con natura e aliquota
 *
 * @module doc
 * @function preparaDocInvio
 *
 * @returns {Object} documento
 */
export function preparaDocInvio(
  { righe, cliente, statoInvio, tipoDocumento, ...doc },
  impostaBollo,
  defaults
) {
  const sez = defaults[`sezionale${doc.destinazioneFattura}`];
  const descrizioneRiga = (_id, descrizione, r) => {
    if (isRigaCassaPrevidenziale(_id)) {
      return CassaPrevidenziale.compattaDescrizioneCassaPrevidenziale(
        r.ente,
        r.aliquota
      );
    } else if (isRigaRitenuta(_id)) {
      return Ritenuta.compattaDescrizioneRitenuta(
        r.ente,
        r.aliquota,
        r.causale || defaults.causaleRitenuta
      );
    } else return descrizione;
  };
  return {
    ...doc,
    statoInvio: statoInvio === StatoInvio.LOCALE ? null : statoInvio,
    tipoDocumento:
      R.isEmpty(tipoDocumento) || R.isNil(tipoDocumento)
        ? "TD01"
        : tipoDocumento,
    sezionaleDocumento: sez,
    cliente: cliente,
    righe: righe.map(({ _id, IVA, descrizione, ...r }) => ({
      ...r,
      descrizione: descrizioneRiga(_id, descrizione, r),
      tipoIVA: espandiTipoIVA(IVA),
    })),
    importoTotale: getImportoTotale(righe),
    impostaBollo: impostaBollo,
  };
}
/**
 * campoIVA
 *
 * @module doc
 * @function campoIVA
 */
export function campoIVA(txt) {
  return R.isNil(txt) || R.isEmpty(txt) ? "0.0" : txt.toString().split("%")[0];
}
/**
 * valoriRiga
 *
 * @module doc
 * @function valoriRiga
 */
export function valoriRiga(r) {
  const _campoIVA = isRigaRitenuta(r._id) ? 0.0 : parseFloat(campoIVA(r.IVA));
  const _importoUnitario = parseFloat(r.importoUnitario) || 0.0;

  return {
    iu: _importoUnitario,
    iva: isNaN(_campoIVA) ? 0.0 : _campoIVA / 100.0,
    qta: r.quantita || 0.0,
  };
}
/**
 * Converte l'iva per l'invio al server
 *
 * localmente l'iva è una stringa, che se rappresenta
 * un tipo con aliquota a 0 riporta il codice del tipo,
 * altrimenti l'aliquota
 *
 * sul server l'iva è un oggetto con natura e aliquota
 *
 * @module doc
 * @function espandiTipoIVA
 *
 * restituisce un json con natura e aliquota
 */
export function espandiTipoIVA(IVA) {
  const valore = campoIVA(IVA);
  const isNumeric = !isNaN(valore);

  return {
    natura: isNumeric ? "NORMALE" : IVA,
    aliquota: isNumeric ? valore : "0",
  };
}
/**
 * Converte l'iva che arriva dal server per uso locale
 *
 * localmente l'iva è una stringa, che se rappresenta
 * un tipo con aliquota a 0 riporta il codice del tipo,
 * altrimenti l'aliquota
 *
 * sul server l'iva è un oggetto con natura e aliquota
 *
 * @module doc
 * @function compattaTipoIVA
 *
 * restituisce una stringa
 */
export function compattaTipoIVA({ natura, aliquota }) {
  return parseFloat(aliquota) > 0 ? aliquota : natura;
}
/**
 * sommaRighe
 *
 * @module doc
 * @function sommaRighe
 */
export function sommaRighe(righe, fn) {
  return righe.reduce((a, r) => a + fn.apply(this, [valoriRiga(r)]), 0);
}
/**
 * importoRiga
 *
 * @module doc
 * @function importoRiga
 */
export function importoRiga({ iu, iva, qta }) {
  return (iu + iu * iva) * qta;
}
/**
 * IVARiga
 *
 * @module doc
 * @function IVARiga
 */
export function IVARiga({ iu, iva, qta }) {
  return iu * iva * qta;
}
/**
 * asString
 *
 * @module doc
 * @function asString
 */
export function asString(val) {
  return val.toLocaleString("it", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
export { StatoInvio, ValoriIVA };

