<template>
  <nav id="main-menu">
    <template v-if="loggedIn">
      <MenuSliderSpSa
        v-if="!credenzialiAssenti"
        :route="routePathFatturaButton"
        icon="file-alt"
        :text="txtFatturaButton"
        :active="activeSection === 'Fattura'"
        @click.native="clickFatturaMenu()"
      />
      <MenuSliderSpSa
        v-if="!credenzialiAssenti"
        route="/#/fatture-emesse"
        icon="archive"
        text="ARCHIVIO"
        :active="activeSection === 'Fatture Emesse'"
      />
      <MenuSliderSpSa
        v-if="!credenzialiAssenti"
        route="/#/clienti"
        icon="users"
        text="CLIENTI"
        :active="activeSection === 'Clienti'"
      />
      <MenuIconaUtenteSpSa @click.native="$emit('toggleRiquadroUtente')" />
    </template>
    <template v-else>
      <router-link v-if="$route.path != '/login'" to="/login"
        ><span @click="showLogin">Login</span></router-link
      >
      <router-link
        v-if="$route.path != '/registrazione'"
        v-tooltip="'Dati utente'"
        to="/registrazione"
        >Registrazione</router-link
      >
    </template>
    <font-awesome-icon
      @click="$emit('toggleRiquadroAssistenza')"
      icon="question-circle"
      v-tooltip="'Help'"
      id="toggle-riquadro-assistenza"
      color="white"
    />
  </nav>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getNumeroDoc } from "mida4-fattura-rapida-spese-sanitarie/doc";

import MenuSliderSpSa from "@/components/_layout/components/MenuSliderSpSa";
import MenuIconaUtenteSpSa from "@/components/_layout/components/MenuIconaUtenteSpSa";

export default {
  components: {
    MenuSliderSpSa,
    MenuIconaUtenteSpSa,
  },

  name: "MenuApplicazioneSpSa",

  computed: {
    ...mapGetters({
      activeSection: "getActiveSection",
      credenzialiAssenti: "auth/credenzialiAssenti",
      scaduto: "user/scaduto",
      loggedIn: "auth/isUserLoggedIn",
      sospesoSTS: "fatture/sospesoSTS",
      sospesoSDI: "fatture/sospesoSDI",
      hasSospeso: "fatture/hasSospeso",
      docDaElaborare: "fatture/getDocDaElaborare",
      isNuovoDocAbilitato: "fatture/isNuovoDocAbilitato",
    }),

    txtFatturaButton() {
      return this.hasSospeso && this.docDaElaborare
        ? `COMPLETARE ${getNumeroDoc(this.docDaElaborare)}`
        : "NUOVA";
    },

    routePathFatturaButton() {
      let url = "/#/fattura";
      if (this.scaduto) {
        url = "/#/rinnovo";
      } else if (this.hasSospeso && this.docDaElaborare) {
        url = `${url}/${this.docDaElaborare.id}`;
      }
      return url;
    },
  },

  methods: {
    ...mapMutations({
      setDocAttivo: "fatture/setDocAttivo",
    }),

    ...mapActions({
      resetDocAttivo: "fatture/resetDocAttivo",
      resetDoc: "elaboranda/resetDoc",
    }),

    showLogin() {
      const back = document.querySelector(".torna-login");
      if (back) back.click();
    },

    clickFatturaMenu() {
      if (this.hasSospeso) {
        this.setDocAttivo(this.docDaElaborare);
      } else {
        this.resetFattura();
      }
    },

    resetFattura() {
      if (!this.isNuovoDocAbilitato) return;
      this.resetDoc();
      if (this.$route.params.idFattura !== undefined) {
        this.$router.push({ params: { idFattura: null } }).catch((error) => {
          // eslint-disable-no-undef
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      }
    },
  },
};
</script>

<style
  src="@/styles/custom/components/_layout/_menu_applicazione.scss"
  lang="scss"
></style>

