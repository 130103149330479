<template>
  <section id="riquadro-utente">
    <p>{{ user.nome + " " }}{{ user.cognomeDenom }}</p>
    <p>P.IVA {{ user.partitaIVA }}</p>
    <DataScadenzaSpSa />
    <hr class="sep" />
    <button id="modifica-dati" @click="modificaDati">Dati Utente</button>
    <router-link
      to="/modifica-credenziali-sts"
      id="modifica-credenziali-sts"
      tag="button"
      >Modifica credenziali STS</router-link
    >
    <hr class="sep" />
    <button id="logout" @click="doLogout">LOGOUT</button>
    <p id="modifica-password">
      <router-link to="/modifica-password">Reset password</router-link>
    </p>
    <p id="termini-servizio">
      <router-link to="/privacy">Norme sulla privacy</router-link> |
      <router-link to="/termini-servizio">Termini di servizio</router-link>
    </p>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DataScadenzaSpSa from "@/components/_layout/components/DataScadenzaSpSa";

const NAME = "RiquadroUtenteSpSa";

export default {
  data() {
    return {
      NAME: NAME,
    };
  },
  components: {
    DataScadenzaSpSa,
  },
  computed: {
    ...mapGetters({
      user: "user/getData",
    }),
  },
  methods: {
    ...mapActions({
      resetDocAttivo: "fatture/resetDocAttivo",
      logout: "auth/logout",
    }),
    async doLogout() {
      await this.resetDocAttivo();
      await this.logout();
      window.location.href = window.location.pathname;
    },
    modificaDati() {
      this.$router.push("/dati-utente");
    },
  },
};
</script>

<style src="@/styles/custom/components/_layout/_riquadro_utente.scss" lang="scss"></style>