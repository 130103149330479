import { R } from "mida4-web-app-utils";
import { getUserDefaults } from "mida4-fattura-rapida-spese-sanitarie/doc";
import StatusCodes from "http-status-codes";

const authModule = {
  namespaced: true,
  state: () => ({
    datiLogin: {
      token: null,
      stsPINDecod: null,
      stsPasswordDecod: null,
    },
    isUserLoggedIn: false,
  }),
  mutations: {
    setDatiLogin(state, datiLogin) {
      state.datiLogin = datiLogin;
      if (datiLogin && state.datiLogin != null) {
        state.isUserLoggedIn = true;
        this._vm.$api.setAuth(datiLogin);
      } else {
        state.isUserLoggedIn = false;
      }
    },
    setCredenzialiSTS(state, credenzialiSTS) {
      state.datiLogin.stsPINDecod = credenzialiSTS.stsPIN;
      state.datiLogin.stsPasswordDecod = credenzialiSTS.stsPassword;
    },
  },
  actions: {
    setDatiLogin({ commit }, datiLogin) {
      commit("setDatiLogin", datiLogin);
    },
    setCredenzialiSTS({ commit }, credenzialiSTS) {
      commit("setCredenzialiSTS", credenzialiSTS);
    },
    async login({ commit, dispatch }, credenziali) {
      const loginResponse = await this._vm.$api.login(credenziali);

      if (loginResponse.status == StatusCodes.OK) {
        const { utente: datiUtenteRicevuti, ...datiAuth } =
          await loginResponse.json();
        const utenteClient = {
          ...datiUtenteRicevuti,
          defaultDocumento: getUserDefaults(
            datiUtenteRicevuti.defaultDocumento
          ),
        };
        // salvo i dati nello store
        commit("setDatiLogin", datiAuth);
        await dispatch("user/setUser", utenteClient, { root: true });
      } else {
        const body = await loginResponse.json();
        throw Error(body.message);
      }
    },
    logout({ commit }) {
      commit("setDatiLogin", null);
    },
  },
  getters: {
    credenzialiAssenti: (state) =>
      [state.datiLogin.stsPINDecod, state.datiLogin.stsPasswordDecod].some(
        (f) => R.isNil(f) || R.isEmpty(f)
      ),
    isUserLoggedIn: (state) => state.isUserLoggedIn,
    getCredenzialiSTS: (state) => ({
      stsPIN: state.datiLogin.stsPINDecod,
      stsPassword: state.datiLogin.stsPasswordDecod,
    }),
  },
};
export default authModule;
