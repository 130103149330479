/** @module functions */
"use strict";
import { toKebabCase, toCamelCase, capitalizeAll } from "ramda-extension";
import { isNil, isEmpty } from "ramda";
import REGEXES from "./regexes";

// https://blog.logrocket.com/using-trampolines-to-manage-large-recursive-loops-in-javascript-d8c9db095ae3/
const trampoline =
  (fn) =>
  (...args) => {
    let result = fn(...args);
    while (typeof result === "function") {
      result = result();
    }
    return result;
  };

export function kebabCase(str) {
  return toKebabCase(str);
}
export function camelCase(str) {
  return toCamelCase(str);
}

export function allCaps(str) {
  return capitalizeAll(str);
}

export function toTitleCase(str) {
  return str
    .split(" ")
    .map((w) =>
      w.toLowerCase().replace(REGEXES.PRIMO_CARATTERE, (c) => c.toUpperCase())
    )
    .join(" ");
}

export function mergeArrayOfObjects(original, newdata, selector = "key") {
  newdata.forEach((dat) => {
    const foundIndex = original.findIndex(
      (ori) => ori[selector] == dat[selector]
    );
    if (foundIndex >= 0) original.splice(foundIndex, 1, dat);
    else original.push(dat);
  });

  return original;
}

/**
 * Restituisce una funzione di ordinamento
 * che opera in base alla proprietà
 * che riceve come parametro.
 * Il secondo parametro rappresenta
 * la direzione dell'ordinamento
 * ed è ascendente di default
 *
 * @param {string} prop
 * @param {string} dir
 * @returns {function}
 */
export function sortArrayOfObjectsBy(prop, dir = "asc") {
  const MUL = dir === "asc" ? 1 : -1;
  return (objs) =>
    objs.sort(
      (a, b) => (a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0) * MUL
    );
}

// src: https://stackoverflow.com/a/68215876  --  https://gist.github.com/davalapar/d0a5ba7cce4bc599f54800da22926da2
// correzione da commento SO per evitare filename doppio
export async function downloadFile(nomeFile, fetchResult) {
  var filename = nomeFile;
  var data = await fetchResult.blob();
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const blob = new Blob([data], {
    type: data.type || "application/octet-stream",
  });
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE doesn't allow using a blob object directly as link href.
    // Workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    // @ts-ignore
    window.navigator.msSaveBlob(blob, filename);
    return;
  }
  // Other browsers
  // Create a link pointing to the ObjectURL containing the blob
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = blobURL;
  tempLink.setAttribute("download", filename);
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === "undefined") {
    tempLink.setAttribute("target", "_blank");
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    // @ts-ignore
    window.URL.revokeObjectURL(blobURL);
  }, 100);
}

/**
 * formatta un importo in base alla locale IT
 *
 * @param {object} args
 * @property {number} args.num numero da formattare
 * @property {boolean} args.decimaliSempre inserire i decimali anche se a zero
 * @returns {string} importo formattato
 */
export function formatImporto({ num, decimaliSempre = true }) {
  const locale = "it-IT";
  const opts = decimaliSempre ? { minimumFractionDigits: 2 } : {};

  return Intl.NumberFormat(locale, opts).format(arrotonda(num));
}

/**
 * arrotonda a due decimali
 *
 * @param {number} num
 * @returns {number} Math.round((num + Number.EPSILON) * 100) / 100
 */
export function arrotonda(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

/**
 * inserisce un elemento in un array
 * dopo un elemento specifico
 *
 * @param {object} opts
 * @property {Array} opts.arr array in cui inserire l'elemento
 * @property {object} opts.el elemento da inserire
 * @property {number} opts.after posizione elemento dopo cui inserire
 *
 * @returns {Array<Object>} array con elemento inserito
 */
export function insert({ arr, el, after }) {
  return arr.reduce(
    (acc, iter, idx) => (
      idx === after ? acc.push(iter, el) : acc.push(iter), acc
    ),
    []
  );
}

/**
 * Restituisce un array di oggetti unici (non replicati)
 * in base ad una proprietà dell'oggetto
 * che riceve come parametro
 *
 * @param {array} oArr
 * @param {string} eProp
 * @returns {array}
 */
function _uniq(oArr, eProp) {
  let retArr = arguments[2] || [],
    flags = arguments[3] || {};
  if (oArr === undefined || eProp === undefined) return undefined;
  else
    return oArr.length === 0
      ? retArr
      : () =>
          ((cObj, found = flags[cObj[eProp]]) =>
            _uniq(
              oArr,
              eProp,
              found ? retArr : [cObj, ...retArr],
              found ? flags : { [cObj[eProp]]: true, ...flags }
            ))(oArr.pop());
}
export const uniq = trampoline(_uniq);

// non tail call
// export const zip = ([v,...a],b) => v ? [v, ...zip(b,a)] : b

export const keysOfUnsetValues = (o) =>
  Object.entries(o).reduce(
    (miss, [k, v]) => (isNil(v) || isEmpty(v) ? [...miss, k] : miss),
    []
  );

/**
 * Riceve una funzione comparator e restituisce una funzione che
 * a partire da un array di due liste
 * restituisce una lista ordinata in base alla logica
 * definita nel comparator.
 *
 * Il comparator deve restituire -1 0 o 1
 *
 * @param {Function} _compare
 * @returns {Function}
 */
export const interleaver = (_compare) => {
  function _ilv([l1, l2], hodgepodge = []) {
    let [f1, ...r1] = l1,
      [f2, ...r2] = l2,
      elToAdd;

    if ([l1, l2].some((l) => !l.length) && !(elToAdd = f1 ?? f2))
      return hodgepodge;

    ((elToAdd = elToAdd ?? (_compare(f1, f2) <= 0 ? f1 : f2)) === f1 &&
      (r2 = l2)) ||
      (r1 = l1);

    return () => _ilv([r1, r2], [...hodgepodge, elToAdd]);
  }
  return trampoline(_ilv);
};

export function debounce(fn, timeout = 500) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, timeout);
  };
}

export function emptyPropertiesDeleted(o) {
  Object.keys(o).forEach((p) => {
    const isStringProp = (p) => typeof p === "string" || p instanceof String;
    if (isStringProp(o[p]) && o[p].trim() === "") {
      delete o[p];
    }
  });
  return o;
}
